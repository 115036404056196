import "../../node_modules/popper.js/dist/popper.min";
import "../../node_modules/popper.js/dist/popper-utils.min";
import "../../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";
//import "../../node_modules/multi.js/dist/multi.min.js";
//import "../../node_modules/multi.js/dist/multi.min.css";

import "../../node_modules/select2/dist/js/select2.min.js";
import "../../node_modules/select2/dist/css/select2.min.css";
//import "../../node_modules/select2/dist/css/select2-bootstrap4.min.css";
import "../../node_modules/@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";

//import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js";
import "../../node_modules/jquery-ui/ui/widgets/datepicker.js";
import "../../node_modules/jquery-ui/themes/base/datepicker.css";

//import './masonry/index.js';
import './colorpicker/index.js';
//import './charts/index.js';
//import './popover/index.js';
//import './scrollbar/index.js';
import './search/index.js';
import './sidebar/index.js';
//import './skycons/index.js';
//import './vectorMaps/index.js';
//import './chat/index.js';
//import './datatable/index.js';
import './datepicker/index.js';
import './email/index.js';
import './fullcalendar/index.js';
//import './googleMaps/index.js';
import './utils/index.js';
import './aurore/index.js';


