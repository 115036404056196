import * as $ from "jquery";

import './latin.js';
//import * as md5 from './hash.js';

// Change text on stroke
(function ($) {
    $.fn.extend({
        // With every keystroke keep only numbers and spaces in the text and format result
        cardFormat: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val().replace(/[^0-9]/g, '');
                var originalLength = $(this).val().length;
                var start = box.selectionStart;
                var end = box.selectionEnd;

                var txtArray = [txt.substring(0, 4)];
                if(txt.length > 4){txtArray.push(txt.substring(4, 8)); }
                if(txt.length > 8){txtArray.push(txt.substring(8, 12));}
                if(txt.length > 12){txtArray.push(txt.substring(12, 16));}
                if(end === originalLength){start++; end++;}

                $(this).val(txtArray.join(' '));

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        },

        // With every keystroke keep only numbers and dot in the text
        floatOnly: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val();
                var start = box.selectionStart;
                var end = box.selectionEnd;

                $(this).val(txt.replace(',', '.').replace(/[^0-9\.]/g, ''));

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        },

        // With every keystroke keep only numbers in the text
        numberOnly: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val();
                var start = box.selectionStart;
                var end = box.selectionEnd;

                $(this).val(txt.replace(/[^0-9]/g, ''));

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        },

        // With every keystroke keep only numbers in the text
        phoneOnly: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val();
                var start = box.selectionStart;
                var end = box.selectionEnd;

                $(this).val(txt.replace(/[^0-9\+ ]/g, ''));

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        },

        // With every keystroke capitalize first letter of ALL words in the text
        capitalizeAll: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val();
                var start = box.selectionStart;
                var end = box.selectionEnd;

                $(this).val(txt.toLowerCase().replace(/^(.)|(\s|\-)(.)/g,
                    function(c) {
                        return c.toUpperCase();
                    }));

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        },

        // With every keystroke capitalize first letter of the FIRST word in the text
        capitalizeFirst: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val();
                var start = box.selectionStart;
                var end = box.selectionEnd;

                $(this).val(txt.toLowerCase().replace(/^(.)/g,
                    function(c) {
                        return c.toUpperCase();
                    }));

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        },

        // Converts with every keystroke the whole text to lowercase
        lowerCase: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val();
                var start = box.selectionStart;
                var end = box.selectionEnd;

                $(this).val(txt.toLowerCase());

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        },

        // Converts with every keystroke the whole text to unaccented lowercase
        lowerCaseNoAccents: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val();
                var start = box.selectionStart;
                var end = box.selectionEnd;

                $(this).val(txt.latinise().toLowerCase());

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        },

        // Converts with every keystroke the whole text to uppercase
        upperCase: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val();
                var start = box.selectionStart;
                var end = box.selectionEnd;

                $(this).val(txt.toUpperCase());

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        },

        // Converts with every keystroke the whole text to unaccented uppercase
        upperCaseNoAccents: function() {
            $(this).on('paste keyup blur change', function(event) {
                var box = event.target;
                var txt = $(this).val();
                var start = box.selectionStart;
                var end = box.selectionEnd;

                $(this).val(txt.latinise().toUpperCase());

                if(box.setSelectionRange && /text|search|password|tel|url/i.test(box.type || '')){
                    box.setSelectionRange(start, end);
                }
                else if (box.createTextRange)
                {
                    var range = box.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
            return this;
        }
    });
}(jQuery));

function addressField5Update(locality)
{
    var localities = $(".jquery-address-city").attr('localities');
    var field5Input = $(".jquery-address-field5");
    var field5Value = field5Input.val();
    var indexFound;

    if(localities.length > 0)
    {
        var localitiesList = localities.split('|');

        $.each(localitiesList, function (key, value)
        {
            field5Value = field5Value.replace(value.toUpperCase(), '');
        });
    }

    if(locality.length > 0 && locality != null  && locality != 'null')
    {
        field5Value += ' ' + locality;
    }

    // Update field 5 value
    field5Input.val(field5Value.trim());
}

function reloadDatePicker()
{
    $('.datepicker:not([readonly],[disabled])').datepicker({
        format: 'dd/mm/yyyy',
        dateFormat: "dd/mm/yy",
        closeText: "Fin",
        prevText: "Préc",
        nextText: "Suiv",
        currentText: "Aujourd'hui",
        monthNames: [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ],
        monthNamesShort: [ "Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec" ],
        dayNames: [ "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi" ],
        dayNamesShort: [ "Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam" ],
        dayNamesMin: [ "Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa" ],
        weekHeader: "Sem",
        showMonthAfterYear: false,
        firstDay: 1,
        yearSuffix: ""
    });
}

function updateCountryPhoneCode()
{
    $('.updateCountryPhoneCode').change(function ()
    {
        var phoneCode = $(this).find('option:selected').attr('phonecode');
        var selectId = $(this).attr('id');

        $('#' + selectId.replace(/\_country+$/, '_phonecode')).html(phoneCode);
    });
}

function formatInternationalPhoneNumber()
{
    $('.formatInternationalPhoneNumber').on('keyup paste', function(event)
    {
        var countrySelectId = $(this).attr('id').replace('_display', '').replace(/\_phone+$/, '_country');
        var countrySelect = $('#' + countrySelectId).find('option:selected');

        var box = event.target;
        var txt = $(this).val().replace(/[^\+0-9]/g, '');
        var start = box.selectionStart;
        var end = box.selectionEnd;

        var code = countrySelect.attr('phonecode').replace(" ", "");
        var codealt =  code.replace("+", "00");
        var prefix = countrySelect.attr('phoneprefix');
        var structure = countrySelect.attr('phonestructure');
        var pmin = countrySelect.attr('phonemin');
        var pmax = countrySelect.attr('phonemax');
        var structureArray = structure.split("_");

        // remove country code (+xx)
        if(txt.substr(0, code.length) === code && txt.length > code.length + 0 && code.length > 0)
        {
            txt = txt.substr(code.length, txt.length);
            start -= code.length;
        }

        // remove country code (00xx)
        if(txt.substr(0, codealt.length) === codealt && txt.length > codealt.length + 0 && codealt.length > 0)
        {
            txt = txt.substr(codealt.length, txt.length);
            start -= codealt.length;
        }

        // remove national prefix
        if(txt.substr(0, 1) === prefix && txt.length > codealt.length + 0 && prefix.length > 0)
        {
            txt = txt.substr(1, txt.length);
            start--;
        }

        if(structureArray.length > 1)
        {
            var txtArray = [];
            $.each(structureArray, function( key, value )
            {
                if(txt.length > 0)
                {
                    txtArray.push(txt.substr(0, value));
                    txt = txt.substr(value, txt.length);
                }
            });
            txt = txtArray.join(" ");
            start += (txtArray.length - 1);
            end += (txtArray.length - 1);
        }

        if(start < 0){start = 0;}

        $(this).val(txt);
        box.setSelectionRange(start, end);
    });
}

function setCollectionMainValue()
{
    $('.CollectionSetMain').click(function ()
    {
        var collectionType = $(this).attr('collectiontype');

        // Unselect all others radio buttons
        $('input[collectiontype="' + collectionType + '"]').not(this).prop('checked', false);
    });
}

function updateAddressField1()
{
    var t = $('.customerfield1title option:selected').text();
    var ln = $('.customerfield1lastname').val();
    var fn = $('.customerfield1firstname').val();
    var f1 = (t + ' ' + ln + ' ' + fn).toUpperCase().substring(0, 38);

    $('.customerfield1').val(f1);
}

function transferCustomerPhonesToPhoneToCall()
{
    if($('.orderController').val() === 'OrderAppointment')
    {
        var customerPhones = $('.customerPhones option').clone();
        $('.customerPhoneToCall').find('option').remove().end().append(customerPhones);
    }
}

function displayCustomerBirthDetails() {

    // Display or hide customer hour and place of birh
    $('.OrderDisplayBirthDetails').on("click", function()
    {
        if($(this).attr("showHide") === '0')
        {
            $(this).attr("showHide", 1);
            $(this).find("i").removeClass("fa-circle-chevron-up").addClass("fa-circle-chevron-down");
            $('.OrderDisplayBirthDetailsRow').addClass('d-none');
        }
        else
        {
            $(this).attr("showHide", 0);
            $(this).find("i").removeClass("fa-circle-chevron-down").addClass("fa-circle-chevron-up");
            $('.OrderDisplayBirthDetailsRow').removeClass('d-none');
        }
    });
}

function displayCustomerHistory()
{
    $('.CustomerDisplayHistoryButton').click(function ()
    {
        var historyBox = $('#order_customer_history');

        if(historyBox.hasClass("d-none")){
            historyBox.removeClass("d-none");
        }
        else{
            historyBox.addClass("d-none");
        }
    });

    $('.CustomerDisplayMediumsHistoryButton').click(function ()
    {
        var historyBox   = $('#order_customer_mediums_history');
        var historyTitle = $('#order_customer_mediums_title');

        if(historyBox.hasClass("d-none"))
        {
            $(this).find("i").removeClass("fa-plus-square").addClass("fa-minus-square");
            historyBox.removeClass("d-none");
            historyTitle.addClass("d-none");
        }
        else
        {
            $(this).find("i").removeClass("fa-minus-square").addClass("fa-plus-square");
            historyBox.addClass("d-none");
            historyTitle.removeClass("d-none");
        }
    });

    $('.CustomerDisplayAppointmentsButton').click(function ()
    {
        var appointmentsBox = $('#order_customer_appointments');

        if(appointmentsBox.hasClass("d-none")){
            appointmentsBox.removeClass("d-none");
        }
        else {
            appointmentsBox.addClass("d-none");
        }
    });

    datagridChangePage();
}

function strokeControls()
{
    $('.stroke-capitalize-all').capitalizeAll();
    $('.stroke-capitalize-first').capitalizeFirst();
    $('.stroke-lower').lowerCase();
    $('.stroke-lower-noaccents').lowerCaseNoAccents();
    $('.stroke-upper').upperCase();
    $('.stroke-upper-noaccents').upperCaseNoAccents();
    $('.stroke-float-only').floatOnly();
    $('.stroke-number-only').numberOnly();
    $('.stroke-phone').phoneOnly();
    $('.stroke-card-format').cardFormat();
}

function CopyToClipboard()
{
    $('.copy_to_clipboard_btn').click(function ()
    {
        var btn = $(this);
        var select = btn.closest(".form-row").find('.copy_to_clipboard_select option:selected');
        var textToCopy = select.text();

        // International Phone
        if(textToCopy.startsWith("+")){
            var textArray = textToCopy.split(" ");
            textArray.shift();
            textToCopy = textArray.join("");
        }

        var tempInput = $("<input>").val(textToCopy).attr('id', 'copy_to_clipboard_input');
        btn.before(tempInput);
        tempInput.select();
        //tempInput.setSelectionRange(0, 99999);
        document.execCommand("copy");
        tempInput.remove();
        alert(textToCopy + " copié dans le presse-papier");
    });
}

function updateCityLocality()
{
    // Update city locality in field 5
    $('.jquery-address-city').change(function () {
        var choosenLocality = $('option:selected', this).attr('locality');

        // Update Field 5
        addressField5Update(choosenLocality);
    });
}

function preventWrongPhoneToCall()
{
    var phoneToCall = $('.customerPhoneToCall');
    var customerId = $('.customerId').val() * 1;

    // New customer and select phonetocall is present
    if(phoneToCall.length > 0 && customerId <= 0)
    {
        // Options with a value
        var options = phoneToCall.find('option[value!=""]');

        // PlaceHolder text
        var placeholderText = phoneToCall.attr('placeholder');

        // PlaceHolder exists
        var placeholderOption = phoneToCall.find('option:first-child');
        var hasPlaceholder = placeholderOption.length > 0 && placeholderOption.val() === '';

        // Phone 1 & 2
        var fullPhone1 = $('#order_customer_new_phone_country option:selected').attr('phonecode') + ' ' + $('#order_customer_new_phone_display_phone').val();
        var fullPhone2 = $('#order_customer_new_phone_2_country option:selected').attr('phonecode') + ' ' + $('#order_customer_new_phone_2_display_phone').val();

        // Phone 1 or phone 2 are found in phone to call list
        var phone1Id = 0; var phone2Id = 0;
        options.each(function()
        {
            // Hide option if different than Phone 1&2
            if($(this).text() !== fullPhone1 && $(this).text() !== fullPhone2){$(this).addClass('d-none');}

            // Store ids of found phones
            if($(this).text() === fullPhone1){phone1Id = $(this).val() * 1;}
            else if($(this).text() === fullPhone2){phone2Id = $(this).val() * 1;}
        });

        // Show phone 1 in list
        if(phone1Id > 0){phoneToCall.find('option[value="' + phone1Id + '"]').removeClass('d-none');}

        // Show phone 2 in list
        if(phone2Id > 0){phoneToCall.find('option[value="' + phone2Id + '"]').removeClass('d-none');}

        // Neither phones are found in list
        if(phone1Id === 0 && phone2Id === 0)
        {
            if(!hasPlaceholder){phoneToCall.prepend('<option value="">' + placeholderText + '</option>');}
            phoneToCall.val('');
        }
        else if(options.length > 0){
            phoneToCall.find('option[value=""]').remove();
            if(phone1Id > 0 && phone2Id === 0){phoneToCall.val(phone1Id);}
            else if(phone1Id ===  0 && phone2Id > 0){phoneToCall.val(phone2Id);}
        }
    }
}

// Load a customer into order_add / appointment
function orderCustomerLoad(customerId, customerPrevious)
{
    // Selected Pseudo and medium ids
    var mediumPseudonymId = $('.mediumPseudonym option:selected').val() * 1;
    var mediumId = $('.medium option:selected').val() * 1;

    // Appointment id
    var appointmentId = $('.orderAppointmentId').val() * 1;

    // Payment mode and option
    var paymentModeId = $('.paymentMode option:selected').val() * 1;
    var paymentOptionId = $('.paymentOption option:selected').val() * 1;

    // Phone 1 & 2 (new customer replaced by an existing one)
    var phoneCountry1 = $('#order_customer_new_phone_country option:selected').val() * 1;
    var phoneCountry2 = $('#order_customer_new_phone_2_country option:selected').val() * 1;
    var phone1 = $('#order_customer_new_phone_display_phone').val();
    var phone2 = $('#order_customer_new_phone_2_display_phone').val();

    // Controller
    var controller = $('.orderController').val();

    // Check the code
    $.ajax({
        url: "/json/order/getCustomer",
        type: "GET",
        dataType: "JSON",
        data: {
            customerId: customerId,
            appointmentId: appointmentId,
            mediumPseudonymId: mediumPseudonymId,
            mediumId: mediumId,
            phoneCountry1: phoneCountry1,
            phone1: phone1,
            phoneCountry2: phoneCountry2,
            phone2: phone2,
            controller: controller
            //kc: md5(todayString + '_' + customerId),
        },
        success: function(result)
        {
            $('#orderCustomerContent').html(result.customer);

            // Customer id update
            $('.orderCustomerId').val(customerId);

            // Add previous customer if we switch to a new customer
            if(customerId <= 0 && customerPrevious != null && typeof customerPrevious != "undefined") {
                $('.customerId').append(customerPrevious);
            }

            // Available prices for this service
            var pricesList = {}; var pricesInputVal = $('.order-customer-prices-list').val();
            if(typeof pricesInputVal !== 'undefined' && pricesInputVal !== false) {pricesList = JSON.parse(pricesInputVal);}

            // Available amounts for this service
            var amountsList = {}; var amountsInputVal = $('.order-customer-amounts-list').val();
            if(typeof amountsInputVal !== 'undefined' && amountsInputVal !== false) {amountsList = JSON.parse(amountsInputVal);}

            // Update available prices and amounts on each service
            if(!$.isEmptyObject(pricesList) && !$.isEmptyObject(amountsList))
            {
                $('.orderService option').each(function()
                {
                    var sid = $(this).val();
                    if(sid !== '')
                    {
                        $(this).attr("prices", JSON.stringify(pricesList[sid]));
                        $(this).attr("amounts", JSON.stringify(amountsList[sid]));
                    }
                });
            }

            // Update prices with customer prices
            orderServiceChangeServicePrice();

            // Update phone to call with customer phones
            transferCustomerPhonesToPhoneToCall();

            // Empty select
            $('#order_customer_card').children('option:not(:first)').remove();

            // Fill select with found cards (missing main card selection)
            $.each(result.customerCards, function(cKey, cValue) {
                $('#order_customer_card')
                    .append($("<option></option>")
                        .attr("value", cKey)
                        .prop('selected', result.mainCardId > 0 && cKey === result.mainCardId)
                        .text(cValue));
            });

            // New card (payment mode CB or caution cb)
            if([1, 3].indexOf(paymentModeId) >= 0 && [1, 2, 3].indexOf(paymentOptionId) >= 0)
            {
                if(result.mainCardId > 0)
                {
                    $('.order-payment-grouping2').addClass('d-none');
                    $('.order-payment-grouping2-required').attr('required', false);
                }
                else
                {
                    $('.order-payment-grouping2').removeClass('d-none');
                    $('.order-payment-grouping2-required').attr('required', true);
                }
            }

            // Reload stroke controls
            strokeControls();

            // Reload country phone code update
            updateCountryPhoneCode();

            // Reload customer edit
            orderCustomerEdit();

            // Reload customer change
            orderCustomerChange();

            // Reload phone format
            formatInternationalPhoneNumber();

            // Update city locality in field 5
            updateCityLocality();

            // Copy to clipboard
            CopyToClipboard();

            // Cities loading from postal code
            updateCitiesFromPostalCode();

            // Save Customer button
            saveOrderCustomer();

            // Save Order form element
            saveOrderOrder();

            // History
            displayCustomerHistory();

            // Customer Birth details
            displayCustomerBirthDetails();

            // Search inconsistency of pseudo/medium
            orderControlValidityPseudonymMedium();

            // Activate function of customer duplicate search
            orderCustomerSearchDuplicateCustomersTrigger();

            // Update origin accordingly to customer history and selected service/pseudonym
            orderCustomerHistoryOriginChange();

            // Hide container
            $('#search_customer_results').addClass('d-none');
        },
        error: function (err)
        {
            alert('Erreur lors du chargement');
        }
    });
}

// Load a customer and his medium/pseudo into review
function customerReviewLoadCustomerAndPseudonyms(customerId, customerName)
{
    $('.customerReviewCustomer').find('option').remove().end()
        .append('<option value="' + customerId + '">' + customerName + '</option>')
        .val(customerId);

    // Get customer mediums and pseudos
    $.ajax({
        url: "/json/customer/medium/review/getCustomerPseudonyms",
        type: "GET",
        dataType: "JSON",
        data: {
            customerId: customerId
        },
        success: function(result)
        {
            // Select
            var pseudonymsSelect = $('.customerReviewMediumPseudonym');

            // Empty select
            pseudonymsSelect.find('option:not(:first)').remove();

            // Fill select with found pseudonyms
            var first = null;
            $.each(result.pseudonyms, function(cKey, cValue)
            {
                if(first == null){first = cKey;}
                pseudonymsSelect.append('<option value="' + cKey + '">' + cValue + '</option>');
            });

            pseudonymsSelect.val(first);
        },
        error: function(err) {
            alert('Erreur lors du chargement des pseudos');
        }
    });

    // Hide container
    $('#search_customer_results').addClass('d-none');
}

function orderPaymentModesOptionsGenerateSecondary(primaryObj, secondaryObj, attribute)
{
    var selectedValue = secondaryObj.find('option:selected').val();
    var objList = primaryObj.find('option:selected').attr(attribute).split('|');
    var found = false;

    if(objList.length > 0)
    {
        // Remove secondary current options
        secondaryObj.html('');

        $.each(objList, function (key, value)
        {
            var values = value.split(':');

            // Reselect previous value if there is one, or select first value
            var selectedText = selectedValue === values[0] || (!found && selectedValue <= 0) ? ' selected="selected"' : '';

            // Is first value
            found = true;

            // Generate select option
            secondaryObj.append('<option value="' + values[0] + '"' + selectedText + ' groupings_list="' + values[2].replace(/@/g, '|') + '">' + values[1] + '</option>');
        });
    }
}

function orderPaymentModesOptionsDisplayElements(paymentModesObj, paymentOptionsObj, paymentAlias)
{
    // Groupings from Mode
    var groupingMode = [];
    $.each(paymentModesObj.find('option:selected').attr('groupings_list').split('|'), function (index, value)
    {
        if(groupingMode.indexOf(value) < 0 && value > 0){
            groupingMode.push(value);
        }
    });

    // Groupings from Option
    var groupingOption = [];
    $.each(paymentOptionsObj.find('option:selected').attr('groupings_list').split('|'), function (index, value)
    {
        if(groupingOption.indexOf(value) < 0 && value > 0){
            groupingOption.push(value);
        }
    });

    // Change visibility and requirement (visible and required if matching both mode and option)
    for(var i = 1; i <= 15; i++)
    {
        if(groupingMode.indexOf('' + i) >= 0 && groupingOption.indexOf('' + i) >= 0 && (i !== 2 || paymentAlias <= 0))
        {
            $(".order-payment-grouping" + i).removeClass('d-none');
            $(".order-payment-grouping" + i + '-required').attr('required', true);
        }
        else
        {
            $(".order-payment-grouping" + i).addClass('d-none');
            $(".order-payment-grouping" + i + '-required').attr('required', false);
        }
    }

    // Reinitialize payment link choice
    $(".paymentSendLinkBy option:selected").prop("selected", false);
    $(".paymentSendLinkBy option:first").attr('selected','selected');
}

function updateOrderPaymentOptions()
{
    $('.order-payment-options-display').change(function ()
    {
        // Objects
        var paymentModesObj = $(".paymentMode");
        var paymentOptionsObj = $(".paymentOption");

        // if select = payment modes, reload options list
        if($(this).attr('id') === paymentModesObj.attr('id')){
            orderPaymentModesOptionsGenerateSecondary(paymentModesObj, paymentOptionsObj, 'options_list');
        }

        // Display/Hide groups elements
        orderPaymentModesOptionsDisplayElements(paymentModesObj, paymentOptionsObj, $("#order_customer_card option:selected").val());

        // Refresh prepayments list
        orderPrePaymentsListLoad();

        // Pending payment sended links
        orderPaymentLinksListLoad($(".paymentMode option:selected").val(), $(".paymentOption option:selected").val(), null, $("#order_appointment_id").val(), $("#order_order_number").val(), null);
    });
}

function updateOrderAppointmentPaymentModes()
{
    $('.order-appointment-payment-modes-display').change(function ()
    {
        // Objects
        var paymentModesObj = $(".paymentMode");
        var paymentOptionsObj = $(".paymentOption");

        // if select = payment options, reload modes list
        if($(this).attr('id') === paymentOptionsObj.attr('id')){
            orderPaymentModesOptionsGenerateSecondary(paymentOptionsObj, paymentModesObj, 'modes_list');
        }

        // Display/Hide groups elements
        orderPaymentModesOptionsDisplayElements(paymentModesObj, paymentOptionsObj, null);

        // Refresh prepayments list
        orderPrePaymentsListLoad();

        // Pending payment sended links
        orderPaymentLinksListLoad($(".paymentMode option:selected").val(), $(".paymentOption option:selected").val(), $("#order_appointment_appointment_number").val(), $("#order_appointment_id").val(), null, null);
    });
}

function orderPrePaymentsListLoad()
{
    // Show loader
    $('#order_orderPrepayment_addloading').removeClass('d-none');

    // Payment Mode
    var paymentModeId = $('.paymentMode option:selected').val();

    // Retrieve ids
    var appointmentNumber = $('.orderAppointmentNumber').val();
    var appointmentId = $('.orderAppointmentId').val();
    var orderNumber = $('.orderOrderNumber').val();

    $.ajax({
        url: "/json/order/prepayments",
        type: "POST",
        dataType: "JSON",
        data: {
            paymentModeId: paymentModeId,
            appointmentNumber: appointmentNumber,
            appointmentId: appointmentId,
            orderNumber: orderNumber,
            orderId: null
        },
        success: function (result)
        {
            // Update payment option to "payed"
            var paymentOptionsObj = $(".paymentOption");
            if(result.success > 0 && paymentOptionsObj.val() !== 5)
            {
                paymentOptionsObj.val(5);

                // Display/Hide groups elements
                orderPaymentModesOptionsDisplayElements($(".paymentMode"), paymentOptionsObj, null);
            }

            // Load content
            $('#order_payments_content').html(result.content);
            orderPrepaymentsListRefresh();

            // Hide loader
            $('#order_orderPrepayment_addloading').addClass('d-none');
        },
        error: function (err)
        {
            $('#order_payments_content').html("Impossible de récupérer la liste des paiements");
        }
    });
}

function orderPrepaymentsListRefresh()
{
    $('#order_orderPrepayment_refreshbutton').click(orderPrePaymentsListLoad);
}

function orderPaymentLinksListLoad(paymentModeId, paymentOptionId, appointmentNumber, appointmentId, orderNumber, orderId)
{
    // Empty payment link send and links list
    $('#order_paymentLinks_content').html('');
    $('#order_paymentLink_content').html('');

    // Get Payment links sent for this payment mode (only if payment is pending)
    if(paymentOptionId === '7')
    {
        $.ajax({
            url: "/json/order/payment/links",
            type: "POST",
            dataType: "JSON",
            data: {
                paymentModeId: paymentModeId,
                appointmentNumber: appointmentNumber,
                appointmentId: appointmentId,
                orderNumber: orderNumber,
                orderId: orderId
            },
            success: function(result){
                $('#order_paymentLinks_content').html(result);
            },
            error: function(err){
                $('#order_paymentLinks_content').html("Impossible de récupérer la liste des liens envoyés");
            }
        });
    }
}

function orderPaymentLinkSendLoad(submitMode= false)
{
    // Composite identifier components
    var appointmentNumberObj = $('.orderAppointmentNumber');
    var appointmentIdObj = $('.orderAppointmentId');
    var orderNumberObj = $('.orderOrderNumber');
    var orderIdObj = $('.orderOrderId');

    // Error message hide and empty
    $('.paymentSendLinkByError').removeClass('d-block').addClass('d-none');
    $('.paymentSendLinkByError .form-error-message').html('');

    // Mandatory Objects
    var paymentSendLinkById = $('.paymentSendLinkBy option:selected').val() * 1;
    var paymentModeId = $('.paymentMode option:selected').val() * 1;
    var paymentOptionId = $('.paymentOption option:selected').val() * 1;
    var serviceId = $('.orderService option:selected').val() * 1;
    var servicePriceId = $('.servicePrice option:selected').val() * 1;
    var servicePromotionId = $('.servicePromotion option:selected').val() * 1;
    var mediumPseudonymId = $('.mediumPseudonym option:selected').val() * 1;

    // Payment API parameters
    var orderPaymentApiId = $('.orderPaymentApi option:selected').val() * 1;
    var orderPaymentInstallmentTypeId = $('.paymentInstallmentType option:selected').val() * 1;
    var orderPaymentInstallmentFrequencyId = $('.paymentInstallmentFrequency option:selected').val() * 1;
    var orderPaymentInstallmentPartsId = $('.paymentInstallmentParts option:selected').val() * 1;
    var orderPaymentInstallmentFirstAmount = $('.paymentInstallmentFirstAmount').val() * 1;
    var orderPaymentInstallmentNextAmounts = $('.paymentInstallmentNextAmounts').val() * 1;

    // Customer
    var customerId = $('.customerId').val() * 1;
    var customerTitleId = $('.customerTitleId').val() * 1;
    var customerLastname = $('.customerLastname').val();
    var customerFirstname = $('.customerFirstname').val();

    // Phone (new or selected)
    var customerMainPhoneId = $('.customerPhones option:selected').val() * 1;
    var customerNewPhoneCountryId = $('.customerNewPhoneCountry option:selected').val() * 1;
    var customerNewPhonePhone = $('.customerNewPhone').val();

    // Phone to call (appointment)
    var customerPhoneToCallId = $('.customerPhoneToCall').length > 0 ? $('.customerPhoneToCall option:selected').val() * 1 : 0;

    // Email (new or selected)
    var customerMainEmailId = $('.customerEmails option:selected').val() * 1;
    var customerNewEmail = $('.customerNewEmail').val();

    // If no link option is selected, we hide the bloc content
    if(paymentSendLinkById < 1){
        $('#order_paymentLink_content').html('');
        return false;
    }

    // Errors
    var errors = [];
    if(paymentModeId < 1){errors.push('Mode de réglement');}
    if(paymentOptionId < 1){errors.push('Option de réglement');}
    if(serviceId < 1){errors.push('Service');}
    if(servicePriceId < 1){errors.push('Tarif');}
    if(mediumPseudonymId < 1){errors.push('Pseudonyme');}

    // Installment errors
    if(orderPaymentInstallmentTypeId >= 2 && orderPaymentInstallmentFirstAmount < 1){errors.push('Premier montant');}
    if(orderPaymentInstallmentTypeId >= 2 && orderPaymentInstallmentNextAmounts < 1){errors.push('Montants suivants');}

    var errorMessage = "";
    if(errors.length === 1){errorMessage = "Le champ " + errors[0] + " est obligatoire !";}
    else if(errors.length > 1){errorMessage = "Les champs suivants sont obligatoires : " + errors.join(', ');}

    // Phone id or (country + new phone) are mandatory
    if(paymentSendLinkById === 1 && customerMainPhoneId < 1 && (customerNewPhoneCountryId < 1 || customerNewPhonePhone.length < 8)){
        errorMessage += (errorMessage.length > 0 ? "\n" : "") + "Un numéro de téléphone est obligatoire";
    }

    // Email id or new email are mandatory
    if(paymentSendLinkById === 2 && customerMainEmailId < 1 && customerNewEmail.length < 8){
        errorMessage += (errorMessage.length > 0 ? "\n" : "") + "Un email est obligatoire";
    }

    // Alert errors found
    if(errorMessage.length > 0)
    {
        $('.paymentSendLinkByError').removeClass('d-none').addClass('d-block');
        $('.paymentSendLinkByError .form-error-message').html(errorMessage);
        $('#order_paymentLink_content').html("");
        $('.paymentSendLinkBy').val("");
    }
    else
    {
        // Load link send zone
        $.ajax({
            url: "/json/order/payment/link/add",
            type: "POST",
            dataType: "JSON",
            data: {
                submitMode: submitMode,
                identifiers: {
                    "appointmentNumber": appointmentNumberObj.length ? appointmentNumberObj.val() * 1 : 0,
                    "appointmentId": appointmentIdObj.length ? appointmentIdObj.val() * 1 : 0,
                    "orderNumber": orderNumberObj.length ? orderNumberObj.val() * 1 : 0,
                    "orderId": orderIdObj.length ? orderIdObj.val() * 1 : 0
                },
                paymentSendLinkById: paymentSendLinkById,
                paymentModeId: paymentModeId,
                paymentOptionId: paymentOptionId,
                serviceId: serviceId,
                servicePriceId: servicePriceId,
                servicePromotionId: servicePromotionId,
                mediumPseudonymId: mediumPseudonymId,
                customerId: customerId,
                customerTitleId: customerTitleId,
                customerLastname: customerLastname,
                customerFirstname: customerFirstname,
                customerPhoneToCallId: customerPhoneToCallId,
                customerMainPhoneId: customerMainPhoneId,
                customerNewPhoneCountryId: customerNewPhoneCountryId,
                customerNewPhonePhone: customerNewPhonePhone,
                customerMainEmailId: customerMainEmailId,
                customerNewEmail: customerNewEmail,
                orderPaymentApiId: orderPaymentApiId,
                orderPaymentInstallmentTypeId: orderPaymentInstallmentTypeId,
                orderPaymentInstallmentFrequencyId: orderPaymentInstallmentFrequencyId,
                orderPaymentInstallmentPartsId: orderPaymentInstallmentPartsId,
                orderPaymentInstallmentFirstAmount: orderPaymentInstallmentFirstAmount,
                orderPaymentInstallmentNextAmounts: orderPaymentInstallmentNextAmounts
            },
            success: function(result)
            {
                // Add result to content
                $('#order_paymentLink_content').html(result.content);

                // Reinitialize payment link choice after link send
                if(submitMode)
                {
                    $(".paymentSendLinkBy option:selected").prop("selected", false);
                    $(".paymentSendLinkBy option:first").attr('selected','selected');

                    // Refresh links list (only if send success ?)
                    if(result.success){
                        orderPaymentLinksListLoad(
                            $(".paymentMode option:selected").val(),
                            $(".paymentOption option:selected").val(),
                            $(".orderAppointmentNumber").val(),
                            $(".orderAppointmentId").val(),
                            $(".orderOrderNumber").val(),
                            $(".orderOrderId").val());
                    }
                }

                // Refresh (without submit)
                $('.paymentSendLinkByRefresh').click(function(){orderPaymentLinkSendLoad(false);});

                // Submit button
                $('.paymentSendLinkBySubmit').click(function(){orderPaymentLinkSendLoad(true);});

            },
            error: function(err)
            {
                $('.paymentSendLinkByError').removeClass('d-none').addClass('d-block');
                $('.paymentSendLinkByError .form-error-message').html("Une erreur inconnue est survenue. Veuillez réessayer plus tard.");
                $('#order_paymentLink_content').html("");
                $('.paymentSendLinkBy').val("");
            }
        });
    }
}

function updateCitiesFromPostalCode()
{
    // Cities loading from postal code
    $('.jquery-address-postalcode').keyup(function ()
    {
        var postalCode = $(this).val();
        var countryId = $(".jquery-address-country").val();
        var citiesSelect = $(".jquery-address-city");

        // Empty localities list on select property
        citiesSelect.attr('localities', "");

        // Min length for a postal code
        if(postalCode.length >= 4)
        {
            // Loader
            $('.jquery-address-city-container').addClass("input-group").addClass("input-group-sm");
            $('.jquery-address-city-container div').removeClass("d-none").addClass("input-group-prepend");

            // Request the cities of the selected postal code.
            $.ajax({
                url: "/json/address/city_from_postalcode",
                type: "GET",
                dataType: "JSON",
                data: {
                    countryId: countryId,
                    postalCode: postalCode
                },
                success: function (citiesList)
                {
                    // Init vars
                    var $selectedValue = citiesSelect.val();
                    var countResults = 0;
                    var selectedUsed = false;
                    var selectedText = "";
                    var localityText = "";
                    var localitiesList = [];
                    var firstLocality = false;

                    // Remove current options
                    citiesSelect.html('');

                    // Each city
                    $.each(citiesList, function (key, city)
                    {
                        // city has localities or not
                        if(city.localities.length > 0)
                        {
                            // Each city locality
                            $.each(city.localities, function (lkey, locality)
                            {
                                // init
                                localityText = "";

                                // value previously selected
                                if($selectedValue == city.id && !selectedUsed)
                                {
                                    selectedText = ' selected';
                                    firstLocality = locality.label;
                                    selectedUsed = true;
                                }
                                else{selectedText = '';}

                                // Locality exists
                                if(locality.label != null)
                                {
                                    localityText = ' (' + locality.label + ')';
                                    if(jQuery.inArray(locality.label, localitiesList) < 0){localitiesList.push(locality.label);}
                                }

                                // Generate select option
                                localityText = (locality.label == null ? '' : ' (' + locality.label + ')');
                                citiesSelect.append('<option value="' + city.id + '" locality="' + locality.label + '"' + selectedText + '>' + city.label + localityText + '</option>');
                            });
                        }
                        else
                        {
                            if($selectedValue == city.id){selectedText = ' selected';}else{selectedText = '';}
                            citiesSelect.append('<option value="' + city.id + '">' + city.label + '</option>');
                            selectedText = "";
                        }
                        countResults++;
                    });

                    // Empty value
                    if (countResults == 0) {
                        citiesSelect.append('<option value>Aucune ville avec le code postal ' + postalCode + '</option>');
                    }

                    // Update select property with localities list
                    citiesSelect.attr('localities', localitiesList.join('|'));

                    // If city has locality, update Field 5 with the first locality found
                    if(firstLocality){addressField5Update(firstLocality);}

                    // Loader
                    $('.jquery-address-city-container').removeClass("input-group").removeClass("input-group-sm");
                    $('.jquery-address-city-container div').removeClass("input-group-prepend").addClass("d-none");
                },
                error: function (err)
                {
                    citiesSelect.html('').append('<option value>Erreur de chargement</option>');

                    // Loader
                    $('.jquery-address-city-container').removeClass("input-group").removeClass("input-group-sm");
                    $('.jquery-address-city-container div').removeClass("input-group-prepend").addClass("d-none");
                }
            });
        }
        else{
            citiesSelect.html('').append('<option value>Aucune ville avec le code postal ' + postalCode + '</option>');
        }
    });
}

// Send a save order request from order
function saveOrderOrder()
{
    var saveOrderOrderFct = function()
    {
        // Get Order number obj
        var $orderNumber = $('#order_order_number');
        var $customerId = $('#order_customer_id');
        if($orderNumber.length)
        {
            // Order number
            var serializedData = $orderNumber.serialize() + '&';

            // Customer Id
            serializedData += $customerId.length ? $customerId.serialize() + '&' : '';

            // Checkbox or not
            if($(this).is(':checkbox')){
                serializedData += encodeURIComponent($(this).attr("name")) + '=' + $(this).prop('checked');
            }
            else{
                serializedData += $(this).serialize();
            }

            // Send field data to controller
            $.ajax({
                url: "/json/order/saveOrder",
                type: "POST",
                dataType: "JSON",
                data: serializedData,
            });
        }
    };

    $('input[type="checkbox"].jquery-save-order, input[type="radio"].jquery-save-order').click(saveOrderOrderFct);
    $('input[type="text"].jquery-save-order, input[type="email"].jquery-save-order, textarea.jquery-save-order').blur(saveOrderOrderFct);
    //$('input[type="text"].jquery-save-order, input[type="email"].jquery-save-order, textarea.jquery-save-order').keyup(saveOrderOrderFct);
    $('select.jquery-save-order').change(saveOrderOrderFct);
}

// Send a save customer request from order
function saveOrderCustomer()
{
    // Save a customer
    $('.jquery-order-save-customer').click(function ()
    {
        // Controller
        var controller = $('.orderController').val();

        // Prefix
        var prefix = (controller === 'OrderAppointment' ? 'order_appointment_' : 'order_');

        // form
        var sform = $(this.form);

        // Get All fields
        var inputs = sform.find('' +
            'input[id^="order_customer_"], ' +
            'select[id^="order_customer_"], ' +
            'textarea[id^="order_customer_"]'
        ).not("#order_customer_card");

        // Serialize the data in the form
        var serializedData = inputs.serialize();

        // First selectable from order selects
        var orderSelects = sform.find('' +
            'select[id="' + prefix + 'origin"], ' +
            'select[id="' + prefix + 'medium"], ' +
            'select[id="' + prefix + 'medium_pseudonym"]'
        );

        // Add order values to serialized list
        orderSelects.each(function() {
            serializedData += '&' + $(this).serialize().replace('order_appointment', 'order');
        });

        // Add controller info to posted values
        serializedData += '&order[controller]=' + controller;

        // Prevent double clic
        $(this).prop("disabled", true);

        // Send form data to controller
        $.ajax({
            url: "/json/order/saveCustomer",
            type: "POST",
            dataType: "JSON",
            data: serializedData,
            success: function(result)
            {
                // Display results
                $('#orderCustomerContent').html(result.customer);

                // Update phone to call with customer phones
                transferCustomerPhonesToPhoneToCall();

                // Reload stroke controls
                strokeControls();

                // Reload country phone code update
                updateCountryPhoneCode();

                // Reload phone format
                formatInternationalPhoneNumber();

                // Update city locality in field 5
                updateCityLocality();

                // Copy to clipboard
                CopyToClipboard();

                // Cities loading from postal code
                updateCitiesFromPostalCode();

                // Save Customer button
                saveOrderCustomer();

                // Reload customer edit
                orderCustomerEdit();

                // Reload customer change
                orderCustomerChange();

                // Save Order form element
                saveOrderOrder();

                // History
                displayCustomerHistory();

                // Customer Birth details
                displayCustomerBirthDetails();

                // Enable save button (success)
                $('.jquery-order-save-customer').prop("disabled", false);
                

                // Load a customer into order_add / appointment after search
                $('.jquery-order-reload-customer').click(function(){orderCustomerLoad($(this).attr('customerid'), null);});

            },
            error: function (err)
            {
                alert('Erreur lors de la sauvegarde.');

                // Load a customer into order_add / appointment after search
                $('.jquery-order-reload-customer').click(function(){orderCustomerLoad($(this).attr('customerid'), null);});

                // Enable save button (error)
                $('.jquery-order-save-customer').prop("disabled", false);
            }
        });
    });
}

// Reload datagrid
function reloadDatagrid($baseUrl = false, $method = 'POST', $page = 1)
{
    // form
    var sform = $('#form_datagrid_filters');

    // Page
    var $validedPage = ($page && $.isNumeric($page) && $page > 1 ? $page : 1);

    // url
    var $url = ($baseUrl === false ? sform.attr('route') : $baseUrl) + ($validedPage > 1 ? '/' + $validedPage : '');

    // Loader
    $('#mainContentLoading').removeClass('d-none');

    // Send form data to controller
    $.ajax({
        url: $url,
        type: $method,
        dataType: "JSON",
        data: $method === 'POST' ? sform.serialize() : '',
        success: function(result)
        {
            if(result.status === 1)
            {
                $('#mainBodyContent').html(result.content);
                $('#datagridCount').html(result.total);

                // Reenable change page action
                datagridChangePage();

                // Reenable validation action
                datagridCustomAction();

                // Loader
                $('#mainContentLoading').addClass('d-none');
            }
            else{
                // Loader
                $('#mainContentLoading').addClass('d-none');

                alert('Erreur lors du chargement');
            }
        },
        error: function (err)
        {
            // Loader
            $('#mainContentLoading').addClass('d-none');

            alert('Erreur lors du chargement');
        }
    });
}

// Datagrid change page
function datagridChangePage()
{
    $('.datagrid_change_page').off("click").on( "click", function (event)
    {
        // Prevent normal action
        event.preventDefault();

        // Page
        var page = $(this).attr('data-page');
        page = page && $.isNumeric(page) && page > 1 ? page : 1;

        // Datagrid with filters or embeded datagrid
        if($('#form_datagrid_filters').length)
        {
            // Reload Datagrid with filters
            reloadDatagrid(false, 'POST', page > 1 ? page : 1);
        }
        else
        {
            // url
            var url = $(this).attr('data-baseurl') + (page > 1 ? '/' + page : '');

            // Loader
            $('#mainContentLoading').removeClass('d-none');

            // Send form data to controller
            $.ajax({
                url: url,
                type: 'GET',
                dataType: "JSON",
                success: function(result)
                {
                    if(result.status === 1)
                    {
                        $('#order_customer_history').html(result.content);
                        $('#datagridCount').html(result.total);

                        // Reenable change page action
                        datagridChangePage();

                        // Loader
                        $('#mainContentLoading').addClass('d-none');
                    }
                    else{
                        // Loader
                        $('#mainContentLoading').addClass('d-none');
                        alert('Erreur lors du chargement');
                    }
                },
                error: function (err)
                {
                    // Loader
                    $('#mainContentLoading').addClass('d-none');
                    alert('Erreur lors du chargement');
                }
            });
        }
    });
}

// Datagrid custom action
function datagridCustomAction()
{
    // Datagrid custom action based on the url of the link
    $('.datagrid_custom_action').off("click").on("click", function (event)
    {
        // Prevent normal action
        event.preventDefault();

        // Reload Datagrid
        reloadDatagrid($(this).attr('href'), 'POST', false);
    });
}

// Prevent order duplicate when duplicating tab (chrome)
function preventOrderDuplicateOnDuplicateTab()
{
    var formSubmitted = $('#form_submitted');
    var orderPreventDuplicateNumber = $('.orderPreventDuplicateNumber');
    var sessionPrefix = '';

    // Inputs exist
    if(formSubmitted.length > 0 && orderPreventDuplicateNumber.length > 0)
    {
        // Session prefix
        if(orderPreventDuplicateNumber.hasClass("orderOrderNumber")){
            sessionPrefix = 'ORD';
        }
        else if(orderPreventDuplicateNumber.hasClass("orderAppointmentNumber")){
            sessionPrefix = 'APT';
        }

        // Number and submitted val
        var orderPreventDuplicateNumberVal = orderPreventDuplicateNumber.val();
        var formSubmittedVal = formSubmitted.val();

        // First page load and number defined
        if(formSubmittedVal === '0' && orderPreventDuplicateNumberVal.length > 10)
        {
            var prefixedNumber = sessionPrefix + orderPreventDuplicateNumberVal;

            var sessionOrderNumber = sessionStorage.getItem(prefixedNumber);
            if(sessionOrderNumber === null){
                sessionStorage.setItem(prefixedNumber, '1');
            }
            else{
                window.location.reload();
            }
        }
    }
}

function orderPaymentsFormDisplayElements()
{
    // Display/Hide
    $('.OrderPaymentFormDisplayNewCard').change(function ()
    {
        if($(this).val() > 0)
        {
            $(".order-payment-online-newcard").addClass('d-none');
            $(".order-payment-online-newcard-required").removeAttr('required');
        }
        else
        {
            $(".order-payment-online-newcard").removeClass('d-none');
            $(".order-payment-online-newcard-required").attr("required", true);
        }
    });

    // Create a new bloc of payment form
    $('.OrderPaymentFormDisplayElements').change(function ()
    {
        // Trigger Objects
        var paymentCategoryObj  = $("#order_payment_payment_category");
        var paymentModeObj      = $("#order_payment_payment_mode");
        var paymentIsOnlineObj  = $("#order_payment_is_online");

        // Payments to cancel object
        var paymentPaymentsObj  = $("#order_payment_amount_payment");

        // Amount and possible values
        var paymentAmountObj    = $("#order_payment_amount");
        var paymentLeftToPay    = $("#order_payment_amount_to_pay").val() * 1;
        var paymentCredit       = $("#order_payment_customer_credit").val() * 1;

        // Mode Id of the order (global)
        var orderModeId         = $("#order_payment_mode_id").val();

        // Init list var
        var availableModes      = {};
        var availableOnlines    = {};
        var availablePayments   = {};

        // New card
        var isNewCard = $("#order_payment_customer_card").val() <= 0;

        // Available modes list
        $.each(paymentModeObj.attr("modes").split('|'), function(key, value)
        {
            var idName = value.split(':');
            availableModes[idName[0]] = idName[1];
            availablePayments[idName[0]] = {};
        });

        // Available Online/Offline list
        $.each(paymentIsOnlineObj.attr("onlines").split('|'), function(key, value)
        {
            var idName = value.split(':');
            availableOnlines[idName[0]] = idName[1];
        });

        // Available payments list
        if(paymentPaymentsObj.attr("payments").length > 0)
        {
            $.each(paymentPaymentsObj.attr("payments").split('|'), function(key, value)
            {
                var idNameMode = value.split(':');
                availablePayments[idNameMode[2]][idNameMode[0]] = idNameMode[1];

                //availablePayments[idNameMode[0]] = {name: idNameMode[1], mode: idNameMode[2]};
            });
        }

        // Properties for selected category
        var categorySelected    = paymentCategoryObj.find('option:selected');
        var categoryProperties  = categorySelected.attr("category_properties");

        // Trigger
        var trigger = '';
        if($(this).attr('id') === 'order_payment_payment_category'){
            trigger = 'category';
        }
        else if($(this).attr('id') === 'order_payment_payment_mode'){
            trigger = 'mode';
        }
        else if($(this).attr('id') === 'order_payment_is_online') {
            trigger = 'online';
        }

        // Adjust default amount
        if(trigger === 'category')
        {
            if(categorySelected.val() * 1 === 7 && paymentCredit <= paymentLeftToPay){
                paymentAmountObj.val(paymentCredit);
            }
            else{
                paymentAmountObj.val(paymentLeftToPay);
            }
        }

        // Remove all modes (on category change)
        if(trigger === 'category')
        {
            paymentModeObj.find('option').remove();
        }

        // Remove all online/offline and payments (on category or mode change)
        if(trigger === 'category' || trigger === 'mode')
        {
            paymentIsOnlineObj.find('option').remove();
            paymentPaymentsObj.find('option').remove();
        }

        // Modes and online/offline available for selected category
        var properties = {};
        if(categoryProperties.length > 0)
        {
            $.each(categoryProperties.split('|'), function(index, mode)
            {
                var modeOptions = mode.split(':');
                if(!(modeOptions[0] in properties)){properties[modeOptions[0]] = {};}
                properties[modeOptions[0]][modeOptions[1]] = {amount: modeOptions[2], card: modeOptions[3], delayed: modeOptions[4]};

                // Add valid modes to select (on category change)
                if(trigger === 'category')
                {
                    paymentModeObj.find("option[value='" + modeOptions[0] + "']").remove();
                    paymentModeObj.append($('<option value="' + modeOptions[0] + '">' + availableModes[modeOptions[0]] + '</option>'));

                    // Default is order mode
                    if(modeOptions[0] === orderModeId){paymentModeObj.find("option[value='" + modeOptions[0] + "']").attr("selected", "selected");}
                }
            });
        }
        else{
            paymentModeObj.prepend($('<option value="" selected="selected">' + paymentModeObj.attr('placeholder') + '</option>'));
        }

        // Selected mode
        var modeSelected = paymentModeObj.find('option:selected').val() * 1;

        // Online/Offline for selected mode
        if((trigger === 'category' || trigger === 'mode'))
        {
            if(modeSelected > 0)
            {
                $.each(properties[modeSelected], function(index, online)
                {
                    //paymentIsOnlineObj.find("option[value='" + index + "']").remove();
                    paymentIsOnlineObj.append($('<option value="' + index + '">' + availableOnlines[index] + '</option>'));
                });
            }
            else{
                paymentIsOnlineObj.prepend($('<option value="0" selected="selected">' + availableOnlines[0] + '</option>'));
            }
        }

        // Selected online/offline
        var onlineSelected = paymentIsOnlineObj.find('option:selected').val();

        // Properties for mode and online selected
        var amountCategory = 1; var hasCard = false; var hasDelayed = false;
        if(modeSelected > 0 && onlineSelected !== '')
        {
            amountCategory  = properties[modeSelected][onlineSelected]['amount'] * 1;
            hasCard         = properties[modeSelected][onlineSelected]['card'] === '1';
            hasDelayed      = properties[modeSelected][onlineSelected]['delayed'] === '1';
        }

        // Payments to cancel with selected mode
        if(trigger === 'category' || trigger === 'mode')
        {
            if(modeSelected > 0)
            {
                $.each(availablePayments[modeSelected], function(id, name){
                    paymentPaymentsObj.append($('<option value="' + id + '">' + name + '</option>'));
                });
            }

            if(paymentPaymentsObj.find('option').length === 0){
                paymentPaymentsObj.prepend($('<option value="" selected="selected">' + paymentPaymentsObj.attr('placeholder') + '</option>'));
            }
        }

        // Amounts display
        for(var i = 1; i <= 4; i++)
        {
            var amountObjContainer  = $(".order-payment-amount" + i);
            var amountObjSelect     = $(".order-payment-amount" + i + "-required");

            if (i === amountCategory)
            {
                amountObjContainer.removeClass('d-none');
                amountObjSelect.attr("required", true);
            }
            else
            {
                amountObjContainer.addClass('d-none');
                amountObjSelect.removeAttr('required');
            }
        }

        // Display/Hide alias fields
        if(hasCard)
        {
            $(".order-payment-online-alias").removeClass('d-none');
            $(".order-payment-online-alias-required").attr("required", true);
        }
        else
        {
            $(".order-payment-online-alias").addClass('d-none');
            $(".order-payment-online-alias-required").removeAttr('required');
        }

        // Display/Hide card fields
        if(hasCard && isNewCard)
        {
            $(".order-payment-online-newcard").removeClass('d-none');
            $(".order-payment-online-newcard-required").attr("required", true);
        }
        else
        {
            $(".order-payment-online-newcard").addClass('d-none');
            $(".order-payment-online-newcard-required").removeAttr('required');
        }

        // Display/Hide delayed date
        if(hasDelayed)
        {
            $(".order-payment-offine-delayed").removeClass('d-none');
            $(".order-payment-offine-delayed-required").attr("required", true);
        }
        else
        {
            $(".order-payment-offine-delayed").addClass('d-none');
            $(".order-payment-offine-delayed-required").removeAttr('required');
        }

        //alert(JSON.stringify(availablePayments));
    });
}

// Reload payments list after inserting a new payment (order edit)
function orderPaymentsListLoad(url)
{
    var paymentContainer = $('#order_orderPayments_content');

    // Send all informations to controller
    $.ajax({
        url: url,
        type: "POST",
        dataType: "JSON",
        data: {},
        success: function(result)
        {
            // Insert content into container
            paymentContainer.html(result);

            // Reload action on add button
            orderPaymentFormLoad();
        },
        error: function (err)
        {
            alert('Erreur lors du chargement');
        }
    });
}

// Load add a new payment form (order edit)
function orderPaymentFormLoad()
{
    // Create a new bloc of payment form
    $('.OrderAddRemovePaymentButton').click(function ()
    {
        var radixId = $(this).attr('id').replace(/addbutton+$/, '');
        var paymentLoading = $('#' + radixId + 'addloading') ;
        var paymentContainer = $('#' + radixId + 'content') ;
        var plusIcon = $(this).children('i');
        var postUrl = $(this).attr("data_link");

        if($(this).attr("addremovemode") === '0')
        {
            paymentLoading.removeClass('d-none');

            // Send all informations to controller
            $.ajax({
                url: postUrl,
                type: "POST",
                dataType: "JSON",
                data: {},
                success: function(result)
                {
                    // Insert content into container
                    paymentContainer.html(result.content);
                    paymentLoading.addClass('d-none');
                    plusIcon.removeClass("fa-plus-square").addClass("fa-minus-square");

                    // Reload action on add button
                    orderPaymentFormPost();

                    // Show/hide fields
                    orderPaymentsFormDisplayElements();

                    // Fields controls
                    strokeControls();
                    reloadDatePicker();
                },
                error: function (err)
                {
                    alert('Erreur lors du chargement');
                    paymentLoading.addClass('d-none');
                    plusIcon.removeClass("fa-plus-square").addClass("fa-minus-square");
                }
            });

            // Update mode attribute
            $(this).attr("addremovemode", '1');
        }
        else
        {
            // Empty new line container
            paymentContainer.html('');

            // Update mode attribute
            $(this).attr("addremovemode", '0');

            // Button Icon
            plusIcon.removeClass("fa-minus-square").addClass("fa-plus-square");
        }
    });
}

// Post payment add form values
function orderPaymentFormPost()
{
    $('.OrderInsertPaymentButton').click(function ()
    {
        var radixId = $(this).attr('id').replace(/submitbutton+$/, '');
        var paymentLoading = $('#' + radixId + 'submitloading');
        var paymentContainer = $('#' + radixId + 'content');
        var paymentSubContainer = $('#' + radixId + 'subcontent');
        var postUrl = $(this).attr("data_link");
        var listUrl = $(this).attr("data_listlink");

        // Loading
        paymentLoading.removeClass('d-none');

        // form
        var sform = $(this.form);

        // Get All fields
        var inputs = sform.find('input, select, textarea');

        // Serialize the data in the form
        var serializedData = inputs.serialize();

        // Send all informations to controller
        $.ajax({
            url: postUrl,
            type: "POST",
            dataType: "JSON",
            data: serializedData,
            success: function(result)
            {
                // Insert content into container
                paymentContainer.html(result.content);
                paymentLoading.addClass('d-none');

                // Reload action on add button
                orderPaymentFormPost();

                // Show/hide fields
                orderPaymentsFormDisplayElements();

                // if insert is successful, reload the payments list
                if(result.status === 1)
                {
                    // Hide sub content
                    $('#order_orderPayment_subcontent').addClass("d-none").html("");

                    // Reload payments list
                    orderPaymentsListLoad(listUrl);
                }

                // Fields controls
                strokeControls();
                reloadDatePicker();
            },
            error: function (err)
            {
                alert('Erreur lors du chargement');
                paymentLoading.addClass('d-none');
            }
        });
    });
}

// Reload customer form on customer select change
function orderCustomerChange()
{
    $('.jquery-order-change-customer').change(function(){
        orderCustomerLoad($(this).val(), $(this).find('option:not(:empty)').last());});
}

// Order Appointment activate or desactivate edition mode
function orderCustomerEditActivation(edition)
{
    var btn = $('.order-customer-edit');
    if(edition)
    {
        btn.attr("edition", 1);
        btn.find('i').addClass('fa-user-slash').removeClass('fa-user-edit');
        $('#order_customer_edition').val('1');
        $('.order-customer-edition').removeClass('d-none');
    }
    else
    {
        btn.attr("edition", 0);
        btn.find('i').addClass('fa-user-edit').removeClass('fa-user-slash');
        $('#order_customer_edition').val('0');
        $('.order-customer-edition').addClass('d-none');
    }
}

// Order Appointment edition mode on btn click
function orderCustomerEdit(){
    $('.order-customer-edit').click(function(event) {
        orderCustomerEditActivation($(this).attr("edition") === '0');
    });
}

// Order check coherence between medium and pseudo regarding customer history
function orderControlValidityPseudonymMedium(trigger = null)
{
    var history = $('.order-customer-history-pseudo-medium').val();
    var pseudoSelect  = $('.order-customer-history-select-pseudo');
    var mediumSelect  = $('.order-customer-history-select-medium');
    var pseudonymId = pseudoSelect.val() !== '' ? pseudoSelect.val() * 1 : 0;
    var mediumId = mediumSelect.val() !== '' ? mediumSelect.val() * 1 : 0;
    var inconsistencyContainer = $('#order_inconsistency_medium_pseudonym');

    // By default, error message is hidden
    inconsistencyContainer.addClass('d-none');

    // History exists
    if(history.length > 2)
    {
        var pseudoMedium = $.parseJSON(history);

        // Pseudo and medium are selected
        if(pseudonymId > 0 && mediumId > 0)
        {
            // List of pseudo
            var pseudonyms = {};
            pseudoSelect.find('option').each(function(){
                var pid = $(this).val();
                if(pid !== ''){pseudonyms[pid] = $(this).text();}
            });

            // List of mediums
            var mediums = {};
            mediumSelect.find('option').each(function(){
                var mid = $(this).val();
                if(mid !== ''){mediums[mid] = $(this).text();}
            });

            // Association list
            var inconsistencyPseudoMedium = '';
            for(var pmPseudo in pseudoMedium)
            {
                if(pseudoMedium.hasOwnProperty(pmPseudo))
                {
                    pmPseudo *= 1;
                    var pmMedium = pseudoMedium[pmPseudo] * 1;
                    var pseudoName = pmPseudo in pseudonyms ? pseudonyms[pmPseudo] : '';
                    var mediumName = pmMedium in mediums ? mediums[pmMedium] : '';

                    // Pseudonym affected to this medium is different than history
                    if(pmPseudo !== pseudonymId && pmMedium === mediumId){
                        inconsistencyPseudoMedium += '<br>- La voyante ' + (mediumName !== '' ? '<b>' + mediumName + '</b>' : 'choisie') + ' est ' + (pseudoName !== '' ? '<b>' + pseudoName + '</b>.': 'a eu un autre pseudo.');
                    }

                    // Medium affected to this pseudo is different than history
                    if(pmPseudo === pseudonymId && pmMedium !== mediumId){
                        inconsistencyPseudoMedium += '<br>- Le pseudo ' + (pseudoName !== '' ? '<b>' + pseudoName + '</b>' : 'choisi') + ' est associé à ' + (mediumName !== '' ? '<b>' + mediumName + '</b>.': 'une autre voyante.');
                    }

                    // Ajouter la notion de dernier pseudo ou derniere voyante en date
                    // !!! vérifier si id (pseudo ou medium) sont présents dans le selecteur respectif (cas de la voyante partie ou pseudo desactivé)
                }
            }

            // There is a least one inconsistency
            if (inconsistencyPseudoMedium !== '')
            {
                inconsistencyContainer.removeClass('d-none');
                $('.order_inconsistency_medium_pseudonym_text').html(inconsistencyPseudoMedium);
            }
        }
        else if (pseudonymId > 0 && trigger === 'pseudo') // Pseudo is selected
        {
            for(var pmPseudo in pseudoMedium)
            {
                if(pseudoMedium.hasOwnProperty(pmPseudo))
                {
                    pmPseudo = pmPseudo * 1;
                    var pmMedium = pseudoMedium[pmPseudo] * 1;

                    // Select last medium associated to this pseudo
                    if(pmPseudo === pseudonymId){mediumSelect.val(pmMedium);}
                }
            }
        }
        else if (mediumId > 0 && trigger === 'medium') // Medium is selected
        {
            var reloadHistory = false;
            for(var pmPseudo in pseudoMedium)
            {
                if(pseudoMedium.hasOwnProperty(pmPseudo))
                {
                    pmPseudo = pmPseudo * 1;
                    var pmMedium = pseudoMedium[pmPseudo] * 1;

                    // Select last pseudo associated to this medium
                    if(pmMedium === mediumId)
                    {
                        // Update pseudo
                        pseudoSelect.val(pmPseudo);
                        reloadHistory = true;
                    }
                }
            }

            // Recalculate origin
            if(reloadHistory){
            	orderCustomerHistoryOriginChange();
            }
        }
    }
}

// Order search duplicates customers by name, firstname and/or birthdate
function orderCustomerSearchDuplicateCustomers(obj)
{
    // Infos
    var phone = $('#order_customer_new_phone_display_phone').val().replace(" ", "");
    var phone2 = $('#order_customer_new_phone_2_display_phone').val().replace(" ", "");
    var lastname = $('#order_customer_lastname').val();
    var firstname = $('#order_customer_firstname').val();
    var postalcode = $('#order_customer_address_display_postal_code').val();
    var email = $('#order_customer_new_email_email').val();

    // Birthdate
    var bYear = $('#order_customer_birth_date_year option:selected').val();
    var bMonth = $('#order_customer_birth_date_month option:selected').text();
    var bDay = $('#order_customer_birth_date_day option:selected').text();
    var birthdate = bYear > 1900 && bMonth > 0 && bDay > 0 ? bYear + '-' + bMonth + '-' + bDay : null;

    // Customer Id
    var customerId = $('#order_customer_customer option:selected').val();

    // Lastname of 2 car and is new customer to start search
    if(lastname.length > 1 && (customerId === '' || customerId <= 0))
    {
        // Send elements to search
        $.ajax({
            url: "/json/order/searchCustomerDuplicateCustomers",
            type: "GET",
            dataType: "JSON",
            data: {
                phone: phone,
                phone2: phone2,
                lastname: lastname,
                firstname: firstname,
                birthdate: birthdate,
                postalcode: postalcode,
                email: email
                //kc: md5(todayString + '_' + customerId),
            },
            success: function (result)
            {
                // Store result in alert box
                if (result.customers > 0)
                {
                    $('#order_customer_duplicate_customers').removeClass('d-none');
                    $('.order_customer_duplicate_customers_text').html(result.message);

                    // Load a customer into order_add / appointment after search
                    $('.jquery-order-reload-customer').click(function(){orderCustomerLoad($(this).attr('customerid'), null);});
                }
                else
                {
                    $('#order_customer_duplicate_customers').addClass('d-none');
                }
            },
            error: function (err) {
                $('#order_customer_duplicate_customers').addClass('d-none');
            }
        });
    }
}

// Activate duplicate customer function on objects
function orderCustomerSearchDuplicateCustomersTrigger()
{
    $('.order-customer-search-duplicate-customers-input').on("paste blur", function(){orderCustomerSearchDuplicateCustomers($(this));});
    $('.order-customer-search-duplicate-customers-select').on("change", function(){orderCustomerSearchDuplicateCustomers($(this));});
}

// Change price after changing service
function orderServiceChangeServicePrice()
{
    // Selected service
    var serviceSelected = $('.orderService option:selected').val() * 1;

    // Prices and amounts for all services
    var servicePricesList = parseJSON($('.order-customer-prices-list').val());
    var serviceAmountsList = parseJSON($('.order-customer-amounts-list').val());

    // Init lists for selected service
    var pricesList = serviceSelected > 0 && typeof servicePricesList !== 'undefined' && servicePricesList !== false ? servicePricesList[serviceSelected] : [];
    var amountsList = serviceSelected > 0 && typeof serviceAmountsList !== 'undefined' && serviceAmountsList !== false ? serviceAmountsList[serviceSelected] : [];

    // Prices select
    var servicePriceObj = $('.servicePrice');

    // Selected price
    var selectedPriceId = servicePriceObj.find('option:selected').val();

    // Each price (option)
    servicePriceObj.find('option').each(function()
    {
        var priceId = $(this).val();
        if($.inArray(priceId * 1, pricesList) >= 0 || priceId === '' || pricesList.length === 0)
        {
            if(selectedPriceId === priceId){servicePriceObj.val(priceId);}
            $(this).show();
        }
        else{
            $(this).hide();
            if(priceId === selectedPriceId){servicePriceObj.val('');}
        }
    });

    // Each amount (link)
    $('.serviceAuthorizationAmountDropdown').find('a').each(function()
    {
        var AmountId = $(this).attr('amount');
        if($.inArray(AmountId * 1, amountsList) >= 0 || amountsList.length === 0){
            $(this).removeClass('d-none');
        }
        else{
            $(this).addClass('d-none');
        }
    });
}

// Show or hide elements and mark some sub elements as required
function showHideElementsOnSelect()
{
    $('.globalShowHideOnSelect').change(function ()
    {
        var emptyShow = $(this).attr("empty_show");
        var emptyHide = $(this).attr("empty_hide");

        // If there is something to show when value is null
        if($(this).val().length === 0 && emptyShow.length > 0)
        {
            $('.' + emptyShow).removeClass('d-none');
            $('.' + emptyShow + '-required').prop("required", true);
        }
        else if(emptyShow.length > 0)
        {
            $('.' + emptyShow).addClass('d-none');
            $('.' + emptyShow + '-required').prop("required", false);
        }

        // If there is something to hide when value is null
        if($(this).val().length === 0 && emptyHide.length > 0)
        {
            $('.' + emptyHide).addClass('d-none');
            $('.' + emptyHide + '-required').prop("required", false);
        }
        else if(emptyHide.length > 0)
        {
            $('.' + emptyHide).removeClass('d-none');
            $('.' + emptyHide + '-required').prop("required", true);
        }
    });
}

// Delete current collection item on delete button press
function collectionDeleteItem()
{
    $('.CollectionDeleteItemButton').click(function ()
    {
        var tableContainer = $(this).closest('table');
        var addButton = $('#' + tableContainer.attr('id').replace(/table+$/, 'addbutton'));
        var formIndex = addButton.attr("formindex");

        // Delete line
        $(this).closest('tr').remove();

        // Decrement counter
        addButton.attr("formindex", (formIndex * 1) - 1);
    });
}

// Calculate installments amounts
function paymentInstallmentsCalculateAmounts(first = 0, nexts = 0)
{
    var iParts = ($('.paymentInstallmentParts').val() * 1) - 1;
    var iType = $('.paymentInstallmentType').val() * 1;

    // Amount to pay
    var amountToPay = 0;

    // Service price
    var servicePrice = $('.servicePrice option:selected').attr('base') * 1;

    // If base price is positive
    if(servicePrice > 0){
       amountToPay = servicePrice;
    }

    // Existing Promotion
    var promotion = $('.servicePromotion option:selected');
    if((promotion.val() * 1) > 0)
    {
        var pCategory = promotion.attr('category') * 1;
        var pValue = promotion.attr('promotionvalue') * 1;

        if(pCategory === 1 && amountToPay >= pValue){ // Amount
            amountToPay = amountToPay - pValue;
        }
        else if(pCategory === 2){ // Minute

        }
        else if(pCategory === 3 && amountToPay > 0){ // %
            amountToPay = amountToPay * (100 - pValue) / 100;
        }
        else if(pCategory === 4){ // Replace
            amountToPay = pValue;
        }
    }

    // Working with integers
    amountToPay = Math.round(amountToPay * 100);
    first = Math.round(first * 100);
    nexts = Math.round(nexts * 100);

    // Type of payment installment
    if(iType === 2 && amountToPay >= 100)
    {
        if(first <= 0){first = 100;}
        if(nexts <= 0){nexts = amountToPay - 100;}
    }
    else if(iType === 3 && amountToPay >= 100)
    {
        if(nexts <= 0 && first <= 0){
            nexts = Math.round(amountToPay / (iParts + 1));
        }
        else if(first > 0)
        {
            // First payment must be maximum 1€ (x parts) less than the total
            if(first > (amountToPay - (100 * iParts))){
                first = amountToPay - (100 * iParts);
            }

            // First payment must be minimum 1€
            if(first < 100){first = 100;}

            // Calculate next payments
            nexts = Math.round((amountToPay - first) / iParts);
            if(nexts < 100){nexts = 100;}
        }
        else
        {
            // Next payments must be maximum 1€ less than the total divided by number of installments
            if(nexts > (amountToPay - 100) / iParts){
                nexts = (amountToPay - 100) / iParts;
            }

            // Next payments must be minimum 1€
            if(nexts < 100){nexts = 100;}
        }

        // Calculate first payment
        first = amountToPay - (nexts * iParts);
    }

    // Update amounts values
    $('.paymentInstallmentFirstAmount').val((first / 100).toFixed(2)); // .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    $('.paymentInstallmentNextAmounts').val((nexts / 100).toFixed(2)); // .replace(/\d(?=(\d{3})+\.)/g, "$&,")

    //return {first: first, nexts: nexts};
}

function checkMediumScheduleForAppointment()
{
    // Variables
    var appointmentId = $('.orderAppointmentId').val() * 1;
    var appointmentDate = $('.orderAppointmentDate').val();
    var appointmentTimeHour = $('.orderAppointmentTimeHour option:selected').val() * 1;
    var appointmentTimeMinute = $('.orderAppointmentTimeMinute option:selected').val() * 1;
    var serviceId = $('.orderService option:selected').val() * 1;
    var servicePriceId = $('.servicePrice option:selected').val() * 1;
    var mediumId = $('.medium option:selected').val() * 1;

    // Datetime
    var appointmentDateFormat = appointmentDate !== undefined && appointmentDate.length === 10 ? appointmentDate.split('/').reverse().join('-') : null;
    var appointmentDateTime = appointmentDateFormat !== null ? appointmentDateFormat + ' ' + ('0' + appointmentTimeHour).slice(-2) + ':' + ('0' + appointmentTimeMinute).slice(-2) + ':00' : null;

    // Empty content
    $('#OrderAppointmentMediumPlanningCheck').html("");

    // Hide medium consent
    $('#OrderAppointmentMediumValidationContainer').addClass("d-none");

    if(mediumId > 0 && appointmentDateTime !== null)
    {
        // Send elements to search
        $.ajax({
            url: "/json/order/checkMediumScheduleForAppointment",
            type: "POST",
            dataType: "JSON",
            data: {
                appointmentId: appointmentId,
                appointmentDateTime: appointmentDateTime,
                serviceId: serviceId,
                servicePriceId: servicePriceId,
                mediumId: mediumId
            },
            success: function (result)
            {
                $('#OrderAppointmentMediumPlanningCheck').html(result.content);

                // Show medium consent
                if(result.error === 1){
                    $('#OrderAppointmentMediumValidationContainer').removeClass("d-none");
                }
            },
            error: function (err) {
            }
        });
    }
}

// Change order origin regarding the customer history
function orderCustomerHistoryOriginChange()
{
    // Default origin (1 - New customer)
    var originType = 1;

    // Linked services
    var customerHistoryServiceList = parseJSON($('.order-customer-history-services').val());
    if(customerHistoryServiceList && Array.isArray(customerHistoryServiceList))
    {
        // selected Service
        var serviceSelected = $('.orderService option:selected').val() * 1;

        // All Services and their children
        var serviceChildrenList = parseJSON($('.order-customer-serviceschildren-list').val());

        // Selected service children
        var selectedServicesList = serviceSelected > 0 && typeof serviceChildrenList !== 'undefined' && serviceChildrenList !== false ? serviceChildrenList[serviceSelected] : [];

        // Service children list is an array
        if(Array.isArray(selectedServicesList))
        {
            $.each(selectedServicesList, function(key, value){
                if($.inArray(value, customerHistoryServiceList) >= 0){originType = 2;} // Customer of the service
            });
        }
    }

    //console.log(selectedServicesList);


    // Linked services
    /*var customerHistoryServiceList = parseJSON($('.order-customer-history-services').val());
    if(customerHistoryServiceList && Array.isArray(customerHistoryServiceList))
    {
        // selected Service(s)
        var selectedServicesList = parseJSON($('.orderService option:selected').attr('services'));
        if(Array.isArray(selectedServicesList))
        {
            $.each(selectedServicesList, function(key, value){
                if($.inArray(value, customerHistoryServiceList) >= 0){originType = 2;} // Customer of the service
            });
        }
    }*/

    // Pseudonym
    var customerHistoryPseudonymsList = parseJSON($('.order-customer-history-pseudonyms').val());
    if(customerHistoryPseudonymsList && Array.isArray(customerHistoryPseudonymsList))
    {
        // selected Pseudonym
        var pseudonymSelected = $('.mediumPseudonym option:selected').val() * 1;
        if(pseudonymSelected > 0 && $.inArray(pseudonymSelected, customerHistoryPseudonymsList) >= 0){originType = 3;} // Customer of the pseudonym
    }

    // Update
    var storedOrigin = $('.orderOrigin option:selected').val() * 1;
    var first = true;
    $('.orderOrigin option').each(function()
    {
        var optionId = $(this).val() * 1;
        if($(this).attr('origin_type') * 1 === originType || optionId === 0)
        {
            $(this).removeClass('d-none');

            if(first || optionId === storedOrigin){
                $(this).prop('selected', true);
            }
            if(optionId > 0 || originType === 1){
                first = false;
            }
        }
        else{
            $(this).addClass('d-none');
        }
    });
}

// Parse json and return it if well formed
function parseJSON(str)
{
    if(typeof(str) !== 'string') {
        return false;
    }

    try{
        return JSON.parse(str);
    } catch (e) {
        return false;
    }
}

function getDateDiffByType(datediff, datetype)
{
    var date = new Date();
    date.setDate(date.getDate() + datediff);

    if(datetype === 'day'){
        return date.getDate();
    }
    else if(datetype === 'month'){
        return date.getMonth() + 1;
    }
    else if(datetype === 'year'){
        return date.getFullYear();
    }

    return false;
}

/* MD5 */
var md5 = function (string)
{
    function RotateLeft(lValue, iShiftBits) {
        return (lValue<<iShiftBits) | (lValue>>>(32-iShiftBits));
    }
    function AddUnsigned(lX,lY) {
        var lX4,lY4,lX8,lY8,lResult;
        lX8 = (lX & 0x80000000);
        lY8 = (lY & 0x80000000);
        lX4 = (lX & 0x40000000);
        lY4 = (lY & 0x40000000);
        lResult = (lX & 0x3FFFFFFF)+(lY & 0x3FFFFFFF);
        if (lX4 & lY4) {
            return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
        }
        if (lX4 | lY4) {
            if (lResult & 0x40000000) {
                return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
            } else {
                return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
            }
        } else {
            return (lResult ^ lX8 ^ lY8);
        }
    }
    function F(x,y,z) { return (x & y) | ((~x) & z); }
    function G(x,y,z) { return (x & z) | (y & (~z)); }
    function H(x,y,z) { return (x ^ y ^ z); }
    function I(x,y,z) { return (y ^ (x | (~z))); }
    function FF(a,b,c,d,x,s,ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };
    function GG(a,b,c,d,x,s,ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };
    function HH(a,b,c,d,x,s,ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };
    function II(a,b,c,d,x,s,ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };
    function ConvertToWordArray(string) {
        var lWordCount;
        var lMessageLength = string.length;
        var lNumberOfWords_temp1=lMessageLength + 8;
        var lNumberOfWords_temp2=(lNumberOfWords_temp1-(lNumberOfWords_temp1 % 64))/64;
        var lNumberOfWords = (lNumberOfWords_temp2+1)*16;
        var lWordArray=Array(lNumberOfWords-1);
        var lBytePosition = 0;
        var lByteCount = 0;
        while ( lByteCount < lMessageLength ) {
            lWordCount = (lByteCount-(lByteCount % 4))/4;
            lBytePosition = (lByteCount % 4)*8;
            lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount)<<lBytePosition));
            lByteCount++;
        }
        lWordCount = (lByteCount-(lByteCount % 4))/4;
        lBytePosition = (lByteCount % 4)*8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80<<lBytePosition);
        lWordArray[lNumberOfWords-2] = lMessageLength<<3;
        lWordArray[lNumberOfWords-1] = lMessageLength>>>29;
        return lWordArray;
    };
    function WordToHex(lValue) {
        var WordToHexValue="",WordToHexValue_temp="",lByte,lCount;
        for (lCount = 0;lCount<=3;lCount++) {
            lByte = (lValue>>>(lCount*8)) & 255;
            WordToHexValue_temp = "0" + lByte.toString(16);
            WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length-2,2);
        }
        return WordToHexValue;
    };
    function Utf8Encode(string) {
        string = string.replace(/\r\n/g,"\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }
        return utftext;
    };
    var x=Array();
    var k,AA,BB,CC,DD,a,b,c,d;
    var S11=7, S12=12, S13=17, S14=22;
    var S21=5, S22=9 , S23=14, S24=20;
    var S31=4, S32=11, S33=16, S34=23;
    var S41=6, S42=10, S43=15, S44=21;
    string = Utf8Encode(string);
    x = ConvertToWordArray(string);
    a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;
    for (k=0;k<x.length;k+=16) {
        AA=a; BB=b; CC=c; DD=d;
        a=FF(a,b,c,d,x[k+0], S11,0xD76AA478);
        d=FF(d,a,b,c,x[k+1], S12,0xE8C7B756);
        c=FF(c,d,a,b,x[k+2], S13,0x242070DB);
        b=FF(b,c,d,a,x[k+3], S14,0xC1BDCEEE);
        a=FF(a,b,c,d,x[k+4], S11,0xF57C0FAF);
        d=FF(d,a,b,c,x[k+5], S12,0x4787C62A);
        c=FF(c,d,a,b,x[k+6], S13,0xA8304613);
        b=FF(b,c,d,a,x[k+7], S14,0xFD469501);
        a=FF(a,b,c,d,x[k+8], S11,0x698098D8);
        d=FF(d,a,b,c,x[k+9], S12,0x8B44F7AF);
        c=FF(c,d,a,b,x[k+10],S13,0xFFFF5BB1);
        b=FF(b,c,d,a,x[k+11],S14,0x895CD7BE);
        a=FF(a,b,c,d,x[k+12],S11,0x6B901122);
        d=FF(d,a,b,c,x[k+13],S12,0xFD987193);
        c=FF(c,d,a,b,x[k+14],S13,0xA679438E);
        b=FF(b,c,d,a,x[k+15],S14,0x49B40821);
        a=GG(a,b,c,d,x[k+1], S21,0xF61E2562);
        d=GG(d,a,b,c,x[k+6], S22,0xC040B340);
        c=GG(c,d,a,b,x[k+11],S23,0x265E5A51);
        b=GG(b,c,d,a,x[k+0], S24,0xE9B6C7AA);
        a=GG(a,b,c,d,x[k+5], S21,0xD62F105D);
        d=GG(d,a,b,c,x[k+10],S22,0x2441453);
        c=GG(c,d,a,b,x[k+15],S23,0xD8A1E681);
        b=GG(b,c,d,a,x[k+4], S24,0xE7D3FBC8);
        a=GG(a,b,c,d,x[k+9], S21,0x21E1CDE6);
        d=GG(d,a,b,c,x[k+14],S22,0xC33707D6);
        c=GG(c,d,a,b,x[k+3], S23,0xF4D50D87);
        b=GG(b,c,d,a,x[k+8], S24,0x455A14ED);
        a=GG(a,b,c,d,x[k+13],S21,0xA9E3E905);
        d=GG(d,a,b,c,x[k+2], S22,0xFCEFA3F8);
        c=GG(c,d,a,b,x[k+7], S23,0x676F02D9);
        b=GG(b,c,d,a,x[k+12],S24,0x8D2A4C8A);
        a=HH(a,b,c,d,x[k+5], S31,0xFFFA3942);
        d=HH(d,a,b,c,x[k+8], S32,0x8771F681);
        c=HH(c,d,a,b,x[k+11],S33,0x6D9D6122);
        b=HH(b,c,d,a,x[k+14],S34,0xFDE5380C);
        a=HH(a,b,c,d,x[k+1], S31,0xA4BEEA44);
        d=HH(d,a,b,c,x[k+4], S32,0x4BDECFA9);
        c=HH(c,d,a,b,x[k+7], S33,0xF6BB4B60);
        b=HH(b,c,d,a,x[k+10],S34,0xBEBFBC70);
        a=HH(a,b,c,d,x[k+13],S31,0x289B7EC6);
        d=HH(d,a,b,c,x[k+0], S32,0xEAA127FA);
        c=HH(c,d,a,b,x[k+3], S33,0xD4EF3085);
        b=HH(b,c,d,a,x[k+6], S34,0x4881D05);
        a=HH(a,b,c,d,x[k+9], S31,0xD9D4D039);
        d=HH(d,a,b,c,x[k+12],S32,0xE6DB99E5);
        c=HH(c,d,a,b,x[k+15],S33,0x1FA27CF8);
        b=HH(b,c,d,a,x[k+2], S34,0xC4AC5665);
        a=II(a,b,c,d,x[k+0], S41,0xF4292244);
        d=II(d,a,b,c,x[k+7], S42,0x432AFF97);
        c=II(c,d,a,b,x[k+14],S43,0xAB9423A7);
        b=II(b,c,d,a,x[k+5], S44,0xFC93A039);
        a=II(a,b,c,d,x[k+12],S41,0x655B59C3);
        d=II(d,a,b,c,x[k+3], S42,0x8F0CCC92);
        c=II(c,d,a,b,x[k+10],S43,0xFFEFF47D);
        b=II(b,c,d,a,x[k+1], S44,0x85845DD1);
        a=II(a,b,c,d,x[k+8], S41,0x6FA87E4F);
        d=II(d,a,b,c,x[k+15],S42,0xFE2CE6E0);
        c=II(c,d,a,b,x[k+6], S43,0xA3014314);
        b=II(b,c,d,a,x[k+13],S44,0x4E0811A1);
        a=II(a,b,c,d,x[k+4], S41,0xF7537E82);
        d=II(d,a,b,c,x[k+11],S42,0xBD3AF235);
        c=II(c,d,a,b,x[k+2], S43,0x2AD7D2BB);
        b=II(b,c,d,a,x[k+9], S44,0xEB86D391);
        a=AddUnsigned(a,AA);
        b=AddUnsigned(b,BB);
        c=AddUnsigned(c,CC);
        d=AddUnsigned(d,DD);
    }
    var temp = WordToHex(a)+WordToHex(b)+WordToHex(c)+WordToHex(d);
    return temp.toLowerCase();
}
/* // MD5 */

$(document).ready(function()
{
    /*
    * GENERAL
    */

    // Select2
    $('.friendlyselect').select2({
        theme: "bootstrap4"
    });

    // Show/hide elements when selecting a value
    showHideElementsOnSelect();

    // Change case while writing text
    strokeControls();

    /*
    * ADDRESS
    */

    // Update customer address field 1 on each change of title, lastname or firstname
    $('.customerfield1title').on('change', updateAddressField1);
    $('.customerfield1lastname, .customerfield1firstname').on('keyup', updateAddressField1);

    /*
    * ORDER
    */

    // Check duplicate order tab
    preventOrderDuplicateOnDuplicateTab();

    // History
    displayCustomerHistory();

    // Customer Birth details
    displayCustomerBirthDetails();

    // Order Payment mode options
    updateOrderPaymentOptions();

    // Order Payment links send display (without submit)
    $('.paymentSendLinkBy').change(function(){orderPaymentLinkSendLoad(false);});

    // Order and Appointment prepayment list
    orderPrepaymentsListRefresh();

    // Close search results
    $('#order_search_results_close').click(function(event){
        $('#search_customer_results').addClass('d-none');
    });

    // Duplicate phone number searched to new Phone field
    $('.customerSearchPhone').on("change keyup paste click input", function()
    {
        var customerId = $('.customerId').val();
        if(customerId === '' || customerId <= 0)
        {
            var newPhone = $('.customerNewPhone').first();
            if($(this).val().length > newPhone.val().length){
                newPhone.val($(this).val());
                $('.formatInternationalPhoneNumber').keyup();
            }
        }
    });

    // Display payment installments options and calculate amounts
    $('.paymentInstallmentType').on("change", function()
    {
        // Variables
        var iType = $(this).val() * 1;

        // Display blocs
        var dBloc1 = $('.order_paymentLink_installment_1');
        var dBloc2 = $('.order_paymentLink_installment_2');

        // Action depending on type
        if(iType === 1)
        {
            // No installments
            dBloc1.addClass('d-none');
            dBloc2.addClass('d-none');
        }
        else
        {
            dBloc1.removeClass('d-none');

            // Installment Parts limited or not
            $('.paymentInstallmentParts option').each(function()
            {
                var partId = $(this).val() * 1;
                if(partId === 2)
                {
                    $(this).show();
                    $(this).prop('selected', true);
                }
                else if(iType === 2){$(this).hide();}
                else if(iType === 3){$(this).show();}
            });

            // Show/Hide second bloc
            if(iType === 2){
                dBloc2.addClass('d-none');
            }
            else if(iType === 3){
                dBloc2.removeClass('d-none');
            }

            // Calculate amounts
            paymentInstallmentsCalculateAmounts(0, 0);

            // Hide send link bloc and reset send link select list
            $('#order_paymentLink_content').html("");
            $('.paymentSendLinkBy').val("");
        }
    });

    // Check if medium is not available for an appointment or if appointment overlap another
    $('.orderAppointmentDate, .orderAppointmentTimeHour, .orderAppointmentTimeMinute, .orderService, .servicePrice, .medium, .mediumPseudonym').on("change", function(){checkMediumScheduleForAppointment();});

    // Calculate installments amounts
    $('.servicePrice, .servicePromotion, .paymentInstallmentParts').on("change", function()
    {
        // Calculate amounts
        paymentInstallmentsCalculateAmounts(0, 0);

        // Hide send link bloc and reset send link select list
        $('#order_paymentLink_content').html("");
        $('.paymentSendLinkBy').val("");
    });

    // Display payment installments options and calculate amounts
    $('.paymentInstallmentFirstAmount, .paymentInstallmentNextAmounts').on("blur", function()
    {
        var first = 0; var nexts = 0;
        var inputValue = ($(this).val().replace(',', '.')) * 1;

        if($(this).hasClass('paymentInstallmentFirstAmount')){
            first = inputValue;
        }
        else if($(this).hasClass('paymentInstallmentNextAmounts')){
            nexts = inputValue;
        }

        // Calculate amounts
        paymentInstallmentsCalculateAmounts(first, nexts);
    });

    // Calculate available car length for SMS message (service)
    $('.serviceSmsMessageLength').on("change keyup paste click input", function()
    {
        var message = $(this).val();
        var maxLengthPseudo = '';
        var maxLengthUrl = '';
        var priceName = '';
        var priceAmount = 0;

        // Search longest Url and associated price
        $('[id^="service_servicePrices_"][id$="_payment_url"]').each(function()
        {
            var url = $(this).val();
            var priceSelectId = $(this).attr('id').replace(/payment_url+$/, 'price');

            if(url.length > maxLengthUrl.length)
            {
                var priceSelect = $('#' + priceSelectId + ' option:selected');

                maxLengthUrl = url;
                priceName = priceSelect.attr("price_name");
                priceAmount = priceSelect.attr("base_price");
            }
        });

        // Search pseudo
        $('[id^="service_serviceMediumPseudonyms_"][id$="_medium_pseudonym"]').each(function()
        {
            var pseudo = $(this).find('option:selected').text();
            if(pseudo.length > maxLengthPseudo.length) {
                maxLengthPseudo = pseudo;
            }
        });

        // Replace url with longest found
        message = message.replace("[url]", maxLengthUrl);

        // Replace price name
        message = message.replace("[price]", priceName);

        // Replace amount
        message = message.replace("[amount]", priceAmount);

        // Replace pseudo
        message = message.replace("[pseudo]", maxLengthPseudo);

        // Update fields
        $('#service_ovh_sms_message_length').html(message.length);
        $('#service_ovh_sms_message_exemple').html(message.replace("\n", "<br>"));
    });

    // Search duplicate cards
    $('.order-customer-search-duplicate-cards').on("paste blur", function()
    {
        // Customer Id
        var customerId = $('#order_customer_id').val();

        // Customer's firstname and lastname
        var lastname = $('#order_customer_lastname').val();
        var firstname = $('#order_customer_firstname').val();

        // Card properties
        var cardNumber = $('#order_new_card_number').val();
        cardNumber = cardNumber.substr(cardNumber.length - 4);
        var cardYear = $('#order_new_card_date_year option:selected').val();
        var cardMonth = $('#order_new_card_date_month option:selected').val();
        var cardCvc = $('#order_new_card_cvc').val();

        // Is new customer to start search
        if(customerId === '' || customerId <= 0 )
        {
            // Send elements to search
            $.ajax({
                url: "/json/order/searchCustomerDuplicateCards",
                type: "GET",
                dataType: "JSON",
                data: {
                    lastname: lastname,
                    firstname: firstname,
                    cardNumber: cardNumber,
                    cardYear: cardYear,
                    cardMonth: cardMonth,
                    cardCvc: cardCvc
                    //kc: md5(todayString + '_' + customerId),
                },
                success: function (result)
                {
                    // Store same customers results in alert box
                    if (result.same.length > 0)
                    {
                        $('#order_customer_cards_same_customers').removeClass('d-none');
                        $('.order_customer_cards_same_customers_text').html(result.same);
                    }
                    else {
                        $('#order_customer_cards_same_customers').addClass('d-none');
                    }

                    // Store different customers results in alert box
                    if (result.different.length > 0)
                    {
                        $('#order_customer_cards_different_customers').removeClass('d-none');
                        $('.order_customer_cards_different_customers_text').html(result.different);
                    }
                    else {
                        $('#order_customer_cards_different_customers').addClass('d-none');
                    }
                },
                error: function (err) {
                    // Hide message boxes
                    $('#order_customer_cards_same_customers').addClass('d-none');
                    $('#order_customer_cards_different_customers').addClass('d-none');
                }
            });
        }
    });

    // Display failed payments line on payments list (order edition)
    $('.OrderDisplayPaymentFailedLines').on("click", function()
    {
        if($(this).attr("showHide") === '0')
        {
            $(this).attr("showHide", 1);
            $('.OrderDisplayPaymentFailedLine').addClass('d-none');
        }
        else
        {
            $(this).attr("showHide", 0);
            $('.OrderDisplayPaymentFailedLine').removeClass('d-none');
        }
    });

    // Modify origin accordingly to customer history
    $('.orderService, .mediumPseudonym').change(function () {
        orderCustomerHistoryOriginChange();
    });

    // Change price after changing service
    $('.serviceChangeServicePrice').change(function(){orderServiceChangeServicePrice();});

    // Check validity of couple pseudo/medium regarding customer history
    $('.order-customer-history-select-medium').change(function(){orderControlValidityPseudonymMedium('medium');});
    $('.order-customer-history-select-pseudo').change(function(){orderControlValidityPseudonymMedium('pseudo');});

    // Load a customer into order_add / appointment after search
    $('.jquery-order-reload-customer').click(function(){orderCustomerLoad($(this).attr('customerid'), null);});

    // Load order payment form loading on new line button
    orderPaymentFormLoad();

    // Appointment modes display
    updateOrderAppointmentPaymentModes();

    // Edit or unEdit a customer from appointment
    orderCustomerEdit();

    // Customer known or new from customer select
    orderCustomerChange();

    // Activate function of customer duplicate search
    orderCustomerSearchDuplicateCustomersTrigger();

    // Clear form errors after failed customer save and remove edition mode
    $('.order-appointment-clear-form-errors').mouseover(function(event)
    {
        // Remove edition mode (if customer exists)
        var customerId = $('#order_appointment_customer').val();
        if(customerId && customerId > 0){orderCustomerEditActivation(false);}

        // Hide customer save errors
        $('#order_appointment_birth_date, #order_appointment_title, #order_appointment_firstname, #order_appointment_city').removeClass('is-invalid');
        $('.order-appointment-clear-form-error').remove();
        //$('.alert-danger').remove();
    });

    /*
   * PROMOTION
   */

    // Formating international phone numbers
    $('.promotionCategoryDisplayParameters').change(function(event)
    {
        // Search the div to display
        var promotionType = $(this).find('option:selected').attr("promotion_type");
        var divToDisplay = $('#promotion_type_' + promotionType);

        // Requirement
        $('.promotion-form-element').prop("required", false);
        $('.promotion-form-' + promotionType).prop("required", true);

        // .promotionDisplayParameter
        $('div.promotionDisplayParameter').not(divToDisplay).addClass('d-none');
        divToDisplay.removeClass('d-none');
    });

    /*
   * PHONE
   */

    // Update each phone code of a collection from country select value
    updateCountryPhoneCode();

    // Formating international phone numbers
    formatInternationalPhoneNumber();

    // Select main object of any collection (.CollectionSetMain)
    setCollectionMainValue();

    /*
   * MEDIUM ACCOUNTING
   */

    // Create a new line of a collection on the button "add" press
    $('.PeriodRecalculate').change(function ()
    {
        var period = $(this).val();
        var btn = $('.PeriodRecalculateButton');

        if(period > 0){
            btn.removeClass('d-none');
            btn.attr("period", period)
        }
        else{
            btn.addClass('d-none');
        }
    });

    /*
    * COLLECTIONS
    */

    // Delete current collection item on delete button press
    collectionDeleteItem();

    // Create new lines of a collection on the button "add" press
    $('.CollectionAddNewItemsButton').click(function ()
    {
        var formIndex = $(this).attr("formindex");
        var radixId = $(this).attr('id').replace(/addbutton+$/, '');
        var tableContainer = radixId + 'table';

        // Content
        var newFormLine = $(this).data('prototype').replace(/__name__/g, formIndex);

        // last line of table
        var lastline = $('#' + tableContainer + ' tr').last();
        lastline.after('<tr>' + newFormLine + '</tr>');

        // Increment counter
        $(this).attr("formindex", (formIndex * 1) + 1);

        // Item deletion
        collectionDeleteItem();
    });

    // Create a new line of a collection on the button "add" press
    $('.CollectionAddRemoveNewItemButton').click(function ()
    {
        var formIndex = $(this).attr("formindex");
        var radixId = $(this).attr('id').replace(/addbutton+$/, '');

        var newLine = radixId + 'newline';
        var tableContainer = radixId + 'table';
        var hasRequiredStopDate = $('#' + tableContainer + ' input.CollectionRequiredDateStop').length;

        if($(this).attr("addremovemode") === '0')
        {
            var newFormLine = $(this).data('prototype').replace(/__name__/g, formIndex);
            var hasDatepicker = $('#' + tableContainer + ' input.datepicker:not([readonly],[disabled])').length;
            var hasPhoneCode = $('#' + tableContainer + ' select.updateCountryPhoneCode').length;
            var hasFormatPhoneNumber = $('#' + tableContainer + ' input.formatInternationalPhoneNumber').length;
            var hasCollectionSetMain = $('#' + tableContainer + ' input.CollectionSetMain').length;

            // Insert prototype content into new line container
            $('#' + newLine).html(newFormLine);

            // Previous Stop Date required after adding a new line
            if(hasRequiredStopDate > 0){
                $('#' + radixId + (formIndex - 1) + '_stop_date').prop("required", true);
            }

            // Update mode attribute
            $(this).attr("addremovemode", '1');

            // Button Icon
            $(this).children('i').removeClass("fa-plus-square").addClass("fa-minus-square");

            // DatePicker
            if(hasDatepicker > 0){reloadDatePicker();}

            // CountryCode
            if(hasPhoneCode > 0){updateCountryPhoneCode();}

            // Format Phone number
            if(hasFormatPhoneNumber > 0){formatInternationalPhoneNumber();}

            // Format Phone number
            if(hasCollectionSetMain > 0){setCollectionMainValue();}
        }
        else
        {
            // Empty new line container
            $('#' + newLine).html('');

            // Previous Stop Date not required after removing a new line
            if(hasRequiredStopDate > 0){
                $('#' + radixId + (formIndex - 1) + '_stop_date').prop("required", false);
            }

            // Update mode attribute
            $(this).attr("addremovemode", '0');

            // Button Icon
            $(this).children('i').removeClass("fa-minus-square").addClass("fa-plus-square");
        }
    });

    /*
    * ORIGINS
    */

    // Update origin group input hidden from select value
    $('#global_origin_category').change(function ()
    {
        $('#global_origin_group_name').val($(this).find('option:selected').text());
    });


    /*
    * MEDIUM PLANNING MODEL
    */

    // Update available days for the planning model apply
    $('#medium_planning_model_apply_week').change(function ()
    {
        var today = $.datepicker.formatDate('yymmdd', new Date());

        for (var i = 1; i <= 7; i++)
        {
            var dateN = $('option:selected', this).attr('day' + i);

            if(today < dateN){
                $('#medium_planning_model_apply_days_' + i).prop("disabled", false);
            }
            else{
                $('#medium_planning_model_apply_days_' + i).attr("disabled", true).prop("checked", false);
            }
        }
    });

    /*
    * USER PLANNING MODEL
    */

    // Update available days for the planning model apply
    $('#user_planning_model_apply_week').change(function ()
    {
        var today = $.datepicker.formatDate('yymmdd', new Date());

        for (var i = 1; i <= 7; i++)
        {
            var dateN = $('option:selected', this).attr('day' + i);

            if(today < dateN){
                $('#user_planning_model_apply_days_' + i).prop("disabled", false);
            }
            else{
                $('#user_planning_model_apply_days_' + i).attr("disabled", true).prop("checked", false);
            }
        }
    });

    /*
   * AJAX DATA LOADING
   */

    // Copy to clipboard
    CopyToClipboard();

    // Update city locality in field 5
    updateCityLocality();

    // Cities loading from postal code
    updateCitiesFromPostalCode();

    // Save Order form element
    saveOrderOrder();

    // Save a customer from order
    saveOrderCustomer();

    // Ask bank authorization
    $('.jquery-ask-authorization').click(function ()
    {
        var selectedAmount = $(this).attr('amount');

        // Current datetime
        var today = new Date();
        var todayFormatted = today.getFullYear().toString()
            + (today.getMonth() < 9 ? '0' : '') + (today.getMonth() + 1)
            + (today.getDate() < 10 ? '0' : '') + today.getDate().toString()
            + (today.getHours() < 10 ? '0' : '') + today.getHours().toString()
            + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes().toString()
            + (today.getSeconds() < 10 ? '0' : '') + today.getSeconds().toString();

        // Order infos
        var orderNumber = $('#order_order_number').val();
        if(orderNumber === ''){orderNumber = todayFormatted;}
        var orderSubNumber = $('#order_sub_number').val();
        if(orderSubNumber === ''){orderSubNumber = '1';}
        var serviceId = $('#order_service option:selected').val();

        // Payment infos
        var paymentMode = $('#order_payment_mode option:selected').val();
        var paymentOption = $('#order_payment_option option:selected').val();
        var paymentDate = $('#order_payment_date').val();
        var bankId = $('#order_bank option:selected').val();
        var aliasId = $('#order_customer_card option:selected').val();
        var cardNumber = $('#order_new_card_number').val();
        var cardDateYear = $('#order_new_card_date_year option:selected').val();
        var cardDateMonth = $('#order_new_card_date_month option:selected').val();
        var cardDate = cardDateYear > 0 && cardDateMonth > 0 ? (cardDateMonth < 10 ? '0' : '') + cardDateMonth.toString() + cardDateYear.toString().substring(2, 4) : '';
        var cardCvc = $('#order_new_card_cvc').val();

        // Auth infos
        var authorizationCode = $('#order_authorization_code').val();

        // Customer infos
        var customerId = $('#order_customer_id').val();
        var customerName = $('#order_customer_lastname').val() + ' ' + $('#order_customer_firstname').val();
        //var customerPhoneSelect = $('#order_customer_main_phone option:selected');
        //var customerPhone = customerPhoneSelect.val() > 0 ? customerPhoneSelect.text() : $('#order_customer_new_phone_display_phone').val();
        //var customerEmailSelect = $('#order_customer_main_email option:selected');
        //var customerEmail = customerEmailSelect.val() > 0 ? customerEmailSelect.text() : $('#order_customer_new_email_email').val();
        //var customerAddress = $('#order_customer_address_field_4').val();
        var customerPostalCode = $('#order_customer_address_display_postal_code').val();
        var customerCitySelect = $('#order_customer_address_city option:selected');
        var customerCity = customerCitySelect.val() > 0 ? customerCitySelect.text() : '';
        var customerCountryCode = $('#order_customer_address_display_country option:selected').attr('countrycode');

        // Container
        var responseContainer = $('#authorization_results');
        var responseContainerLoad = responseContainer.find('div.container-fluid');
        responseContainerLoad.html();

        if(authorizationCode.length > 0)
        {
            alert("Il y a déjà un code d'autorisation fourni par la banque");
            return false;
        }

        if(aliasId.length === 0 && (cardNumber.length < 16 || cardDate.length !== 4 || cardCvc.length < 3 || cardCvc.length > 4 ))
        {
            alert("Les informations de la carte bancaires sont incomplétes.");
            return false;
        }

        // Show loader
        $('#loading_authorization').removeClass("d-none");

        // Send all informations to controller
        $.ajax({
            url: "/json/order/getAuthorization",
            type: "POST",
            dataType: "JSON",
            data: {
                orderNumber: orderNumber,
                orderSubNumber: orderSubNumber,
                serviceId: serviceId,
                paymentMode: paymentMode,
                paymentOption: paymentOption,
                bankId: bankId,
                aliasId: aliasId,
                cardNumber: cardNumber,
                cardDate: cardDate,
                cardCvc: cardCvc,
                paymentDate: paymentDate,
                customerId: customerId,
                customerName: customerName,
                //customerPhone: customerPhone,
                //customerEmail: customerEmail,
                //customerAddress: customerAddress,
                customerPostalCode: customerPostalCode,
                customerCity: customerCity,
                customerCountryCode: customerCountryCode,
                selectedAmount: selectedAmount
                //kc: md5(todayString + '_' + customerId),
            },
            success: function(result)
            {
                // Display container
                responseContainer.removeClass("d-none");

                // Css message color
                var responseCss = 'text-danger';
                if(result.status === '0'){
                    responseCss = 'text-warning';
                }
                else if(result.status === '2'){
                    responseCss = 'text-danger';
                }
                else if(result.status === '1'){
                    responseCss = 'text-success';
                }

                // Display container and message
                responseContainer.removeClass("d-none");
                responseContainerLoad.html('<p class="' + responseCss + '">' + result.message + '</p>');

                // Store results in form fields
                $('#order_authorization_code').val(result.status === '1' ? result.authorizationCode : '');
                $('#order_online_auth_card_alias').val(result.authorizationCardAlias);

                // Increment Sub Number if authorization fail
                var subNumber = $('#order_sub_number').val() * 1;
                if(result.status === '2'){$('#order_sub_number').val(subNumber + 1);}

                // Hide loader
                $('#loading_authorization').addClass("d-none");

            },
            error: function (err)
            {
                alert('Erreur lors du chargement');

                // Hide loader
                $('#loading_authorization').addClass("d-none");
            }
        });
    });

    // Datagrid clear filters
    $('.datagrid_clear_filters').click(function (event)
    {
        // Prevent normal action
        event.preventDefault();

        // form
        var form = $(this.form);

        // Dates to update default
        var datesToUpdate = form.find('[currentdatediff]');
        datesToUpdate.each(function()
        {
            var dateNewVal = getDateDiffByType($(this).attr('currentdatediff') * 1, $(this).attr('id').split("_").pop());
            if(dateNewVal){
                $(this).attr('default', dateNewVal);
            }
        });

        // Get All fields
        var inputs = form.find('input, select, textarea');
        inputs.each(function()
        {
            // Default value
            var defaultValue = $(this).attr('default');

            if(typeof defaultValue !== "undefined"){
                $(this).val(defaultValue);
            }
        });

        reloadDatagrid(false, 'POST', 1);
     });

    // Datagrid filter
    $('.datagrid_apply_filters').click(function (event)
    {
        // Prevent normal action
        event.preventDefault();

        // Reload Datagrid
        reloadDatagrid(false, 'POST', 1);
    });

    // Datagrid Pagination
    datagridChangePage();

    // Datagrid Validation
    datagridCustomAction();

    // Search customers matching criterias
    $('.jquery-search-customers').click(function ()
    {
        // Action on btn select
        var actionAttr = $(this).attr('action');
        var customerSelectAction = typeof actionAttr !== 'undefined' && actionAttr !== false ? actionAttr : 'order';

        var customerTel = $('#' + $(this).attr('id').replace("_button", "_telephone"));
        var customerType = $('#' + $(this).attr('id').replace("_button", "_type"));
        var customerText = $('#' + $(this).attr('id').replace("_button", "_name"));

        var searchContainer = $('#search_customer_results');
        var searchResultNumber = $('#search_customer_results_number');
        var searchContainerLoad = searchContainer.find('div.container-fluid');

        // Loading obj
        var searchLoading = $('#loading_search_customer');
        searchLoading.removeClass('d-none');

        // Hide blocs
        searchContainer.addClass('d-none');
        $('#order_customer_mediums').addClass('d-none');
        $('#order_customer_message').addClass('d-none');

        // Clear previous content
        searchContainerLoad.html('');

        // Init number
        searchResultNumber.html('(0)');

        // Send request
        $.ajax({
            url: "/json/order/searchCustomers",
            type: "GET",
            dataType: "JSON",
            data: {
                customerTel: customerTel.val(),
                customerType: customerType.val(),
                customerText: customerText.val(),
                //kc: md5(todayString + '_' + customerTel),
            },
            success: function(result)
            {
                // Display results
                searchContainerLoad.html(result.customerResults);

                // Display number
                searchResultNumber.html('(' + result.customerNumber + ')');

                // Hide loading
                searchLoading.addClass('d-none');

                // Display bloc
                searchContainer.removeClass('d-none');

                // Load a customer into order_add / appointment after search
                $('.jquery-order-reload-customer').click(function()
                {
                    var customerId = $(this).attr('customerid');

                    // Actions
                    if(customerSelectAction === 'order') {
                        orderCustomerLoad(customerId, null);
                    }
                    else if(customerSelectAction === 'review'){
                        customerReviewLoadCustomerAndPseudonyms(customerId, $(this).attr('customer'));
                    }
                });
            },
            error: function (err)
            {
                alert('Erreur lors du chargement');
                searchLoading.addClass('d-none');
            }
        });
    });

    // Check promotion code
    $('.jquery-check-promotion').click(function ()
    {
        var promotionCode = $('.order_promotion_code').val();
        var promotionMessageContainer = $('#order_promotion_result');
        var promotionMessageTitle = promotionMessageContainer.find('.order-promotion-badge');
        var promotionMessageText = promotionMessageContainer.find('.order-promotion-message');

        // Customer Id
        var customerObj = $('#order_customer_id');
        var customerId = customerObj.length > 0 ? customerObj.val() : $('.order_customer_id').val();

        // Service Id
        var serviceObj = $('#order_service option:selected');
        var serviceId = serviceObj.length > 0 ? serviceObj.val() : $('.order_service_id').val();

        // css
        promotionMessageContainer.removeClass('valid-feedback').addClass('invalid-feedback');
        promotionMessageTitle.removeClass('form-success-icon').removeClass('badge-success').addClass('form-error-icon').addClass('badge-danger');
        promotionMessageText.removeClass('form-success-message').removeClass('text-warning').addClass('form-error-message');

        // Pre empty message contents
        promotionMessageTitle.html('');
        promotionMessageText.html('');

        // if code size > 4
        if(promotionCode.length >= 4)
        {
            // Loading
            $('#loading_promotion_code').removeClass("d-none");

            // Check the code
            $.ajax({
                url: "/json/order/checkpromotioncode",
                type: "GET",
                dataType: "JSON",
                data: {
                    promotionCode: promotionCode,
                    customerId: customerId,
                    serviceId: serviceId
                    //kc: md5(todayString + '_' + entityId),
                },
                success: function(result)
                {
                    promotionMessageTitle.html(result.title);
                    promotionMessageText.html(result.message);

                    // Css status - Default is error (0), 1 is OK, 2 is Warning
                    if(result.status === 1){
                        promotionMessageContainer.removeClass('invalid-feedback').addClass('valid-feedback');
                        promotionMessageTitle.removeClass('form-error-icon').removeClass('badge-danger').addClass('form-success-icon').addClass('badge-success');
                        promotionMessageText.removeClass('form-error-message').addClass('form-success-message');
                    }
                    else if(result.status === 2){
                        promotionMessageTitle.removeClass('form-error-icon').removeClass('badge-danger').addClass('form-warning-icon').addClass('badge-warning');
                        promotionMessageText.removeClass('form-error-message').addClass('form-warning-message').addClass('text-warning');
                    }

                    // Remove all options
                    $('.order_promotion option[value][value!=""]').remove();

                    // Add option
                    $('.order_promotion').append('<option' + (result.id > 0 ? ' value="' + result.id + '" category="' + result.category + '" promotionvalue="' + result.pvalue + '"' : '') + ' selected="selected">' + result.label + '</option>');

                    // Recalculate Installments amounts
                    paymentInstallmentsCalculateAmounts(0, 0);

                    // Loading
                    $('#loading_promotion_code').addClass("d-none");
                },
                error: function (err)
                {
                    promotionMessageTitle.html('erreur');
                    promotionMessageText.html("Erreur lors du chargement !");

                    // Hide Loading
                    $('#loading_promotion_code').addClass("d-none");
                }
            });
        }
    });

    // order finalization sump up message
    $('.order_calculate_amount').change(function ()
    {
        var priceId             = $('.order_price option:selected').val();
        var promotionId         = $('.order_promotion option:selected').val();

        // Prepayment
        var prepayment          = $('.order_prepayment').val();

        // Credit
        var paymentUseCredit    = $('.order_payment_use_credit option:selected').val();
        var credit              = $('.order_credit').val();

        // Start date parts
        var startDtDate         = $('.order_start_date').val();
        var startDtHour         = $('.order_start_hour option:selected').val();
        var startDtMinute       = $('.order_start_minute option:selected').val();

        // Stop date parts
        var stopDtDate          = $('.order_stop_date').val();
        var stopDtHour          = $('.order_stop_hour option:selected').val();
        var stopDtMinute        = $('.order_stop_minute option:selected').val();

        var dateStart = null;
        if(startDtDate.length === 10 && startDtHour >= 0 && startDtHour < 24 && startDtMinute >= 0 && startDtMinute < 60)
        {
            dateStart = startDtDate.substr(6, 4) + '-' + startDtDate.substr(3, 2) + '-' + startDtDate.substr(0, 2);
            dateStart += 'T' + (startDtHour < 10 ? '0' : '') + startDtHour + ':' + (startDtMinute < 10 ? '0' : '') + startDtMinute + ':00';
        }

        var dateStop = null;
        if(stopDtDate.length === 10 && stopDtHour >= 0 && stopDtHour < 24 && stopDtMinute >= 0 && stopDtMinute < 60)
        {
            dateStop = stopDtDate.substr(6, 4) + '-' + stopDtDate.substr(3, 2) + '-' + stopDtDate.substr(0, 2);
            dateStop += 'T' + (stopDtHour < 10 ? '0' : '') + stopDtHour + ':' + (stopDtMinute < 10 ? '0' : '') + stopDtMinute + ':00';
        }

        // if data are ok
        if(priceId > 0 && dateStart.length === 19 && dateStop.length === 19)
        {
            // Loading
            $('#loading_amount').removeClass("d-none");

            // Calculate amounts
            $.ajax({
                url: "/json/order/calculateAmount",
                type: "GET",
                dataType: "JSON",
                data: {
                    dateStart: dateStart,
                    dateStop: dateStop,
                    priceId: priceId,
                    promotionId: promotionId,
                    prepayment: prepayment,
                    paymentUseCredit: paymentUseCredit,
                    credit: credit
                    //kc: md5(todayString + '_' + entityId),
                },
                success: function(result)
                {
                    $('#order_amount_result').html(result);

                    // Loading
                    $('#loading_amount').addClass("d-none");
                },
                error: function (err)
                {
                    $('#order_amount_result').html("Erreur lors du calcul !");

                    // Hide Loading
                    $('#loading_amount').addClass("d-none");
                }
            });
        }
    });

    // Enable/disable an entity object
    $('.jquery-active-entity').click(function ()
    {
        var classArray = ['btn-secondary', 'btn-success'];
        var subclassArray = ['fa-toggle-off', 'fa-toggle-on'];
        var activeAction = -1;
        var activePrevious = -1;

        // Action
        if($(this).hasClass(classArray[0])){
            activePrevious = 0; activeAction = 1;
        }
        else if($(this).hasClass(classArray[1])){
            activePrevious = 1; activeAction = 0;
        }

        var buttonId = $(this).attr('id');
        var valuesArray = buttonId.split('_');

        // Id is well formed and active value is correct
        if(valuesArray.length === 2 && (activeAction === 0 || activeAction === 1))
        {
            // Loading
            $('#loading_' + buttonId).removeClass("d-none");

            var entityUrl = valuesArray[0].replace("-", "/");
            var entityId = valuesArray[1];
            var today = new Date();
            var todayString = today.getFullYear() +
                (today.getMonth() < 9 ? '0' : '' ) + (today.getMonth() + 1) +
                (today.getDate() < 10 ? '0' : '' ) + today.getDate();

            // Request the modification to the appropriate entity
            $.ajax({
                url: "/json/" + entityUrl + "/active",
                type: "GET",
                dataType: "JSON",
                data: {
                    entityId: entityId,
                    active: activeAction,
                    kc: md5(todayString + '_' + entityId)
                },
                success: function (result)
                {
                    if(result.status == 'success')
                    {
                        var button = $("#" + buttonId);

                        button.addClass(classArray[activeAction]).removeClass(classArray[activePrevious]);
                        button.children('i').addClass(subclassArray[activeAction]).removeClass(subclassArray[activePrevious]);
                    }
                    else{
                        alert(result.message);
                    }

                    // Loading
                    $('#loading_' + buttonId).addClass("d-none");
                },
                error: function (err)
                {
                    alert("Erreur lors du chargement !");
                    $('#loading_' + buttonId).addClass("d-none");
                }
            });
        }
    });

    // Update medium company fields value
    $('#medium_company').change(function ()
    {
        // Company ID
        var companyId = $(this).val();

        // Loader
        $('.jquery-company-field-container').addClass("input-group").addClass("input-group-sm");
        $('.jquery-company-field-container div').removeClass("d-none").addClass("input-group-prepend");

        // Request the cities of the selected postal code.
        $.ajax({
            url: "/json/medium/company_from_id",
            type: "GET",
            dataType: "JSON",
            data: {
                companyId: companyId
            },
            success: function (company)
            {
                $('#medium_company_name').val(company.name);
                $('#medium_company_category').val(company.category);
                $('#medium_company_ape').val(company.ape);
                $('#medium_company_siren_number').val(company.sirenNumber);
                $('#medium_company_siret_number').val(company.siretNumber);
                $('#medium_company_siret_check_date').val(company.siretCheckDate);
                $('#medium_company_vat_number').val(company.vatNumber);
                $('#medium_company_account_code').val(company.AccountCode);

                // Loader
                $('.jquery-company-field-container').removeClass("input-group").removeClass("input-group-sm");
                $('.jquery-company-field-container div').removeClass("input-group-prepend").addClass("d-none");
            },
            error: function (err) {
                alert("Erreur lors du chargement !");

                // Loader
                $('.jquery-company-field-container').removeClass("input-group").removeClass("input-group-sm");
                $('.jquery-company-field-container div').removeClass("input-group-prepend").addClass("d-none");
            }
        });
    });
});
