/*
* Pick-a-Color JS v1.2.3
* Copyright 2013 Lauren Sperber and Broadstreet Ads
*/
;(function ($) {
    "use strict";

	var trimLeft = /^\s+/,
		trimRight = /\s+$/,
		tinyCounter = 0,
		mathRound = Math.round,
		mathMin = Math.min,
		mathMax = Math.max,
		mathRandom = Math.random;

	function tinycolor (color, opts) {

		color = (color) ? color : '';
		opts = opts || { };

		if (color instanceof tinycolor) {
		   return color;
		}
		
		if (!(this instanceof tinycolor)) {
			return new tinycolor(color, opts);
		}

		var rgb = inputToRGB(color);
		this._originalInput = color,
		this._r = rgb.r,
		this._g = rgb.g,
		this._b = rgb.b,
		this._a = rgb.a,
		this._roundA = mathRound(100*this._a) / 100,
		this._format = opts.format || rgb.format;
		this._gradientType = opts.gradientType;

		if (this._r < 1) { this._r = mathRound(this._r); }
		if (this._g < 1) { this._g = mathRound(this._g); }
		if (this._b < 1) { this._b = mathRound(this._b); }

		this._ok = rgb.ok;
		this._tc_id = tinyCounter++;
	}

	tinycolor.prototype = {
		isDark: function() {
			return this.getBrightness() < 128;
		},
		isLight: function() {
			return !this.isDark();
		},
		isValid: function() {
			return this._ok;
		},
		getOriginalInput: function() {
		  return this._originalInput;
		},
		getFormat: function() {
			return this._format;
		},
		getAlpha: function() {
			return this._a;
		},
		getBrightness: function() {
			var rgb = this.toRgb();
			return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
		},
		getLuminance: function() {
			var rgb = this.toRgb();
			var RsRGB, GsRGB, BsRGB, R, G, B;
			RsRGB = rgb.r/255;
			GsRGB = rgb.g/255;
			BsRGB = rgb.b/255;

			if (RsRGB <= 0.03928) {R = RsRGB / 12.92;} else {R = Math.pow(((RsRGB + 0.055) / 1.055), 2.4);}
			if (GsRGB <= 0.03928) {G = GsRGB / 12.92;} else {G = Math.pow(((GsRGB + 0.055) / 1.055), 2.4);}
			if (BsRGB <= 0.03928) {B = BsRGB / 12.92;} else {B = Math.pow(((BsRGB + 0.055) / 1.055), 2.4);}
			return (0.2126 * R) + (0.7152 * G) + (0.0722 * B);
		},
		setAlpha: function(value) {
			this._a = boundAlpha(value);
			this._roundA = mathRound(100*this._a) / 100;
			return this;
		},
		toHsv: function() {
			var hsv = rgbToHsv(this._r, this._g, this._b);
			return { h: hsv.h * 360, s: hsv.s, v: hsv.v, a: this._a };
		},
		toHsvString: function() {
			var hsv = rgbToHsv(this._r, this._g, this._b);
			var h = mathRound(hsv.h * 360), s = mathRound(hsv.s * 100), v = mathRound(hsv.v * 100);
			return (this._a == 1) ?
			  "hsv("  + h + ", " + s + "%, " + v + "%)" :
			  "hsva(" + h + ", " + s + "%, " + v + "%, "+ this._roundA + ")";
		},
		toHsl: function() {
			var hsl = rgbToHsl(this._r, this._g, this._b);
			return { h: hsl.h * 360, s: hsl.s, l: hsl.l, a: this._a };
		},
		toHslString: function() {
			var hsl = rgbToHsl(this._r, this._g, this._b);
			var h = mathRound(hsl.h * 360), s = mathRound(hsl.s * 100), l = mathRound(hsl.l * 100);
			return (this._a == 1) ?
			  "hsl("  + h + ", " + s + "%, " + l + "%)" :
			  "hsla(" + h + ", " + s + "%, " + l + "%, "+ this._roundA + ")";
		},
		toHex: function(allow3Char) {
			return rgbToHex(this._r, this._g, this._b, allow3Char);
		},
		toHexString: function(allow3Char) {
			return '#' + this.toHex(allow3Char);
		},
		toHex8: function(allow4Char) {
			return rgbaToHex(this._r, this._g, this._b, this._a, allow4Char);
		},
		toHex8String: function(allow4Char) {
			return '#' + this.toHex8(allow4Char);
		},
		toRgb: function() {
			return { r: mathRound(this._r), g: mathRound(this._g), b: mathRound(this._b), a: this._a };
		},
		toRgbString: function() {
			return (this._a == 1) ?
			  "rgb("  + mathRound(this._r) + ", " + mathRound(this._g) + ", " + mathRound(this._b) + ")" :
			  "rgba(" + mathRound(this._r) + ", " + mathRound(this._g) + ", " + mathRound(this._b) + ", " + this._roundA + ")";
		},
		toPercentageRgb: function() {
			return { r: mathRound(bound01(this._r, 255) * 100) + "%", g: mathRound(bound01(this._g, 255) * 100) + "%", b: mathRound(bound01(this._b, 255) * 100) + "%", a: this._a };
		},
		toPercentageRgbString: function() {
			return (this._a == 1) ?
			  "rgb("  + mathRound(bound01(this._r, 255) * 100) + "%, " + mathRound(bound01(this._g, 255) * 100) + "%, " + mathRound(bound01(this._b, 255) * 100) + "%)" :
			  "rgba(" + mathRound(bound01(this._r, 255) * 100) + "%, " + mathRound(bound01(this._g, 255) * 100) + "%, " + mathRound(bound01(this._b, 255) * 100) + "%, " + this._roundA + ")";
		},
		toName: function() {
			if (this._a === 0) {
				return "transparent";
			}

			if (this._a < 1) {
				return false;
			}

			return hexNames[rgbToHex(this._r, this._g, this._b, true)] || false;
		},
		toFilter: function(secondColor) {
			var hex8String = '#' + rgbaToArgbHex(this._r, this._g, this._b, this._a);
			var secondHex8String = hex8String;
			var gradientType = this._gradientType ? "GradientType = 1, " : "";

			if (secondColor) {
				var s = tinycolor(secondColor);
				secondHex8String = '#' + rgbaToArgbHex(s._r, s._g, s._b, s._a);
			}

			return "progid:DXImageTransform.Microsoft.gradient("+gradientType+"startColorstr="+hex8String+",endColorstr="+secondHex8String+")";
		},
		toString: function(format) {
			var formatSet = !!format;
			format = format || this._format;

			var formattedString = false;
			var hasAlpha = this._a < 1 && this._a >= 0;
			var needsAlphaFormat = !formatSet && hasAlpha && (format === "hex" || format === "hex6" || format === "hex3" || format === "hex4" || format === "hex8" || format === "name");

			if (needsAlphaFormat) {
				if (format === "name" && this._a === 0) {
					return this.toName();
				}
				return this.toRgbString();
			}
			if (format === "rgb") {
				formattedString = this.toRgbString();
			}
			if (format === "prgb") {
				formattedString = this.toPercentageRgbString();
			}
			if (format === "hex" || format === "hex6") {
				formattedString = this.toHexString();
			}
			if (format === "hex3") {
				formattedString = this.toHexString(true);
			}
			if (format === "hex4") {
				formattedString = this.toHex8String(true);
			}
			if (format === "hex8") {
				formattedString = this.toHex8String();
			}
			if (format === "name") {
				formattedString = this.toName();
			}
			if (format === "hsl") {
				formattedString = this.toHslString();
			}
			if (format === "hsv") {
				formattedString = this.toHsvString();
			}

			return formattedString || this.toHexString();
		},
		clone: function() {
			return tinycolor(this.toString());
		},

		_applyModification: function(fn, args) {
			var color = fn.apply(null, [this].concat([].slice.call(args)));
			this._r = color._r;
			this._g = color._g;
			this._b = color._b;
			this.setAlpha(color._a);
			return this;
		},
		lighten: function() {
			return this._applyModification(lighten, arguments);
		},
		brighten: function() {
			return this._applyModification(brighten, arguments);
		},
		darken: function() {
			return this._applyModification(darken, arguments);
		},
		desaturate: function() {
			return this._applyModification(desaturate, arguments);
		},
		saturate: function() {
			return this._applyModification(saturate, arguments);
		},
		greyscale: function() {
			return this._applyModification(greyscale, arguments);
		},
		spin: function() {
			return this._applyModification(spin, arguments);
		},

		_applyCombination: function(fn, args) {
			return fn.apply(null, [this].concat([].slice.call(args)));
		},
		analogous: function() {
			return this._applyCombination(analogous, arguments);
		},
		complement: function() {
			return this._applyCombination(complement, arguments);
		},
		monochromatic: function() {
			return this._applyCombination(monochromatic, arguments);
		},
		splitcomplement: function() {
			return this._applyCombination(splitcomplement, arguments);
		},
		triad: function() {
			return this._applyCombination(triad, arguments);
		},
		tetrad: function() {
			return this._applyCombination(tetrad, arguments);
		}
	};

	tinycolor.fromRatio = function(color, opts) {
		if (typeof color == "object") {
			var newColor = {};
			for (var i in color) {
				if (color.hasOwnProperty(i)) {
					if (i === "a") {
						newColor[i] = color[i];
					}
					else {
						newColor[i] = convertToPercentage(color[i]);
					}
				}
			}
			color = newColor;
		}

		return tinycolor(color, opts);
	};

	function inputToRGB(color) {

		var rgb = { r: 0, g: 0, b: 0 };
		var a = 1;
		var s = null;
		var v = null;
		var l = null;
		var ok = false;
		var format = false;

		if (typeof color == "string") {
			color = stringInputToObject(color);
		}

		if (typeof color == "object") {
			if (isValidCSSUnit(color.r) && isValidCSSUnit(color.g) && isValidCSSUnit(color.b)) {
				rgb = rgbToRgb(color.r, color.g, color.b);
				ok = true;
				format = String(color.r).substr(-1) === "%" ? "prgb" : "rgb";
			}
			else if (isValidCSSUnit(color.h) && isValidCSSUnit(color.s) && isValidCSSUnit(color.v)) {
				s = convertToPercentage(color.s);
				v = convertToPercentage(color.v);
				rgb = hsvToRgb(color.h, s, v);
				ok = true;
				format = "hsv";
			}
			else if (isValidCSSUnit(color.h) && isValidCSSUnit(color.s) && isValidCSSUnit(color.l)) {
				s = convertToPercentage(color.s);
				l = convertToPercentage(color.l);
				rgb = hslToRgb(color.h, s, l);
				ok = true;
				format = "hsl";
			}

			if (color.hasOwnProperty("a")) {
				a = color.a;
			}
		}

		a = boundAlpha(a);

		return {
			ok: ok,
			format: color.format || format,
			r: mathMin(255, mathMax(rgb.r, 0)),
			g: mathMin(255, mathMax(rgb.g, 0)),
			b: mathMin(255, mathMax(rgb.b, 0)),
			a: a
		};
	}

	function rgbToRgb(r, g, b){
		return {
			r: bound01(r, 255) * 255,
			g: bound01(g, 255) * 255,
			b: bound01(b, 255) * 255
		};
	}

	function rgbToHsl(r, g, b) {

		r = bound01(r, 255);
		g = bound01(g, 255);
		b = bound01(b, 255);

		var max = mathMax(r, g, b), min = mathMin(r, g, b);
		var h, s, l = (max + min) / 2;

		if(max == min) {
			h = s = 0;
		}
		else {
			var d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
			switch(max) {
				case r: h = (g - b) / d + (g < b ? 6 : 0); break;
				case g: h = (b - r) / d + 2; break;
				case b: h = (r - g) / d + 4; break;
			}

			h /= 6;
		}

		return { h: h, s: s, l: l };
	}

	function hslToRgb(h, s, l) {
		var r, g, b;

		h = bound01(h, 360);
		s = bound01(s, 100);
		l = bound01(l, 100);

		function hue2rgb(p, q, t) {
			if(t < 0) t += 1;
			if(t > 1) t -= 1;
			if(t < 1/6) return p + (q - p) * 6 * t;
			if(t < 1/2) return q;
			if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
			return p;
		}

		if(s === 0) {
			r = g = b = l;
		}
		else {
			var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
			var p = 2 * l - q;
			r = hue2rgb(p, q, h + 1/3);
			g = hue2rgb(p, q, h);
			b = hue2rgb(p, q, h - 1/3);
		}

		return { r: r * 255, g: g * 255, b: b * 255 };
	}

	function rgbToHsv(r, g, b) {

		r = bound01(r, 255);
		g = bound01(g, 255);
		b = bound01(b, 255);

		var max = mathMax(r, g, b), min = mathMin(r, g, b);
		var h, s, v = max;

		var d = max - min;
		s = max === 0 ? 0 : d / max;

		if(max == min) {
			h = 0;
		}
		else {
			switch(max) {
				case r: h = (g - b) / d + (g < b ? 6 : 0); break;
				case g: h = (b - r) / d + 2; break;
				case b: h = (r - g) / d + 4; break;
			}
			h /= 6;
		}
		return { h: h, s: s, v: v };
	}

	 function hsvToRgb(h, s, v) {

		h = bound01(h, 360) * 6;
		s = bound01(s, 100);
		v = bound01(v, 100);

		var i = Math.floor(h),
			f = h - i,
			p = v * (1 - s),
			q = v * (1 - f * s),
			t = v * (1 - (1 - f) * s),
			mod = i % 6,
			r = [v, q, p, p, t, v][mod],
			g = [t, v, v, q, p, p][mod],
			b = [p, p, t, v, v, q][mod];

		return { r: r * 255, g: g * 255, b: b * 255 };
	}

	function rgbToHex(r, g, b, allow3Char) {
		var hex = [
			pad2(mathRound(r).toString(16)),
			pad2(mathRound(g).toString(16)),
			pad2(mathRound(b).toString(16))
		];

		if (allow3Char && hex[0].charAt(0) == hex[0].charAt(1) && hex[1].charAt(0) == hex[1].charAt(1) && hex[2].charAt(0) == hex[2].charAt(1)) {
			return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0);
		}

		return hex.join("");
	}

	function rgbaToHex(r, g, b, a, allow4Char) {
		var hex = [
			pad2(mathRound(r).toString(16)),
			pad2(mathRound(g).toString(16)),
			pad2(mathRound(b).toString(16)),
			pad2(convertDecimalToHex(a))
		];

		if (allow4Char && hex[0].charAt(0) == hex[0].charAt(1) && hex[1].charAt(0) == hex[1].charAt(1) && hex[2].charAt(0) == hex[2].charAt(1) && hex[3].charAt(0) == hex[3].charAt(1)) {
			return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0) + hex[3].charAt(0);
		}

		return hex.join("");
	}

	function rgbaToArgbHex(r, g, b, a) {
		var hex = [
			pad2(convertDecimalToHex(a)),
			pad2(mathRound(r).toString(16)),
			pad2(mathRound(g).toString(16)),
			pad2(mathRound(b).toString(16))
		];

		return hex.join("");
	}

	tinycolor.equals = function (color1, color2) {
		if (!color1 || !color2) { return false; }
		return tinycolor(color1).toRgbString() == tinycolor(color2).toRgbString();
	};

	tinycolor.random = function() {
		return tinycolor.fromRatio({
			r: mathRandom(),
			g: mathRandom(),
			b: mathRandom()
		});
	};

	function desaturate(color, amount) {
		amount = (amount === 0) ? 0 : (amount || 10);
		var hsl = tinycolor(color).toHsl();
		hsl.s -= amount / 100;
		hsl.s = clamp01(hsl.s);
		return tinycolor(hsl);
	}

	function saturate(color, amount) {
		amount = (amount === 0) ? 0 : (amount || 10);
		var hsl = tinycolor(color).toHsl();
		hsl.s += amount / 100;
		hsl.s = clamp01(hsl.s);
		return tinycolor(hsl);
	}

	function greyscale(color) {
		return tinycolor(color).desaturate(100);
	}

	function lighten (color, amount) {
		amount = (amount === 0) ? 0 : (amount || 10);
		var hsl = tinycolor(color).toHsl();
		hsl.l += amount / 100;
		hsl.l = clamp01(hsl.l);
		return tinycolor(hsl);
	}

	function brighten(color, amount) {
		amount = (amount === 0) ? 0 : (amount || 10);
		var rgb = tinycolor(color).toRgb();
		rgb.r = mathMax(0, mathMin(255, rgb.r - mathRound(255 * - (amount / 100))));
		rgb.g = mathMax(0, mathMin(255, rgb.g - mathRound(255 * - (amount / 100))));
		rgb.b = mathMax(0, mathMin(255, rgb.b - mathRound(255 * - (amount / 100))));
		return tinycolor(rgb);
	}

	function darken (color, amount) {
		amount = (amount === 0) ? 0 : (amount || 10);
		var hsl = tinycolor(color).toHsl();
		hsl.l -= amount / 100;
		hsl.l = clamp01(hsl.l);
		return tinycolor(hsl);
	}

	function spin(color, amount) {
		var hsl = tinycolor(color).toHsl();
		var hue = (hsl.h + amount) % 360;
		hsl.h = hue < 0 ? 360 + hue : hue;
		return tinycolor(hsl);
	}

	function complement(color) {
		var hsl = tinycolor(color).toHsl();
		hsl.h = (hsl.h + 180) % 360;
		return tinycolor(hsl);
	}

	function triad(color) {
		var hsl = tinycolor(color).toHsl();
		var h = hsl.h;
		return [
			tinycolor(color),
			tinycolor({ h: (h + 120) % 360, s: hsl.s, l: hsl.l }),
			tinycolor({ h: (h + 240) % 360, s: hsl.s, l: hsl.l })
		];
	}

	function tetrad(color) {
		var hsl = tinycolor(color).toHsl();
		var h = hsl.h;
		return [
			tinycolor(color),
			tinycolor({ h: (h + 90) % 360, s: hsl.s, l: hsl.l }),
			tinycolor({ h: (h + 180) % 360, s: hsl.s, l: hsl.l }),
			tinycolor({ h: (h + 270) % 360, s: hsl.s, l: hsl.l })
		];
	}

	function splitcomplement(color) {
		var hsl = tinycolor(color).toHsl();
		var h = hsl.h;
		return [
			tinycolor(color),
			tinycolor({ h: (h + 72) % 360, s: hsl.s, l: hsl.l}),
			tinycolor({ h: (h + 216) % 360, s: hsl.s, l: hsl.l})
		];
	}

	function analogous(color, results, slices) {
		results = results || 6;
		slices = slices || 30;

		var hsl = tinycolor(color).toHsl();
		var part = 360 / slices;
		var ret = [tinycolor(color)];

		for (hsl.h = ((hsl.h - (part * results >> 1)) + 720) % 360; --results; ) {
			hsl.h = (hsl.h + part) % 360;
			ret.push(tinycolor(hsl));
		}
		return ret;
	}

	function monochromatic(color, results) {
		results = results || 6;
		var hsv = tinycolor(color).toHsv();
		var h = hsv.h, s = hsv.s, v = hsv.v;
		var ret = [];
		var modification = 1 / results;

		while (results--) {
			ret.push(tinycolor({ h: h, s: s, v: v}));
			v = (v + modification) % 1;
		}

		return ret;
	}

	tinycolor.mix = function(color1, color2, amount) {
		amount = (amount === 0) ? 0 : (amount || 50);

		var rgb1 = tinycolor(color1).toRgb();
		var rgb2 = tinycolor(color2).toRgb();

		var p = amount / 100;

		var rgba = {
			r: ((rgb2.r - rgb1.r) * p) + rgb1.r,
			g: ((rgb2.g - rgb1.g) * p) + rgb1.g,
			b: ((rgb2.b - rgb1.b) * p) + rgb1.b,
			a: ((rgb2.a - rgb1.a) * p) + rgb1.a
		};

		return tinycolor(rgba);
	};

	tinycolor.readability = function(color1, color2) {
		var c1 = tinycolor(color1);
		var c2 = tinycolor(color2);
		return (Math.max(c1.getLuminance(),c2.getLuminance())+0.05) / (Math.min(c1.getLuminance(),c2.getLuminance())+0.05);
	};

	tinycolor.isReadable = function(color1, color2, wcag2) {
		var readability = tinycolor.readability(color1, color2);
		var wcag2Parms, out;

		out = false;

		wcag2Parms = validateWCAG2Parms(wcag2);
		switch (wcag2Parms.level + wcag2Parms.size) {
			case "AAsmall":
			case "AAAlarge":
				out = readability >= 4.5;
				break;
			case "AAlarge":
				out = readability >= 3;
				break;
			case "AAAsmall":
				out = readability >= 7;
				break;
		}
		return out;

	};

	tinycolor.mostReadable = function(baseColor, colorList, args) {
		var bestColor = null;
		var bestScore = 0;
		var readability;
		var includeFallbackColors, level, size ;
		args = args || {};
		includeFallbackColors = args.includeFallbackColors ;
		level = args.level;
		size = args.size;

		for (var i= 0; i < colorList.length ; i++) {
			readability = tinycolor.readability(baseColor, colorList[i]);
			if (readability > bestScore) {
				bestScore = readability;
				bestColor = tinycolor(colorList[i]);
			}
		}

		if (tinycolor.isReadable(baseColor, bestColor, {"level":level,"size":size}) || !includeFallbackColors) {
			return bestColor;
		}
		else {
			args.includeFallbackColors=false;
			return tinycolor.mostReadable(baseColor,["#fff", "#000"],args);
		}
	};

	var names = tinycolor.names = {
		aliceblue: "f0f8ff",
		antiquewhite: "faebd7",
		aqua: "0ff",
		aquamarine: "7fffd4",
		azure: "f0ffff",
		beige: "f5f5dc",
		bisque: "ffe4c4",
		black: "000",
		blanchedalmond: "ffebcd",
		blue: "00f",
		blueviolet: "8a2be2",
		brown: "a52a2a",
		burlywood: "deb887",
		burntsienna: "ea7e5d",
		cadetblue: "5f9ea0",
		chartreuse: "7fff00",
		chocolate: "d2691e",
		coral: "ff7f50",
		cornflowerblue: "6495ed",
		cornsilk: "fff8dc",
		crimson: "dc143c",
		cyan: "0ff",
		darkblue: "00008b",
		darkcyan: "008b8b",
		darkgoldenrod: "b8860b",
		darkgray: "a9a9a9",
		darkgreen: "006400",
		darkgrey: "a9a9a9",
		darkkhaki: "bdb76b",
		darkmagenta: "8b008b",
		darkolivegreen: "556b2f",
		darkorange: "ff8c00",
		darkorchid: "9932cc",
		darkred: "8b0000",
		darksalmon: "e9967a",
		darkseagreen: "8fbc8f",
		darkslateblue: "483d8b",
		darkslategray: "2f4f4f",
		darkslategrey: "2f4f4f",
		darkturquoise: "00ced1",
		darkviolet: "9400d3",
		deeppink: "ff1493",
		deepskyblue: "00bfff",
		dimgray: "696969",
		dimgrey: "696969",
		dodgerblue: "1e90ff",
		firebrick: "b22222",
		floralwhite: "fffaf0",
		forestgreen: "228b22",
		fuchsia: "f0f",
		gainsboro: "dcdcdc",
		ghostwhite: "f8f8ff",
		gold: "ffd700",
		goldenrod: "daa520",
		gray: "808080",
		green: "008000",
		greenyellow: "adff2f",
		grey: "808080",
		honeydew: "f0fff0",
		hotpink: "ff69b4",
		indianred: "cd5c5c",
		indigo: "4b0082",
		ivory: "fffff0",
		khaki: "f0e68c",
		lavender: "e6e6fa",
		lavenderblush: "fff0f5",
		lawngreen: "7cfc00",
		lemonchiffon: "fffacd",
		lightblue: "add8e6",
		lightcoral: "f08080",
		lightcyan: "e0ffff",
		lightgoldenrodyellow: "fafad2",
		lightgray: "d3d3d3",
		lightgreen: "90ee90",
		lightgrey: "d3d3d3",
		lightpink: "ffb6c1",
		lightsalmon: "ffa07a",
		lightseagreen: "20b2aa",
		lightskyblue: "87cefa",
		lightslategray: "789",
		lightslategrey: "789",
		lightsteelblue: "b0c4de",
		lightyellow: "ffffe0",
		lime: "0f0",
		limegreen: "32cd32",
		linen: "faf0e6",
		magenta: "f0f",
		maroon: "800000",
		mediumaquamarine: "66cdaa",
		mediumblue: "0000cd",
		mediumorchid: "ba55d3",
		mediumpurple: "9370db",
		mediumseagreen: "3cb371",
		mediumslateblue: "7b68ee",
		mediumspringgreen: "00fa9a",
		mediumturquoise: "48d1cc",
		mediumvioletred: "c71585",
		midnightblue: "191970",
		mintcream: "f5fffa",
		mistyrose: "ffe4e1",
		moccasin: "ffe4b5",
		navajowhite: "ffdead",
		navy: "000080",
		oldlace: "fdf5e6",
		olive: "808000",
		olivedrab: "6b8e23",
		orange: "ffa500",
		orangered: "ff4500",
		orchid: "da70d6",
		palegoldenrod: "eee8aa",
		palegreen: "98fb98",
		paleturquoise: "afeeee",
		palevioletred: "db7093",
		papayawhip: "ffefd5",
		peachpuff: "ffdab9",
		peru: "cd853f",
		pink: "ffc0cb",
		plum: "dda0dd",
		powderblue: "b0e0e6",
		purple: "800080",
		rebeccapurple: "663399",
		red: "f00",
		rosybrown: "bc8f8f",
		royalblue: "4169e1",
		saddlebrown: "8b4513",
		salmon: "fa8072",
		sandybrown: "f4a460",
		seagreen: "2e8b57",
		seashell: "fff5ee",
		sienna: "a0522d",
		silver: "c0c0c0",
		skyblue: "87ceeb",
		slateblue: "6a5acd",
		slategray: "708090",
		slategrey: "708090",
		snow: "fffafa",
		springgreen: "00ff7f",
		steelblue: "4682b4",
		tan: "d2b48c",
		teal: "008080",
		thistle: "d8bfd8",
		tomato: "ff6347",
		turquoise: "40e0d0",
		violet: "ee82ee",
		wheat: "f5deb3",
		white: "fff",
		whitesmoke: "f5f5f5",
		yellow: "ff0",
		yellowgreen: "9acd32"
	};

	var hexNames = tinycolor.hexNames = flip(names);

	function flip(o) {
		var flipped = { };
		for (var i in o) {
			if (o.hasOwnProperty(i)) {
				flipped[o[i]] = i;
			}
		}
		return flipped;
	}

	function boundAlpha(a) {
		a = parseFloat(a);

		if (isNaN(a) || a < 0 || a > 1) {
			a = 1;
		}

		return a;
	}

	function bound01(n, max) {
		if (isOnePointZero(n)) { n = "100%"; }

		var processPercent = isPercentage(n);
		n = mathMin(max, mathMax(0, parseFloat(n)));

		if (processPercent) {
			n = parseInt(n * max, 10) / 100;
		}

		if ((Math.abs(n - max) < 0.000001)) {
			return 1;
		}

		return (n % max) / parseFloat(max);
	}

	function clamp01(val) {
		return mathMin(1, mathMax(0, val));
	}

	function parseIntFromHex(val) {
		return parseInt(val, 16);
	}

	function isOnePointZero(n) {
		return typeof n == "string" && n.indexOf('.') != -1 && parseFloat(n) === 1;
	}

	function isPercentage(n) {
		return typeof n === "string" && n.indexOf('%') != -1;
	}

	function pad2(c) {
		return c.length == 1 ? '0' + c : '' + c;
	}

	function convertToPercentage(n) {
		if (n <= 1) {
			n = (n * 100) + "%";
		}

		return n;
	}

	function convertDecimalToHex(d) {
		return Math.round(parseFloat(d) * 255).toString(16);
	}

	function convertHexToDecimal(h) {
		return (parseIntFromHex(h) / 255);
	}

	var matchers = (function() {

		var CSS_INTEGER = "[-\\+]?\\d+%?";

		var CSS_NUMBER = "[-\\+]?\\d*\\.\\d+%?";

		var CSS_UNIT = "(?:" + CSS_NUMBER + ")|(?:" + CSS_INTEGER + ")";

		var PERMISSIVE_MATCH3 = "[\\s|\\(]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")\\s*\\)?";
		var PERMISSIVE_MATCH4 = "[\\s|\\(]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")\\s*\\)?";

		return {
			CSS_UNIT: new RegExp(CSS_UNIT),
			rgb: new RegExp("rgb" + PERMISSIVE_MATCH3),
			rgba: new RegExp("rgba" + PERMISSIVE_MATCH4),
			hsl: new RegExp("hsl" + PERMISSIVE_MATCH3),
			hsla: new RegExp("hsla" + PERMISSIVE_MATCH4),
			hsv: new RegExp("hsv" + PERMISSIVE_MATCH3),
			hsva: new RegExp("hsva" + PERMISSIVE_MATCH4),
			hex3: /^#?([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
			hex6: /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
			hex4: /^#?([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
			hex8: /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/
		};
	})();

	function isValidCSSUnit(color) {
		return !!matchers.CSS_UNIT.exec(color);
	}

	function stringInputToObject(color) {
		color = color.replace(trimLeft,'').replace(trimRight, '').toLowerCase();
		var named = false;
		if (names[color]) {
			color = names[color];
			named = true;
		}
		else if (color == 'transparent') {
			return { r: 0, g: 0, b: 0, a: 0, format: "name" };
		}

		var match;
		if ((match = matchers.rgb.exec(color))) {
			return { r: match[1], g: match[2], b: match[3] };
		}
		if ((match = matchers.rgba.exec(color))) {
			return { r: match[1], g: match[2], b: match[3], a: match[4] };
		}
		if ((match = matchers.hsl.exec(color))) {
			return { h: match[1], s: match[2], l: match[3] };
		}
		if ((match = matchers.hsla.exec(color))) {
			return { h: match[1], s: match[2], l: match[3], a: match[4] };
		}
		if ((match = matchers.hsv.exec(color))) {
			return { h: match[1], s: match[2], v: match[3] };
		}
		if ((match = matchers.hsva.exec(color))) {
			return { h: match[1], s: match[2], v: match[3], a: match[4] };
		}
		if ((match = matchers.hex8.exec(color))) {
			return {
				r: parseIntFromHex(match[1]),
				g: parseIntFromHex(match[2]),
				b: parseIntFromHex(match[3]),
				a: convertHexToDecimal(match[4]),
				format: named ? "name" : "hex8"
			};
		}
		if ((match = matchers.hex6.exec(color))) {
			return {
				r: parseIntFromHex(match[1]),
				g: parseIntFromHex(match[2]),
				b: parseIntFromHex(match[3]),
				format: named ? "name" : "hex"
			};
		}
		if ((match = matchers.hex4.exec(color))) {
			return {
				r: parseIntFromHex(match[1] + '' + match[1]),
				g: parseIntFromHex(match[2] + '' + match[2]),
				b: parseIntFromHex(match[3] + '' + match[3]),
				a: convertHexToDecimal(match[4] + '' + match[4]),
				format: named ? "name" : "hex8"
			};
		}
		if ((match = matchers.hex3.exec(color))) {
			return {
				r: parseIntFromHex(match[1] + '' + match[1]),
				g: parseIntFromHex(match[2] + '' + match[2]),
				b: parseIntFromHex(match[3] + '' + match[3]),
				format: named ? "name" : "hex"
			};
		}

		return false;
	}

	$.fn.pickAColor = function (options) {
      var supportsTouch = 'ontouchstart' in window,
          smallScreen = (parseInt($(window).width(),10) < 767) ? true : false,
          supportsLocalStorage = 'localStorage' in window && window.localStorage !== null &&
            typeof JSON === 'object', 
          isIELT10 = document.all && !window.atob, 

          startEvent    = supportsTouch ? "touchstart.pickAColor"  : "mousedown.pickAColor",
          moveEvent     = supportsTouch ? "touchmove.pickAColor"   : "mousemove.pickAColor",
          endEvent      = supportsTouch ? "touchend.pickAColor"    : "mouseup.pickAColor",
          clickEvent    = supportsTouch ? "touchend.pickAColor"    : "click.pickAColor",
          dragEvent     = "dragging.pickAColor",
          endDragEvent  = "endDrag.pickAColor";

      var settings = $.extend({
        showSpectrum          : true,
        showSavedColors       : true,
        saveColorsPerElement  : false,
        fadeMenuToggle        : true,
        showAdvanced          : true,
        showBasicColors       : true,
        showHexInput          : true,
        allowBlank            : false,
        inlineDropdown        : false,
        basicColors           : {
          white     : 'fff',
          red       : 'f00',
          orange    : 'f60',
          yellow    : 'ff0',
          green     : '008000',
          blue      : '00f',
          purple    : '800080',
          black     : '000'
        }
      }, options);
	  
      if (!settings.showAdvanced && !settings.showBasicColors) {
        settings.showBasicColors = true;
      }

      var useTabs = (settings.showSavedColors && settings.showAdvanced) ||
        (settings.showBasicColors && settings.showSavedColors) ||
        (settings.showBasicColors && settings.showAdvanced);

      var markupAfterInput = function () {
        var $markup = $("<div>").addClass("input-group-append"),
            $dropdownButton = $("<button type='button'>").addClass("btn btn-default color-dropdown dropdown-toggle input-group-text"),
            $dropdownColorPreview = $("<span>").addClass("color-preview current-color"),
            $dropdownCaret = $("<span>").addClass("caret"),
            $dropdownContainer = $("<div>").addClass("color-menu dropdown-menu dropdown-menu-right");
        if (settings.inlineDropdown) {
          $dropdownContainer.addClass("color-menu--inline");
        }
        if (!settings.showHexInput) {
          $dropdownButton.addClass("no-hex");
          $dropdownContainer.addClass("no-hex");
        }
        $markup.append($dropdownButton.append($dropdownColorPreview).append($dropdownCaret));
        if (!useTabs && !settings.showSpectrum) {
          $dropdownContainer.addClass("small");
        }
        if (useTabs) {
          var $tabContainer = $("<div>").addClass("color-menu-tabs"),
              savedColorsClass = settings.showBasicColors ? "savedColors-tab tab" : "savedColors-tab tab tab-active";
          if (settings.showBasicColors) {
            $tabContainer.append($("<span>").addClass("basicColors-tab tab tab-active").
              append($("<a>").text("Basic Colors"))); 
          }
          if (settings.showSavedColors) {
            $tabContainer.append($("<span>").addClass(savedColorsClass).append($("<a>").text("Saved Colors")));
          }
          if (settings.showAdvanced) {
            $tabContainer.append($("<span>").addClass("advanced-tab tab").
            append($("<a>").text("Advanced")));
          }
          $dropdownContainer.append($tabContainer);
        }

        if (settings.showBasicColors) {
          var $basicColors = $("<div>").addClass("basicColors-content active-content");
          if (settings.showSpectrum) {
            $basicColors.append($("<h6>").addClass("color-menu-instructions").
              text("Tap spectrum or drag band to change color"));
          }
          var $listContainer = $("<ul>").addClass("basic-colors-list");
          $.each(settings.basicColors, function (index,value) {
            var $thisColor = $("<li>").addClass("color-item"),
                $thisLink = $("<a>").addClass(index + " color-link"),
                $colorPreview =  $("<span>").addClass("color-preview " + index),
                $colorLabel = $("<span>").addClass("color-label").text(index);

              $thisLink.append($colorPreview, $colorLabel);
              $colorPreview.append();
              if (value[0] !== '#') {
                value = '#'+value;
              }
              $colorPreview.css('background-color', value);

            if (settings.showSpectrum) {
              var $thisSpectrum = $("<span>").addClass("color-box spectrum-" + index);
              if (isIELT10) {
                $.each([0,1], function (i) {
                  if (value !== "fff" && index !== "000")
                  $thisSpectrum.append($("<span>").addClass(index + "-spectrum-" + i +
                  " ie-spectrum"));
                });
              }
              var $thisHighlightBand = $("<span>").addClass("highlight-band");
              $.each([0,1,2], function () {
                $thisHighlightBand.append($("<span>").addClass("highlight-band-stripe"));
              });
              $thisLink.append($thisSpectrum.append($thisHighlightBand));
            }
            $listContainer.append($thisColor.append($thisLink));
          });
          $dropdownContainer.append($basicColors.append($listContainer));
        }

        if (settings.showSavedColors) {
          var savedColorsActiveClass = settings.showBasicColors ? 'inactive-content' : 'active-content',
            $savedColors = $("<div>").addClass("savedColors-content").addClass(savedColorsActiveClass);
            $savedColors.append($("<p>").addClass("saved-colors-instructions").
            text("Type in a color or use the spectrums to lighten or darken an existing color."));
          $dropdownContainer.append($savedColors);
        }

        if (settings.showAdvanced) {
          var advancedColorsActiveClass = settings.showBasicColors || settings.showSavedColors ? 'inactive-content' : 'active-content';
          var $advanced = $("<div>").addClass("advanced-content").addClass(advancedColorsActiveClass).
                append($("<h6>").addClass("advanced-instructions").text("Tap spectrum or drag band to change color")),
              $advancedList = $("<ul>").addClass("advanced-list"),
              $hueItem = $("<li>").addClass("hue-item"),
              $hueContent = $("<span>").addClass("hue-text").
                text("Hue: ").append($("<span>").addClass("hue-value").text("0"));
          var $hueSpectrum = $("<span>").addClass("color-box spectrum-hue");
          if (isIELT10) {
            $.each([0,1,2,3,4,5,6], function (i) {
              $hueSpectrum.append($("<span>").addClass("hue-spectrum-" + i +
              " ie-spectrum hue"));
            });
          }
          var $hueHighlightBand = $("<span>").addClass("highlight-band");
          $.each([0,1,2], function () {
            $hueHighlightBand.append($("<span>").addClass("highlight-band-stripe"));
          });
          $advancedList.append($hueItem.append($hueContent).append($hueSpectrum.append($hueHighlightBand)));
          var $lightnessItem = $("<li>").addClass("lightness-item"),
              $lightnessSpectrum = $("<span>").addClass("color-box spectrum-lightness"),
              $lightnessContent = $("<span>").addClass("lightness-text").
            text("Lightness: ").append($("<span>").addClass("lightness-value").text("50%"));
          if (isIELT10) {
            $.each([0,1], function (i) {
              $lightnessSpectrum.append($("<span>").addClass("lightness-spectrum-" + i +
              " ie-spectrum"));
            });
          }
          var $lightnessHighlightBand = $("<span>").addClass("highlight-band");
          $.each([0,1,2], function () {
            $lightnessHighlightBand.append($("<span>").addClass("highlight-band-stripe"));
          });
          $advancedList.append($lightnessItem.
            append($lightnessContent).append($lightnessSpectrum.append($lightnessHighlightBand)));
          var $saturationItem = $("<li>").addClass("saturation-item"),
              $saturationSpectrum = $("<span>").addClass("color-box spectrum-saturation");
          if (isIELT10) {
            $.each([0,1], function (i) {
              $saturationSpectrum.append($("<span>").addClass("saturation-spectrum-" + i +
              " ie-spectrum"));
            });
          }
          var $saturationHighlightBand = $("<span>").addClass("highlight-band");
          $.each([0,1,2], function () {
            $saturationHighlightBand.append($("<span>").addClass("highlight-band-stripe"));
          });
          var $saturationContent = $("<span>").addClass("saturation-text").
            text("Saturation: ").append($("<span>").addClass("saturation-value").text("100%"));
          $advancedList.append($saturationItem.append($saturationContent).append($saturationSpectrum.
            append($saturationHighlightBand)));
          var $previewItem = $("<li>").addClass("preview-item").append($("<span>").
              addClass("preview-text").text("Preview")),
            $preview = $("<span>").addClass("color-preview advanced").
              append("<button class='color-select btn btn-mini advanced' type='button'>Select</button>");
          $advancedList.append($previewItem.append($preview));
          $dropdownContainer.append($advanced.append($advancedList));
        }
        $markup.append($dropdownContainer);
        return $markup;
      };

      var myColorVars = {};

      var myStyleVars = {
          rowsInDropdown     : 8,
          maxColsInDropdown  : 2
      };
      
      if (settings.showSavedColors) { 
        var allSavedColors = []; 
        if (supportsLocalStorage && localStorage.allSavedColors) {
          allSavedColors = JSON.parse(localStorage.allSavedColors);

        } else if (document.cookie.match("pickAColorSavedColors-allSavedColors=")) {
          var theseCookies = document.cookie.split(";");

          $.each(theseCookies, function (index) {
            if (theseCookies[index].match("pickAColorSavedColors-allSavedColors=")) {
              allSavedColors = theseCookies[index].split("=")[1].split(",");
            }
          });
        }
      }

      var methods = {

        initialize: function (index) {
          var $thisEl = $(this),
              $thisParent,
              myId,
              defaultColor;
          
          if (!$thisEl.attr("name")) {
            $thisEl.attr("name","pick-a-color-" + index);
          }
          myId = $thisEl.attr("name");
          
          $thisEl.addClass("pick-a-color");
          
          if (settings.allowBlank) {
            if (!$thisEl.val().match(/^\s+$|^$/)) {
              myColorVars.defaultColor = tinycolor($thisEl.val()).toHex();
              myColorVars.typedColor = myColorVars.defaultColor;
              $thisEl.val(myColorVars.defaultColor);
            }
          } else {
            myColorVars.defaultColor = tinycolor($thisEl.val()).toHex();
            myColorVars.typedColor = myColorVars.defaultColor;
            $thisEl.val(myColorVars.defaultColor);
          }
          
          $($thisEl).wrap('<div class="input-group input-group-sm pick-a-color-markup" id="' + myId + '">');
          $thisParent = $($thisEl.parent());
          if (settings.showHexInput) {
            $thisParent.prepend('<div class="input-group-prepend"><div class="input-group-text">#</div></div>').append(markupAfterInput());
          } else {
            $thisParent.append(markupAfterInput());
          }
          
          if (!settings.showHexInput) {
            $thisEl.attr("type","hidden");
          }
        },

        updatePreview: function ($thisEl) {
          if (!settings.allowBlank) {
            myColorVars.typedColor = tinycolor($thisEl.val()).toHex();
            $thisEl.siblings(".input-group-append").find(".current-color").css("background-color",
              "#" + myColorVars.typedColor);
          } else {
            myColorVars.typedColor = $thisEl.val().match(/^\s+$|^$/) ? '' : tinycolor($thisEl.val()).toHex();
            if (myColorVars.typedColor === '') {
              $thisEl.siblings(".input-group-append").find(".current-color").css("background",
                "none");
            } else {
              $thisEl.siblings(".input-group-append").find(".current-color").css("background-color",
                "#" + myColorVars.typedColor);
            }
          }
        },

        pressPreviewButton: function () {
          var thisEvent = arguments[0].thisEvent;
          thisEvent.stopPropagation();
          methods.toggleDropdown(thisEvent.target);
        },

        openDropdown: function (button,menu) {
          $(".color-menu").each(function () {
            var $thisEl = $(this);
            if ($thisEl.css("display") === "block") {
              var thisColorPreviewButton = $thisEl.parents(".input-group-append");
              methods.closeDropdown(thisColorPreviewButton,$thisEl);
            }
          });

          if (settings.fadeMenuToggle && !supportsTouch) {
            $(menu).fadeIn("fast");
          } else {
            $(menu).show();
          }

          $(button).addClass("open");
        },

        closeDropdown: function (button,menu) {
          if (settings.fadeMenuToggle && !supportsTouch) {
            $(menu).fadeOut("fast");
          } else {
            $(menu).css("display","none");
          }

          $(button).removeClass("open");
        },

        closeDropdownIfOpen: function () {
          var button = arguments[0].button,
              menu = arguments[0].menu;
          if (menu.css("display") === "block") {
            methods.closeDropdown(button,menu);
          }
        },

        toggleDropdown: function (element) {
          var $container = $(element).parents(".pick-a-color-markup"),
              $input = $container.find("input"),
              $button = $container.find(".input-group-append"),
              $menu = $container.find(".color-menu");
          if (!$input.is(":disabled") && $menu.css("display") === "none") {
            methods.openDropdown($button,$menu);
          } else {
            methods.closeDropdown($button,$menu);
          }
        },

        tabbable: function () {
          var $this_el = $(this),
              $myContainer = $this_el.parents(".pick-a-color-markup");

          $this_el.click(function () {
            var $this_el = $(this),
                contentClass = $this_el.attr("class").split(" ")[0].split("-")[0] + "-content",
                myContent = $this_el.parents(".dropdown-menu").find("." + contentClass);

            if (!$this_el.hasClass("tab-active")) {
              $myContainer.find(".tab-active").removeClass("tab-active");
              $myContainer.find(".active-content").
                removeClass("active-content").addClass("inactive-content");
              $this_el.addClass("tab-active"); 
              $(myContent).addClass("active-content").removeClass("inactive-content");
            }
          });
        },

        getColorMultiplier: function (spectrumType,position,tab) {
          var spectrumWidth = (tab === "basic") ? parseInt($(".color-box").first().width(),10) :
            parseInt($(".advanced-list").find(".color-box").first().width(),10);
          if (spectrumWidth === 0) {
            if (tab === "basic") {
              spectrumWidth = supportsTouch ? 160 : 200;
            } else {
              spectrumWidth = supportsTouch ? 160 : 300;
            }
          }
          var halfSpectrumWidth = spectrumWidth / 2,
              percentOfBox = position / spectrumWidth;

          if (spectrumType === "bidirectional") {
            return (percentOfBox <= 0.5) ?
              (1 - (position / halfSpectrumWidth)) / 2 :
              -((position - halfSpectrumWidth) / halfSpectrumWidth) / 2;
          } else {
            return (spectrumType === "darkenRight") ? -(percentOfBox / 2) : (percentOfBox / 2);
          }

        },

        modifyHSLLightness: function (HSL,multiplier) {
          var hsl = HSL;
          hsl.l += multiplier;
          hsl.l = Math.min(Math.max(0,hsl.l),1);
          return tinycolor(hsl).toHslString();
        },

        getMoveableArea: function ($element) {
          var dimensions = {},
              $elParent = $element.parent(),
              myWidth = $element.outerWidth(),
              parentWidth = $elParent.width(), 
              parentLocation = $elParent.offset();
          dimensions.minX = parentLocation.left;
          dimensions.maxX = parentWidth - myWidth;
          return dimensions;
        },

        moveHighlightBand: function ($highlightBand, moveableArea, e) {
          var hbWidth = $(".highlight-band").first().outerWidth(),
              threeFourthsHBWidth = hbWidth * 0.75,
              mouseX = supportsTouch ? e.originalEvent.pageX : e.pageX,
              newPosition = mouseX - moveableArea.minX - threeFourthsHBWidth;

          newPosition = Math.max(0,(Math.min(newPosition,moveableArea.maxX)));
          $highlightBand.css("position", "absolute");
          $highlightBand.css("left", newPosition);
        },

        horizontallyDraggable: function () {
          $(this).on(startEvent, function (event) {
            event.preventDefault();
            var $this_el = $(event.delegateTarget);
            $this_el.css("cursor","-webkit-grabbing");
            $this_el.css("cursor","-moz-grabbing");
            var dimensions = methods.getMoveableArea($this_el);

            $(document).on(moveEvent, function (e) {
              $this_el.trigger(dragEvent);
              methods.moveHighlightBand($this_el, dimensions, e);
            }).on(endEvent, function(event) {
              $(document).off(moveEvent);
              $(document).off(dragEvent);
              $this_el.css("cursor","-webkit-grab");
              $this_el.css("cursor","-moz-grab");
              $this_el.trigger(endDragEvent);
              $(document).off(endEvent);
            });
          }).on(endEvent, function(event) {
            event.stopPropagation();
            $(document).off(moveEvent);
            $(document).off(dragEvent);
          });
        },

        modifyHighlightBand: function ($highlightBand,colorMultiplier,spectrumType) {
          var darkGrayHSL = { h: 0, s:0, l: 0.05 },
              bwMidHSL = { h: 0, s:0, l: 0.5 },
              hbColorMultiplier = -colorMultiplier,
              hbsColorMultiplier = hbColorMultiplier * 10,
              $hbStripes = $highlightBand.find(".highlight-band-stripe"),
              newBandColor = (spectrumType === "lightenRight") ?
                methods.modifyHSLLightness(bwMidHSL,hbColorMultiplier) :
                methods.modifyHSLLightness(darkGrayHSL,hbColorMultiplier);
          $highlightBand.css("border-color", newBandColor);
          $hbStripes.css("background-color", newBandColor);
        },

        calculateHighlightedColor: function () {
          var $thisEl = $(this),
              $thisParent = $thisEl.parent(),
              hbWidth = $(".highlight-band").first().outerWidth(),
              halfHBWidth = hbWidth / 2,
              tab = arguments[0].type,
              spectrumType,
              colorHsl,
              currentHue,
              currentSaturation,
              $advancedPreview,
              $saturationSpectrum,
              $hueSpectrum,
              $lightnessValue;

          if (tab === "basic") {
            var colorName = $thisParent.attr("class").split("-")[2],
                colorHex = settings.basicColors[colorName];
            colorHsl = tinycolor(colorHex).toHsl();
            switch(colorHex) {
              case "fff":
                spectrumType = "darkenRight";
                break;
              case "000":
                spectrumType = "lightenRight";
                break;
              default:
                spectrumType = "bidirectional";
            }
          } else {
            var $advancedContainer = $thisEl.parents(".advanced-list");
            currentSaturation = arguments[0].hsl.s;
            $hueSpectrum = $advancedContainer.find(".spectrum-hue");
            currentHue = arguments[0].hsl.h;
            $saturationSpectrum = $advancedContainer.find(".spectrum-saturation");
            $lightnessValue = $advancedContainer.find(".lightness-value");
            $advancedPreview = $advancedContainer.find(".color-preview");
            colorHsl = {"h": arguments[0].hsl.h, "l": 0.5, "s": arguments[0].hsl.s};
            spectrumType = "bidirectional";
          }

          var highlightBandLocation = parseInt($thisEl.css("left"),10) + halfHBWidth,
              colorMultiplier = methods.getColorMultiplier(spectrumType,highlightBandLocation,tab),
              highlightedColor = methods.modifyHSLLightness(colorHsl,colorMultiplier),
              highlightedHex = "#" + tinycolor(highlightedColor).toHex(),
              highlightedLightnessString = highlightedColor.split("(")[1].split(")")[0].split(",")[2],
              highlightedLightness = (parseInt(highlightedLightnessString.split("%")[0], 10)) / 100;

          if (tab === "basic") {
            $thisParent.siblings(".color-preview").css("background-color",highlightedHex);
            $thisParent.prev('.color-label').replaceWith(
              '<button class="color-select btn btn-mini" type="button">Select</button>');
            if (spectrumType !== "darkenRight") {
              methods.modifyHighlightBand($thisEl,colorMultiplier,spectrumType);
            }
          } else {
            $advancedPreview.css("background-color",highlightedHex);
            $lightnessValue.text(highlightedLightnessString);
            methods.updateSaturationStyles($saturationSpectrum,currentHue,highlightedLightness);
            methods.updateHueStyles($hueSpectrum,currentSaturation,highlightedLightness);
            methods.modifyHighlightBand($(".advanced-content .highlight-band"),colorMultiplier,spectrumType);
          }

          return (tab === "basic") ? tinycolor(highlightedColor).toHex() : highlightedLightness;
        },

        updateSavedColorPreview: function (elements) {
          $.each(elements, function (index) {
            var $this_el = $(elements[index]),
                thisColor = $this_el.attr("class");
            $this_el.find(".color-preview").css("background-color",thisColor);
          });
        },

        updateSavedColorMarkup: function ($savedColorsContent,mySavedColors) {
          mySavedColors = mySavedColors ? mySavedColors : allSavedColors;
          if (settings.showSavedColors && mySavedColors.length > 0) {

            if (!settings.saveColorsPerElement) {
              $savedColorsContent = $(".savedColors-content");
              mySavedColors = allSavedColors;
            }

            var maxSavedColors = myStyleVars.rowsInDropdown * myStyleVars.maxColsInDropdown;
            mySavedColors = mySavedColors.slice(0,maxSavedColors);

            var $col0 = $("<ul>").addClass("saved-color-col 0"),
                $col1 = $("<ul>").addClass("saved-color-col 1");

            $.each(mySavedColors, function (index,value) {
              var $this_li = $("<li>").addClass("color-item"),
                  $this_link = $("<a>").addClass(value);
              $this_link.append($("<span>").addClass("color-preview"));
              $this_link.append($("<span>").addClass("color-label").text(value));
              $this_li.append($this_link);
              if (index % 2 === 0) {
                $col0.append($this_li);
              } else {
                $col1.append($this_li);
              }
            });

            $savedColorsContent.html($col0);
            $savedColorsContent.append($col1);

            var savedColorLinks = $($savedColorsContent).find("a");
            methods.updateSavedColorPreview(savedColorLinks);

          }
        },

        setSavedColorsCookie: function (savedColors,savedColorsDataAttr) {
          var now = new Date(),
              tenYearsInMilliseconds = 315360000000,
              expiresOn = new Date(now.getTime() + tenYearsInMilliseconds);
          expiresOn = expiresOn.toGMTString();

          if (typeof savedColorsDataAttr === "undefined") {
            document.cookie = "pickAColorSavedColors-allSavedColors=" + savedColors +
              ";expires=" + expiresOn;
          } else {
            document.cookie = "pickAColorSavedColors-" + savedColorsDataAttr + "=" +
              savedColors + "; expires=" + expiresOn;
          }
        },

        saveColorsToLocalStorage: function (savedColors,savedColorsDataAttr) {
          if (supportsLocalStorage) {
            if (typeof savedColorsDataAttr === "undefined") {
              try {
                localStorage.allSavedColors = JSON.stringify(savedColors);
              }
              catch(e) {
                localStorage.clear();
              }
            } else {
              try {
                localStorage["pickAColorSavedColors-" + savedColorsDataAttr] =
                  JSON.stringify(savedColors);
              }
              catch(e) {
                localStorage.clear();
              }
            }
          } else {
            methods.setSavedColorsCookie(savedColors,savedColorsDataAttr);
          }
        },

        removeFromArray: function (array, item) {
          if ($.inArray(item,array) !== -1) {
            array.splice($.inArray(item,array),1);
          }
        },

        updateSavedColors: function (color,savedColors,savedColorsDataAttr) {
          methods.removeFromArray(savedColors,color);
          savedColors.unshift(color);
          methods.saveColorsToLocalStorage(savedColors,savedColorsDataAttr);
        },

        addToSavedColors: function (color,mySavedColorsInfo,$mySavedColorsContent) {
          if (settings.showSavedColors && color !== undefined) {
            if (color[0] != "#") {
              color = "#" + color;
            }
            methods.updateSavedColors(color,allSavedColors);
            if (settings.saveColorsPerElement) { 
              var mySavedColors = mySavedColorsInfo.colors,
                  dataAttr = mySavedColorsInfo.dataAttr;
              methods.updateSavedColors(color,mySavedColors,dataAttr);
              methods.updateSavedColorMarkup($mySavedColorsContent,mySavedColors);
            } else {
              methods.updateSavedColorMarkup($mySavedColorsContent,allSavedColors);
            }
          }
        },

        selectFromBasicColors: function () {
          var selectedColor = $(this).find("span:first").css("background-color"),
              myElements = arguments[0].els,
              mySavedColorsInfo = arguments[0].savedColorsInfo;
          selectedColor = tinycolor(selectedColor).toHex();
          $(myElements.thisEl).val(selectedColor);
          $(myElements.thisEl).trigger("change");
          methods.updatePreview(myElements.thisEl);
          methods.addToSavedColors(selectedColor,mySavedColorsInfo,myElements.savedColorsContent);
          methods.closeDropdown(myElements.colorPreviewButton,myElements.colorMenu);
        },

        tapSpectrum: function () {
          var thisEvent = arguments[0].thisEvent,
              mySavedColorsInfo = arguments[0].savedColorsInfo,
              myElements = arguments[0].els,
              mostRecentClick = arguments[0].mostRecentClick;
          thisEvent.stopPropagation();
          var $highlightBand = $(this).find(".highlight-band"),
              dimensions = methods.getMoveableArea($highlightBand);
          if (supportsTouch) {
            methods.moveHighlightBand($highlightBand, dimensions, mostRecentClick);
          } else {
            methods.moveHighlightBand($highlightBand, dimensions, thisEvent);
          }
          var highlightedColor = methods.calculateHighlightedColor.apply($highlightBand, [{type: "basic"}]);
          methods.addToSavedColors(highlightedColor,mySavedColorsInfo,myElements.savedColorsContent);
          myElements.touchInstructions.html("Press 'select' to choose this color");
        },

        executeUnlessScrolled: function () {
          var thisFunction = arguments[0].thisFunction,
              theseArguments = arguments[0].theseArguments,
              windowTopPosition,
              mostRecentClick;
          $(this).on(startEvent, function (e) {
            windowTopPosition = $(window).scrollTop();
            mostRecentClick = e;
          }).on(clickEvent, function (event) {
            var distance = windowTopPosition - $(window).scrollTop();
            if (supportsTouch && (Math.abs(distance) > 0)) {
              return false;
            } else {
              theseArguments.thisEvent = event;
              theseArguments.mostRecentClick = mostRecentClick;
              thisFunction.apply($(this), [theseArguments]);
            }
          });
        },

        updateSaturationStyles: function (spectrum, hue, lightness) {
          var lightnessString = (lightness * 100).toString() + "%",
              start = "#" + tinycolor("hsl(" + hue + ",0%," + lightnessString).toHex(),
              mid = "#" + tinycolor("hsl(" + hue + ",50%," + lightnessString).toHex(),
              end = "#" + tinycolor("hsl(" + hue + ",100%," + lightnessString).toHex(),
              fullSpectrumString = "",
              standard = $.each(["-webkit-linear-gradient","-o-linear-gradient"], function(index,value) {
                fullSpectrumString += "background-image: " + value + "(left, " + start + " 0%, " + mid + " 50%, " + end + " 100%);";
              }),
              ieSpectrum0 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + start + "', endColorstr='" +
              mid + "', GradientType=1)",
              ieSpectrum1 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + mid + "', endColorstr='" +
              end + "', GradientType=1)";
            fullSpectrumString =
              "background-image: -moz-linear-gradient(left center, " + start + " 0%, " + mid + " 50%, " + end + " 100%);" +
              "background-image: linear-gradient(to right, " + start + " 0%, " + mid + " 50%, " + end + " 100%); " +
              "background-image: -webkit-gradient(linear, left top, right top," +
                "color-stop(0, " + start + ")," + "color-stop(0.5, " + mid + ")," + "color-stop(1, " + end + "));" +
              fullSpectrumString;
          if (isIELT10) {
            var $spectrum0 = $(spectrum).find(".saturation-spectrum-0");
            var $spectrum1 = $(spectrum).find(".saturation-spectrum-1");
            $spectrum0.css("filter",ieSpectrum0);
            $spectrum1.css("filter",ieSpectrum1);
          } else {
            spectrum.attr("style",fullSpectrumString);
          }
        },

        updateLightnessStyles: function (spectrum, hue, saturation) {
          var saturationString = (saturation * 100).toString() + "%",
              start = "#" + tinycolor("hsl(" + hue + "," + saturationString + ",100%)").toHex(),
              mid = "#" + tinycolor("hsl(" + hue + "," + saturationString + ",50%)").toHex(),
              end = "#" + tinycolor("hsl(" + hue + "," + saturationString + ",0%)").toHex(),
              fullSpectrumString = "",
              standard = $.each(["-webkit-linear-gradient","-o-linear-gradient"], function(index, value) {
                fullSpectrumString += "background-image: " + value + "(left, " + start + " 0%, " + mid + " 50%, "
                + end + " 100%);";
              }),
              ieSpectrum0 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + start + "', endColorstr='" +
              mid + "', GradientType=1)",
              ieSpectrum1 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + mid + "', endColorstr='" +
              end + "', GradientType=1)";
          fullSpectrumString =
            "background-image: -moz-linear-gradient(left center, " + start + " 0%, " + mid + " 50%, " + end + " 100%); " +
            "background-image: linear-gradient(to right, " + start + " 0%, " + mid + " 50%, " + end + " 100%); " +
            "background-image: -webkit-gradient(linear, left top, right top," +
            " color-stop(0, " + start + ")," + " color-stop(0.5, " + mid + ")," + " color-stop(1, " + end + ")); " +
            fullSpectrumString;
          if (isIELT10) {
            var $spectrum0 = $(spectrum).find(".lightness-spectrum-0");
            var $spectrum1 = $(spectrum).find(".lightness-spectrum-1");
            $spectrum0.css("filter",ieSpectrum0);
            $spectrum1.css("filter",ieSpectrum1);
          } else {
            spectrum.attr("style",fullSpectrumString);
          }
        },

        updateHueStyles: function (spectrum, saturation, lightness) {
          var saturationString = (saturation * 100).toString() + "%",
              lightnessString = (lightness * 100).toString() + "%",
              color1 = "#" + tinycolor("hsl(0," + saturationString + "," + lightnessString + ")").toHex(),
              color2 = "#" + tinycolor("hsl(60," + saturationString + "," + lightnessString + ")").toHex(),
              color3 = "#" + tinycolor("hsl(120," + saturationString + "," + lightnessString + ")").toHex(),
              color4 = "#" + tinycolor("hsl(180," + saturationString + "," + lightnessString + ")").toHex(),
              color5 = "#" + tinycolor("hsl(240," + saturationString + "," + lightnessString + ")").toHex(),
              color6 = "#" + tinycolor("hsl(300," + saturationString + "," + lightnessString + ")").toHex(),
              color7 = "#" + tinycolor("hsl(0," + saturationString + "," + lightnessString + ")").toHex(),
              ieSpectrum0 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + color1 + "', endColorstr='" +
              color2 + "', GradientType=1)",
              ieSpectrum1 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + color2 + "', endColorstr='" +
              color3 + "', GradientType=1)",
              ieSpectrum2 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + color3 + "', endColorstr='" +
              color4 + "', GradientType=1)",
              ieSpectrum3 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + color4 + "', endColorstr='" +
              color5 + "', GradientType=1)",
              ieSpectrum4 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + color5 + "', endColorstr='" +
              color6 + "', GradientType=1)",
              ieSpectrum5 = "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + color6 + "', endColorstr='" +
              color7 + "', GradientType=1)",
              fullSpectrumString = "",
              standard = $.each(["-webkit-linear-gradient","-o-linear-gradient"], function(index,value) {
                fullSpectrumString += "background-image: " + value + "(left, " + color1 + " 0%, " + color2 + " 17%, " +
                  color3 + " 24%, " + color4 + " 51%, " + color5 + " 68%, " + color6 + " 85%, " + color7 + " 100%);";
            });
          fullSpectrumString += "background-image: -webkit-gradient(linear, left top, right top," +
            "color-stop(0%, " + color1 + ")," + "color-stop(17%, " + color2 + ")," + "color-stop(34%, " + color3 + ")," +
            "color-stop(51%, " + color4 + ")," + "color-stop(68%, " + color5 + ")," + "color-stop(85%, " + color6 + ")," +
            "color-stop(100%, " + color7 + "));" + 
            "background-image: linear-gradient(to right, " + color1 + " 0%, " + color2 + " 17%, " + color3 + " 24%," + 
            color4 + " 51%," + color5 + " 68%," + color6 + " 85%," + color7 + " 100%); " +
            "background-image: -moz-linear-gradient(left center, " +
            color1 + " 0%, " + color2 + " 17%, " + color3 + " 24%, " + color4 + " 51%, " + color5 + " 68%, " +
            color6 + " 85%, " + color7 + " 100%);";
          if (isIELT10) {
            var $spectrum0 = $(spectrum).find(".hue-spectrum-0"),
                $spectrum1 = $(spectrum).find(".hue-spectrum-1"),
                $spectrum2 = $(spectrum).find(".hue-spectrum-2"),
                $spectrum3 = $(spectrum).find(".hue-spectrum-3"),
                $spectrum4 = $(spectrum).find(".hue-spectrum-4"),
                $spectrum5 = $(spectrum).find(".hue-spectrum-5");
            $spectrum0.css("filter",ieSpectrum0);
            $spectrum1.css("filter",ieSpectrum1);
            $spectrum2.css("filter",ieSpectrum2);
            $spectrum3.css("filter",ieSpectrum3);
            $spectrum4.css("filter",ieSpectrum4);
            $spectrum5.css("filter",ieSpectrum5);
          } else {
            spectrum.attr("style",fullSpectrumString);
            
          }
        },
		
        getHighlightedHue: function () {
          var $thisEl = $(this),
              hbWidth = $thisEl.outerWidth(),
              halfHBWidth = hbWidth / 2,
              position = parseInt($thisEl.css("left"),10) + halfHBWidth,
              $advancedContainer = $thisEl.parents(".advanced-list"),
              $advancedPreview = $advancedContainer.find(".color-preview"),
              $lightnessSpectrum = $advancedContainer.find(".spectrum-lightness"),
              $saturationSpectrum = $advancedContainer.find(".spectrum-saturation"),
              spectrumWidth = parseInt($advancedContainer.find(".color-box").first().width(),10),
              $hueValue = $advancedContainer.find(".hue-value"),
              currentLightness = arguments[0].l,
              currentSaturation = arguments[0].s,
              saturationString = (currentSaturation * 100).toString() + "%",
              lightnessString = (currentLightness * 100).toString() + "%";

          if (spectrumWidth === 0) {
            spectrumWidth = supportsTouch ? 160 : 300;
          }

          var hue = Math.floor((position/spectrumWidth) * 360),
              color = "hsl(" + hue + "," + saturationString + "," + lightnessString + ")";
          color = "#" + tinycolor(color).toHex();

          $advancedPreview.css("background-color",color);
          $hueValue.text(hue);
          methods.updateLightnessStyles($lightnessSpectrum,hue,currentSaturation);
          methods.updateSaturationStyles($saturationSpectrum,hue,currentLightness);
          return hue;
        },

        getHighlightedSaturation: function () {
          var $thisEl = $(this),
              hbWidth = $thisEl.outerWidth(),
              halfHBWidth = hbWidth / 2,
              position = parseInt($thisEl.css("left"),10) + halfHBWidth,
              $advancedContainer = $thisEl.parents(".advanced-list"),
              $advancedPreview = $advancedContainer.find(".color-preview"),
              $lightnessSpectrum = $advancedContainer.find(".spectrum-lightness"),
              $hueSpectrum = $advancedContainer.find(".spectrum-hue"),
              $saturationValue = $advancedContainer.find(".saturation-value"),
              spectrumWidth = parseInt($advancedContainer.find(".color-box").first().width(),10),
              currentLightness = arguments[0].l,
              lightnessString = (currentLightness * 100).toString() + "%",
              currentHue = arguments[0].h;

          if (spectrumWidth === 0) {
            spectrumWidth = supportsTouch ? 160 : 300;
          }

          var saturation = position/spectrumWidth,
              saturationString = Math.round((saturation * 100)).toString() + "%",
              color = "hsl(" + currentHue + "," + saturationString + "," + lightnessString + ")";
          color = "#" + tinycolor(color).toHex();

          $advancedPreview.css("background-color",color);
          $saturationValue.text(saturationString);
          methods.updateLightnessStyles($lightnessSpectrum,currentHue,saturation);
          methods.updateHueStyles($hueSpectrum,saturation,currentLightness);
          return saturation;
        },

        updateAdvancedInstructions: function (instructionsEl) {
          instructionsEl.html("Press the color preview to choose this color");
        }

      };

      return this.each(function (index) {

        methods.initialize.apply(this,[index]);
        
        var myElements = {
          thisEl: $(this),
          thisWrapper: $(this).parent(),
          colorTextInput: $(this).find("input"),
          colorMenuLinks: $(this).parent().find(".color-menu li a"),
          colorPreviewButton: $(this).parent().find(".input-group-append"),
          colorMenu: $(this).parent().find(".color-menu"),
          colorSpectrums: $(this).parent().find(".color-box"),
          basicSpectrums: $(this).parent().find(".basicColors-content .color-box"),
          touchInstructions: $(this).parent().find(".color-menu-instructions"),
          advancedInstructions: $(this).parent().find(".advanced-instructions"),
          highlightBands: $(this).parent().find(".highlight-band"),
          basicHighlightBands: $(this).parent().find(".basicColors-content .highlight-band")
        };

        var mostRecentClick,
            windowTopPosition,
            advancedStatus,
            mySavedColorsInfo;

        if (useTabs) {
          myElements.tabs = myElements.thisWrapper.find(".tab");
        }

        if (settings.showSavedColors) {
          myElements.savedColorsContent = myElements.thisWrapper.find(".savedColors-content");
          if (settings.saveColorsPerElement) {
            mySavedColorsInfo = {
              colors: [],
              dataObj: $(this).data()
            };
            $.each(mySavedColorsInfo.dataObj, function (key) {
              mySavedColorsInfo.dataAttr = key;
            });

            if (supportsLocalStorage && localStorage["pickAColorSavedColors-" +
              mySavedColorsInfo.dataAttr]) {
              mySavedColorsInfo.colors = JSON.parse(localStorage["pickAColorSavedColors-" +
                mySavedColorsInfo.dataAttr]);

            } else if (document.cookie.match("pickAColorSavedColors-" +
              mySavedColorsInfo.dataAttr)) {
              var theseCookies = document.cookie.split(";"); 
              for (var i=0; i < theseCookies.length; i++) {
                if (theseCookies[i].match(mySavedColorsInfo.dataAttr)) {
                  mySavedColorsInfo.colors = theseCookies[i].split("=")[1].split(",");
                }
              }

            } else {
              mySavedColorsInfo.colors = allSavedColors;
            }
          }
        }
        if (settings.showAdvanced) {
          advancedStatus = {
            h: 0,
            s: 1,
            l: 0.5
          };

          myElements.advancedSpectrums = myElements.thisWrapper.find(".advanced-list").find(".color-box");
          myElements.advancedHighlightBands = myElements.thisWrapper.find(".advanced-list").find(".highlight-band");
          myElements.hueSpectrum = myElements.thisWrapper.find(".spectrum-hue");
          myElements.lightnessSpectrum = myElements.thisWrapper.find(".spectrum-lightness");
          myElements.saturationSpectrum = myElements.thisWrapper.find(".spectrum-saturation");
          myElements.hueHighlightBand = myElements.thisWrapper.find(".spectrum-hue .highlight-band");
          myElements.lightnessHighlightBand = myElements.thisWrapper.find(".spectrum-lightness .highlight-band");
          myElements.saturationHighlightBand = myElements.thisWrapper.find(".spectrum-saturation .highlight-band");
          myElements.advancedPreview = myElements.thisWrapper.find(".advanced-content .color-preview");
        }

        methods.addToSavedColors(myColorVars.defaultColor,mySavedColorsInfo,myElements.savedColorsContent);
        methods.updatePreview(myElements.thisEl);

        myElements.thisEl.focus(function () {
          var $thisEl = $(this);
          myColorVars.typedColor = $thisEl.val();
          if (!settings.allowBlank) {
            $thisEl.val("");
          }
          methods.toggleDropdown(myElements.colorPreviewButton,myElements.ColorMenu);
        }).blur(function () {
          var $thisEl = $(this);
          myColorVars.newValue = $thisEl.val();
          
          if (myColorVars.newValue.match(/^\s+$|^$/)) {
            if (!settings.allowBlank) {
              $thisEl.val(myColorVars.typedColor);
            }
          } else { 
            myColorVars.newValue = tinycolor(myColorVars.newValue).toHex(); 
            $thisEl.val(myColorVars.newValue); 
            
            methods.addToSavedColors(myColorVars.newValue,mySavedColorsInfo,myElements.savedColorsContent);
          }
          methods.toggleDropdown(myElements.colorPreviewButton,myElements.ColorMenu);
          methods.updatePreview($thisEl); 
        });

        methods.executeUnlessScrolled.apply(myElements.colorPreviewButton,
          [{"thisFunction": methods.pressPreviewButton, "theseArguments" : {}}]);

        methods.executeUnlessScrolled.apply($(document), [{"thisFunction": methods.closeDropdownIfOpen,
          "theseArguments": {"button": myElements.colorPreviewButton, "menu": myElements.colorMenu}}]);

        myElements.colorMenu.on(clickEvent, function (e) {
          e.stopPropagation();
        });
        myElements.thisEl.on(clickEvent, function(e) {
          e.stopPropagation();
        });

        methods.executeUnlessScrolled.apply(myElements.colorMenuLinks, [{"thisFunction": methods.selectFromBasicColors,
          "theseArguments": {"els": myElements, "savedColorsInfo": mySavedColorsInfo}}]);

        if (useTabs) { 
          methods.tabbable.apply(myElements.tabs);
        }

        if (settings.showSpectrum || settings.showAdvanced) {
          methods.horizontallyDraggable.apply(myElements.highlightBands);
        }

        if (settings.showSpectrum) {

          methods.executeUnlessScrolled.apply(myElements.basicSpectrums, [{"thisFunction": methods.tapSpectrum,
            "theseArguments": {"savedColorsInfo": mySavedColorsInfo, "els": myElements}}]);

          $(myElements.basicHighlightBands).on(dragEvent,function (event) {
            var $thisEl = event.target;
            methods.calculateHighlightedColor.apply(this, [{type: "basic"}]);
          }).on(endDragEvent, function (event) {
            var $thisEl = event.delegateTarget;
            var finalColor = methods.calculateHighlightedColor.apply($thisEl, [{type: "basic"}]);
            methods.addToSavedColors(finalColor,mySavedColorsInfo,myElements.savedColorsContent);
          });

        }

        if (settings.showAdvanced) {

          $(myElements.hueHighlightBand).on(dragEvent, function(event) {
            advancedStatus.h = methods.getHighlightedHue.apply(this, [advancedStatus]);
          });

          $(myElements.lightnessHighlightBand).on(dragEvent, function() {
            methods.calculateHighlightedColor.apply(this, [{"type": "advanced", "hsl": advancedStatus}]);
          }).on(endEvent, function () {
            advancedStatus.l = methods.calculateHighlightedColor.apply(this, [{"type": "advanced", "hsl": advancedStatus}]);
          });

          $(myElements.saturationHighlightBand).on(dragEvent, function() {
            methods.getHighlightedSaturation.apply(this, [advancedStatus]);
          }).on(endDragEvent, function () {
            advancedStatus.s = methods.getHighlightedSaturation.apply(this, [advancedStatus]);
          });

          $(myElements.advancedHighlightBand).on(endDragEvent, function () {
            methods.updateAdvancedInstructions(myElements.advancedInstructions);
          });

          $(myElements.lightnessSpectrum).click( function (event) {
            event.stopPropagation(); 
            var $highlightBand = $(this).find(".highlight-band"),
                dimensions = methods.getMoveableArea($highlightBand);
            methods.moveHighlightBand($highlightBand, dimensions, event);
            advancedStatus.l = methods.calculateHighlightedColor.apply($highlightBand, [{"type": "advanced", "hsl": advancedStatus}]);
          });

          $(myElements.hueSpectrum).click( function (event) {
            event.stopPropagation(); 
            var $highlightBand = $(this).find(".highlight-band"),
                dimensions = methods.getMoveableArea($highlightBand);
            methods.moveHighlightBand($highlightBand, dimensions, event);
            advancedStatus.h = methods.getHighlightedHue.apply($highlightBand, [advancedStatus]);
          });

          $(myElements.saturationSpectrum).click( function (event) {
            event.stopPropagation(); 
            var $highlightBand = $(this).find(".highlight-band"),
                dimensions = methods.getMoveableArea($highlightBand);
            methods.moveHighlightBand($highlightBand, dimensions, event);
            advancedStatus.s = methods.getHighlightedSaturation.apply($highlightBand, [advancedStatus]);
          });

          $(myElements.advancedSpectrums).click( function () {
            methods.updateAdvancedInstructions(myElements.advancedInstructions);
          });

          $(myElements.advancedPreview).click( function () {
            var selectedColor = tinycolor($(this).css("background-color")).toHex();
            $(myElements.thisEl).val(selectedColor);
            $(myElements.thisEl).trigger("change");
            methods.updatePreview(myElements.thisEl);
            methods.addToSavedColors(selectedColor,mySavedColorsInfo,myElements.savedColorsContent);
            methods.closeDropdown(myElements.colorPreviewButton,myElements.colorMenu); 
          });
        }

        if (settings.showSavedColors) {

          $(myElements.savedColorsContent).click( function (event) {
            var $thisEl = $(event.target);

            if ($thisEl.is("SPAN") || $thisEl.is("A")) {
              var selectedColor = $thisEl.is("SPAN") ?
                $thisEl.parent().attr("class").split("#")[1] :
                $thisEl.attr("class").split("#")[1];
              $(myElements.thisEl).val(selectedColor);
              $(myElements.thisEl).trigger("change");
              methods.updatePreview(myElements.thisEl);
              methods.closeDropdown(myElements.colorPreviewButton,myElements.colorMenu);
              methods.addToSavedColors(selectedColor,mySavedColorsInfo,myElements.savedColorsContent);
            }
          });

          if (!settings.saveColorsPerElement) {
            methods.updateSavedColorMarkup(myElements.savedColorsContent,allSavedColors);
          } else if (settings.saveColorsPerElement) {
            methods.updateSavedColorMarkup(myElements.savedColorsContent,mySavedColorsInfo.colors);
          }
        }
      });
    };

})(jQuery);
