/*import * as $ from 'jquery';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

export default (function () {
  $('.start-date').datepicker();
  $('.end-date').datepicker();
}())*/

import * as $ from 'jquery';
import './datepicker-jquery-ui.min.js';
import './datepicker-jquery-ui.min.css';
$(document).ready(function()
{
    $('.datepicker:not([readonly],[disabled])').datepicker({
        format: 'dd/mm/yyyy',
        dateFormat: "dd/mm/yy",
        closeText: "Fin",
        prevText: "Préc",
        nextText: "Suiv",
        currentText: "Aujourd'hui",
        monthNames: [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ],
        monthNamesShort: [ "Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec" ],
        dayNames: [ "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi" ],
        dayNamesShort: [ "Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam" ],
        dayNamesMin: [ "Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa" ],
        weekHeader: "Sem",
        showMonthAfterYear: false,
        firstDay: 1,
        yearSuffix: ""
    });
});