import '../scss/aurore.scss';

import './index.js';

import '../images/logo.png';
import '../images/bg.jpg';
import '../images/404.png';
import '../images/500.png';
import '../images/favicon-16x16.png';
import '../images/favicon-32x32.png';

import '../images/datatables/sort_asc.png';
import '../images/datatables/sort_asc_disabled.png';
import '../images/datatables/sort_both.png';
import '../images/datatables/sort_desc.png';
import '../images/datatables/sort_desc_disabled.png';

import '../fonts/icons/fontawesome/fontawesome-webfont.eot';
import '../fonts/icons/fontawesome/fontawesome-webfont.woff';
import '../fonts/icons/fontawesome/fontawesome-webfont.woff2';
import '../fonts/icons/fontawesome/fontawesome-webfont.svg';
import '../fonts/icons/fontawesome/fontawesome-webfont.ttf';

import '../fonts/icons/themify/themify.eot';
import '../fonts/icons/themify/themify.svg';
import '../fonts/icons/themify/themify.ttf';
import '../fonts/icons/themify/themify.woff';


