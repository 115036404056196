import './pick-a-color.js';
import './pick-a-color.min.css';

$(document).ready(function()
{
    $(".pick-a-color").pickAColor({
        showSpectrum            : true,
        showSavedColors         : true,
        saveColorsPerElement    : false,
        fadeMenuToggle          : true,
        showHexInput            : true,
        showBasicColors         : true,
        allowBlank              : false,
        inlineDropdown          : false
    });
});