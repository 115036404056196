import * as $ from 'jquery';
//import 'fullcalendar/dist/fullcalendar.min.js';
//import 'fullcalendar/dist/fullcalendar.min.css';

import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';

function calendarMinTime(selectedDay)
{
    // Default
    var slotMinTime = '08:00:00';
    var dateNow = new Date();
    dateNow.setHours(dateNow.getHours() - 2);
    var hourM2 = (dateNow.getHours() < 8 ? 8 : (dateNow.getHours() > 18 ? 18 : dateNow.getHours()));

    // slotMinTime (if today), another day = default
    if(selectedDay.getFullYear() === dateNow.getFullYear() && selectedDay.getMonth() === dateNow.getMonth() && selectedDay.getDay() === dateNow.getDay()){
        slotMinTime = (hourM2 < 10 ? '0' : '') + hourM2 + ':00:00';
    }

    return slotMinTime;
}

function calendarMinHeight(minTime, maxTime)
{
    var minHour = (minTime.substr(0, 2)) * 1;
    var maxHour = (maxTime.substr(0, 2)) * 1;
    return (maxHour - minHour) * 120;
}

$(document).ready(function()
{
    // Medium Planning
    var calendarMediumPlanning = document.getElementById('medium-planning-display');
    if(calendarMediumPlanning !== null)
    {
        var calendarMedium = new Calendar(calendarMediumPlanning, {
            contentHeight           : 1500,
            expandRows              : true,
            locale                  : 'fr',
            timeZone                : 'local',
            handleWindowResize      : true,
            firstDay                : 1,
            droppable               : false,
            slotEventOverlap        : false,
            slotDuration            : '00:15:00',
            slotLabelInterval       : '01:00:00',
            slotLabelFormat         :
            {
                hour            : '2-digit',
                minute          : '2-digit',
                omitZeroMinute  : true,
                meridiem        : false,
                omitCommas      : true
            },
            slotMinTime             : "08:00:00",
            slotMaxTime             : "22:00:00",
            plugins                 : [ dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin, bootstrapPlugin ],
            allDaySlot              : false,
            nowIndicator            : true,
            displayEventEnd         : true,
            //dayHeaderFormat         : { weekday: 'long' },
            themeSystem             : 'bootstrap',
            buttonText              :
                {
                    today   : 'Aujourd\'hui',
                    month   : 'Mois',
                    week    : 'Semaine',
                    day     : 'Jour',
                    list    : 'Liste',
                },
            headerToolbar           :
                {
                    left   : 'dayGridMonth,timeGridWeek,timeGridDay',
                    center : 'title',
                    right  : 'today prev,next'
                },
            eventSources            :
                [
                    {
                        url: "/json/medium/plannings",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                        },
                        error: function() {
                            alert('Erreur lors de la récupération des plannings');
                        },
                    }
                ],
            businessHours:
                [
                    {
                        daysOfWeek: [ 1, 2, 3, 4, 5 ],
                        startTime: '08:45',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 6 ],
                        startTime: '09:00',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 0 ],
                        startTime: '09:30',
                        endTime: '20:00'
                    }
                ],
            eventDurationEditable   : true,
            eventResizableFromStart : true,

            eventResize: function(info)
            {
                if(moment(info.event.end).format('YYYYMMDDHHmm') < moment().format('YYYYMMDDHHmm'))
                {
                    alert("Impossible de déplacer ce planning à une date passée !");
                    info.revert();
                }
                else
                {
                    if(confirm(info.event.title + ' sera sur le planning du ' + moment(info.event.start).format('DD/MM [de] HH[h]mm') + ' à ' + moment(info.event.end).format('HH[h]mm')))
                    {
                        $.ajax({
                            url: "/json/medium/planning/update",
                            type: "GET",
                            dataType: "JSON",
                            data: {
                                id: info.event.id,
                                newdate: moment(info.event.start).format('YYYY-MM-DD'),
                                newstart: moment(info.event.start).format('HH:mm:ss'),
                                newstop: moment(info.event.end).format('HH:mm:ss'),
                            },
                            success: function(updateResult)
                            {
                                if(!updateResult){info.revert();}
                            },
                            error: function(err) {
                                info.revert();
                            }
                        });
                    }
                    else
                    {
                        info.revert();
                    }
                }
            },

            eventDrop: function(event, delta, revertFunc)
            {

                var timeNow =  (new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000)).toISOString().replace(/[^0-9]/g, "").slice(0,12);

                if(event.end.format('YYYYMMDDHHmm') < timeNow)
                {
                    alert("Impossible de déplacer ce planning à une date passée !");
                    revertFunc();
                }
                else
                {
                    if (confirm(event.title + ' sera sur le planning du ' + event.start.format('DD/MM') + ' de ' +
                        event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                        event.end.format('HH') + 'h' + event.end.format('mm')))
                    {
                        $.ajax({
                            url: "/json/medium/planning/update",
                            type: "GET",
                            dataType: "JSON",
                            data: {
                                id: event.id,
                                newdate: event.start.format('YYYY-MM-DD'),
                                newstart: event.start.format('HH:mm:ss'),
                                newstop: event.end.format('HH:mm:ss'),
                            },
                            success: function(updateResult)
                            {
                                if(!updateResult){revertFunc();}
                            },
                            error: function(err) {
                                revertFunc();
                            }
                        });
                    }
                    else
                    {
                        revertFunc();
                    }
                }
            },

            initialView             : 'timeGridWeek'
        });
        calendarMedium.updateSize();
        calendarMedium.render();
    }

    // Medium Planning Model
    var calendarMediumPlanningModel = document.getElementById('medium-modelplanning-display');
    if(calendarMediumPlanningModel !== null)
    {
        var calendarMediumModel = new Calendar(calendarMediumPlanningModel, {
            contentHeight           : 1500,
            expandRows              : true,
            locale                  : 'fr',
            timeZone                : 'local',
            handleWindowResize      : true,
            firstDay                : 1,
            droppable               : false,
            slotEventOverlap        : false,
            slotDuration            : '00:15:00',
            slotLabelInterval       : '01:00:00',
            slotLabelFormat         :
                {
                    hour            : '2-digit',
                    minute          : '2-digit',
                    omitZeroMinute  : true,
                    meridiem        : false,
                    omitCommas      : true
                },
            slotMinTime             : "08:00:00",
            slotMaxTime             : "22:00:00",
            validRange              :
                {
                    start   : '2018-01-01',
                    end     : '2018-01-08'
                },
            plugins                 : [ dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin, bootstrapPlugin ],
            allDaySlot              : false,
            nowIndicator            : false,
            displayEventEnd         : true,
            dayHeaderFormat         : { weekday: 'long' },
            themeSystem             : 'bootstrap',
            buttonText              :
                {
                    week    : 'Semaine',
                    day     : 'Jour',
                    list    : 'Liste',
                },
            headerToolbar           :
                {
                    left   : 'timeGridWeek,timeGridDay',
                    center : '',
                    right  : 'prev,next'
                },
            eventSources            :
                [
                    {
                        url: "/json/medium/planning/models",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                        },
                        error: function() {
                            alert('Erreur lors de la récupération des modèles de plannings');
                        },
                    }
                ],
            businessHours:
                [
                    {
                        daysOfWeek: [ 1, 2, 3, 4, 5 ],
                        startTime: '08:45',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 6 ],
                        startTime: '09:00',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 0 ],
                        startTime: '09:30',
                        endTime: '20:00'
                    }
                ],
            eventDurationEditable   : true,
            eventResizableFromStart : true,

            eventResize: function(info)
            {
                if(confirm(info.event.title + ' sera sur le planning du ' + moment(info.event.start).format('dddd [de] HH[h]mm') + ' à ' + moment(info.event.end).format('HH[h]mm')))
                {
                    $.ajax({
                        url: "/json/medium/planning/model/update",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                            id: info.event.id,
                            newday: moment(info.event.start).format('D'),
                            newstart: moment(info.event.start).format('HH:mm:ss'),
                            newstop: moment(info.event.end).format('HH:mm:ss'),
                        },
                        success: function(updateResult)
                        {
                            if(!updateResult){info.revert();}
                        },
                        error: function(err) {
                            info.revert();
                        }
                    });
                }
                else
                {
                    info.revert();
                }

            },

            eventDrop: function(event, delta, revertFunc)
            {
                if (confirm(event.title + ' sera sur le planning du ' + event.start.format('dddd') + ' de ' +
                    event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                    event.end.format('HH') + 'h' + event.end.format('mm')))
                {
                    $.ajax({
                        url: "/json/medium/planning/model/update",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                            id: event.id,
                            newday: event.start.format('D'),
                            newstart: event.start.format('HH:mm:ss'),
                            newstop: event.end.format('HH:mm:ss'),
                        },
                        success: function(updateResult)
                        {
                            if(!updateResult){revertFunc();}
                        },
                        error: function(err) {
                            revertFunc();
                        }
                    });
                }
                else
                {
                    revertFunc();
                }

            },

            initialView             : 'timeGridWeek'
        });
        calendarMediumModel.updateSize();
        calendarMediumModel.render();
    }

    // Admin Planning
    var calendarAdminPlanning = document.getElementById('user-planning-display');
    if(calendarAdminPlanning !== null)
    {
        var calendarAdmin = new Calendar(calendarAdminPlanning, {
            contentHeight           : 1500,
            expandRows              : true,
            locale                  : 'fr',
            timeZone                : 'local',
            handleWindowResize      : true,
            firstDay                : 1,
            droppable               : false,
            slotEventOverlap        : false,
            slotDuration            : '00:15:00',
            slotLabelInterval       : '01:00:00',
            slotLabelFormat         :
                {
                    hour            : '2-digit',
                    minute          : '2-digit',
                    omitZeroMinute  : true,
                    meridiem        : false,
                    omitCommas      : true
                },
            slotMinTime             : "08:00:00",
            slotMaxTime             : "22:00:00",
            plugins                 : [ dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin, bootstrapPlugin ],
            allDaySlot              : false,
            nowIndicator            : true,
            displayEventEnd         : true,
            //dayHeaderFormat         : { weekday: 'long' },
            themeSystem             : 'bootstrap',
            buttonText              :
                {
                    today   : 'Aujourd\'hui',
                    month   : 'Mois',
                    week    : 'Semaine',
                    day     : 'Jour',
                    list    : 'Liste',
                },
            headerToolbar           :
                {
                    left   : 'dayGridMonth,timeGridWeek,timeGridDay',
                    center : 'title',
                    right  : 'today prev,next'
                },
            eventSources            :
                [
                    {
                        url: "/json/user/plannings",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                        },
                        error: function() {
                            alert('Erreur lors de la récupération des plannings');
                        },
                    }
                ],
            businessHours:
                [
                    {
                        daysOfWeek: [ 1, 2, 3, 4, 5 ],
                        startTime: '08:45',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 6 ],
                        startTime: '09:00',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 0 ],
                        startTime: '09:30',
                        endTime: '20:00'
                    }
                ],
            eventDurationEditable   : true,
            eventResizableFromStart : true,

            eventResize: function(info)
            {
                if(moment(info.event.end).format('YYYYMMDDHHmm') < moment().format('YYYYMMDDHHmm'))
                {
                    alert("Impossible de déplacer ce planning à une date passée !");
                    info.revert();
                }
                else
                {
                    if(confirm(info.event.title + ' sera sur le planning du ' + moment(info.event.start).format('DD/MM [de] HH[h]mm') + ' à ' + moment(info.event.end).format('HH[h]mm')))
                    {
                        $.ajax({
                            url: "/json/user/planning/update",
                            type: "GET",
                            dataType: "JSON",
                            data: {
                                id: info.event.id,
                                newdate: moment(info.event.start).format('YYYY-MM-DD'),
                                newstart: moment(info.event.start).format('HH:mm:ss'),
                                newstop: moment(info.event.end).format('HH:mm:ss'),
                            },
                            success: function(updateResult)
                            {
                                if(!updateResult){info.revert();}
                            },
                            error: function(err) {
                                info.revert();
                            }
                        });
                    }
                    else
                    {
                        info.revert();
                    }
                }
            },

            eventDrop: function(event, delta, revertFunc)
            {

                var timeNow =  (new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000)).toISOString().replace(/[^0-9]/g, "").slice(0,12);

                if(event.end.format('YYYYMMDDHHmm') < timeNow)
                {
                    alert("Impossible de déplacer ce planning à une date passée !");
                    revertFunc();
                }
                else
                {
                    if (confirm(event.title + ' sera sur le planning du ' + event.start.format('DD/MM') + ' de ' +
                        event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                        event.end.format('HH') + 'h' + event.end.format('mm')))
                    {
                        $.ajax({
                            url: "/json/user/planning/update",
                            type: "GET",
                            dataType: "JSON",
                            data: {
                                id: event.id,
                                newdate: event.start.format('YYYY-MM-DD'),
                                newstart: event.start.format('HH:mm:ss'),
                                newstop: event.end.format('HH:mm:ss'),
                            },
                            success: function(updateResult)
                            {
                                if(!updateResult){revertFunc();}
                            },
                            error: function(err) {
                                revertFunc();
                            }
                        });
                    }
                    else
                    {
                        revertFunc();
                    }
                }
            },

            initialView             : 'timeGridWeek'
        });
        calendarAdmin.updateSize();
        calendarAdmin.render();
    }

    // Admin Planning Model
    var calendarAdminPlanningModel = document.getElementById('user-modelplanning-display');
    if(calendarAdminPlanningModel !== null)
    {
        var calendarAdminModel = new Calendar(calendarAdminPlanningModel, {
            contentHeight           : 1500,
            expandRows              : true,
            locale                  : 'fr',
            timeZone                : 'local',
            handleWindowResize      : true,
            firstDay                : 1,
            droppable               : false,
            slotEventOverlap        : false,
            slotDuration            : '00:15:00',
            slotLabelInterval       : '01:00:00',
            slotLabelFormat         :
                {
                    hour            : '2-digit',
                    minute          : '2-digit',
                    omitZeroMinute  : true,
                    meridiem        : false,
                    omitCommas      : true
                },
            slotMinTime             : "08:00:00",
            slotMaxTime             : "22:00:00",
            validRange              :
                {
                    start   : '2018-01-01',
                    end     : '2018-01-08'
                },
            plugins                 : [ dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin, bootstrapPlugin ],
            allDaySlot              : false,
            nowIndicator            : false,
            displayEventEnd         : true,
            dayHeaderFormat         : { weekday: 'long' },
            themeSystem             : 'bootstrap',
            buttonText              :
                {
                    week    : 'Semaine',
                    day     : 'Jour',
                    list    : 'Liste',
                },
            headerToolbar           :
                {
                    left   : 'timeGridWeek,timeGridDay',
                    center : '',
                    right  : 'prev,next'
                },
            eventSources            :
                [
                    {
                        url: "/json/user/planning/models",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                        },
                        error: function() {
                            alert('Erreur lors de la récupération des modèles de plannings');
                        },
                    }
                ],
            businessHours:
                [
                    {
                        daysOfWeek: [ 1, 2, 3, 4, 5 ],
                        startTime: '08:45',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 6 ],
                        startTime: '09:00',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 0 ],
                        startTime: '09:30',
                        endTime: '20:00'
                    }
                ],
            eventDurationEditable   : true,
            eventResizableFromStart : true,

            eventResize: function(info)
            {
                if(confirm(info.event.title + ' sera sur le planning du ' + moment(info.event.start).format('dddd [de] HH[h]mm') + ' à ' + moment(info.event.end).format('HH[h]mm')))
                {
                    $.ajax({
                        url: "/json/user/planning/model/update",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                            id: info.event.id,
                            newday: moment(info.event.start).format('D'),
                            newstart: moment(info.event.start).format('HH:mm:ss'),
                            newstop: moment(info.event.end).format('HH:mm:ss'),
                        },
                        success: function(updateResult)
                        {
                            if(!updateResult){info.revert();}
                        },
                        error: function(err) {
                            info.revert();
                        }
                    });
                }
                else
                {
                    info.revert();
                }

            },

            eventDrop: function(event, delta, revertFunc)
            {
                if (confirm(event.title + ' sera sur le planning du ' + event.start.format('dddd') + ' de ' +
                    event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                    event.end.format('HH') + 'h' + event.end.format('mm')))
                {
                    $.ajax({
                        url: "/json/user/planning/model/update",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                            id: event.id,
                            newday: event.start.format('D'),
                            newstart: event.start.format('HH:mm:ss'),
                            newstop: event.end.format('HH:mm:ss'),
                        },
                        success: function(updateResult)
                        {
                            if(!updateResult){revertFunc();}
                        },
                        error: function(err) {
                            revertFunc();
                        }
                    });
                }
                else
                {
                    revertFunc();
                }

            },

            initialView             : 'timeGridWeek'
        });
        calendarAdminModel.updateSize();
        calendarAdminModel.render();
    }

    // Mediums Appointments
    var calendarMediumsAppointments = document.getElementById('medium-appointmentplanning-display');
    if(calendarMediumsAppointments !== null)
    {
        var slotMinTime = "08:00:00";
        var slotMaxTime = "22:00:00";
        var calendarHeight = calendarMinHeight(slotMinTime, slotMaxTime);
        var calendarMAppointments = new Calendar(calendarMediumsAppointments, {
            contentHeight           : calendarHeight,
            expandRows              : true,
            locale                  : 'fr',
            timeZone                : 'local',
            handleWindowResize      : true,
            firstDay                : 1,
            droppable               : false,
            slotEventOverlap        : false,
            slotDuration            : '00:15:00',
            slotLabelInterval       : '01:00:00',
            slotLabelFormat         :
                {
                    hour            : '2-digit',
                    minute          : '2-digit',
                    omitZeroMinute  : true,
                    meridiem        : false,
                    omitCommas      : true
                },
            slotMinTime             : slotMinTime,
            slotMaxTime             : slotMaxTime,
            plugins                 : [resourceTimeGridPlugin, momentPlugin, bootstrapPlugin, interactionPlugin],
            allDaySlot              : false,
            nowIndicator            : true,
            titleFormat             : { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
            dayHeaderFormat         : { weekday: 'long' },
            themeSystem             : 'bootstrap',
            datesSet: function(dateInfo) {
                slotMinTime = calendarMinTime(dateInfo.start);
                calendarMAppointments.setOption('slotMinTime', slotMinTime);
                calendarMAppointments.setOption('contentHeight', calendarMinHeight(slotMinTime, slotMaxTime));
            },
            buttonText              :
                {
                    today   : 'Aujourd\'hui',
                    week    : 'Semaine',
                    day     : 'Jour',
                    list    : 'Liste',
                },
            headerToolbar           :
                {
                    left   : '',
                    center : 'title',
                    right  : 'today prev,next'
                },
            refetchResourcesOnNavigate : true,
            resources               :
                {
                    url: "/json/medium/plannings/appointment/medium",
                },
            events            :
                {
                    url: "/json/medium/plannings/appointment",
                    type: "GET",
                    dataType: "JSON",
                    data: {
                    },
                    error: function() {
                        alert('Erreur lors de la récupération des rendez-vous');
                    },
                },
            eventClick          : function(info)
            {
                info.jsEvent.preventDefault(); // don't let the browser navigate
                if(info.event.url) {
                    window.open(info.event.url);
                }
            },
            businessHours:
                [
                    {
                        daysOfWeek: [ 1, 2, 3, 4, 5 ],
                        startTime: '08:45',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 6 ],
                        startTime: '09:00',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 0 ],
                        startTime: '09:30',
                        endTime: '20:00'
                    }
                ],
            eventDurationEditable   : false,
            eventResizableFromStart : false,
            initialView             : 'resourceTimeGridDay'
        });

        // Calculate Min Time
        slotMinTime = calendarMinTime(calendarMAppointments.getDate());

        // Generate Calendar
        calendarMAppointments.updateSize();
        calendarMAppointments.setOption('slotMinTime', slotMinTime);
        calendarMAppointments.setOption('contentHeight', calendarMinHeight(slotMinTime, slotMaxTime));
        calendarMAppointments.render();

        // Reload events
        calendarMAppointments.interval = setInterval(function () {
            slotMinTime = calendarMinTime(calendarMAppointments.getDate());
            calendarMAppointments.refetchEvents();
            calendarMAppointments.setOption('slotMinTime', calendarMinTime(calendarMAppointments.getDate()));
            calendarMAppointments.setOption('contentHeight', calendarMinHeight(slotMinTime, slotMaxTime));

            var calendarCountObj = document.getElementById('medium-appointmentplanning-count');
            var calendarCount = calendarCountObj.value * 1;
            if(calendarCount > 2){
                calendarMAppointments.refetchResources();
                calendarCountObj.value = 0;
            }
            else{
                calendarCountObj.value = calendarCount + 1;
            }
        }, 30000);
    }

    // Orders by medium
    var calendarMediumsOrders = document.getElementById('medium-orderplanning-display');
    if(calendarMediumsOrders !== null)
    {
        var calendarMOrders = new Calendar(calendarMediumsOrders, {
            contentHeight           : 1500,
            expandRows              : true,
            locale                  : 'fr',
            timeZone                : 'local',
            handleWindowResize      : true,
            firstDay                : 1,
            droppable               : false,
            slotEventOverlap        : false,
            slotDuration            : '00:15:00',
            slotLabelInterval       : '01:00:00',
            slotLabelFormat         :
                {
                    hour            : '2-digit',
                    minute          : '2-digit',
                    omitZeroMinute  : true,
                    meridiem        : false,
                    omitCommas      : true
                },
            slotMinTime             : "08:00:00",
            slotMaxTime             : "22:00:00",
            plugins                 : [ resourceTimeGridPlugin, momentPlugin, bootstrapPlugin, interactionPlugin],
            allDaySlot              : false,
            nowIndicator            : false,
            titleFormat             : { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
            dayHeaderFormat         : { weekday: 'long' },
            themeSystem             : 'bootstrap',
            buttonText              :
                {
                    today   : 'Aujourd\'hui',
                    week    : 'Semaine',
                    day     : 'Jour',
                    list    : 'Liste',
                },
            headerToolbar           :
                {
                    left   : '',
                    center : 'title',
                    right  : 'today prev,next'
                },
            refetchResourcesOnNavigate : true,
            resources               :
                {
                    url: "/json/medium/plannings/order/medium",
                },
            events            :
                {
                    url: "/json/medium/plannings/order",
                    type: "GET",
                    dataType: "JSON",
                    data: {
                    },
                    error: function() {
                        alert('Erreur lors de la récupération des consultations');
                    },
                },
            eventClick          : function(info)
            {
                info.jsEvent.preventDefault(); // don't let the browser navigate
                if(info.event.url) {
                    window.open(info.event.url);
                }
            },
            businessHours:
                [
                    {
                        daysOfWeek: [ 1, 2, 3, 4, 5 ],
                        startTime: '08:45',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 6 ],
                        startTime: '09:00',
                        endTime: '21:00'
                    },
                    {
                        daysOfWeek: [ 0 ],
                        startTime: '09:30',
                        endTime: '20:00'
                    }
                ],
            eventDurationEditable   : false,
            eventResizableFromStart : false,
            initialView             : 'resourceTimeGridDay'
        });

        // Generate Calendar
        calendarMOrders.updateSize();
        calendarMOrders.render();
    }
});

/*
$(document).ready(function()
{
    // Medium Planning
    $('#medium-planning-display2').fullCalendar({
        height                  : 1000,
        locale                  : 'fr',
        firstDay                : 1,
        //editable                : true,
        droppable               : false,
        displayEventTime        : true,
        displayEventEnd         : true,
        timeFormat              : 'HH:mm',
        slotLabelFormat         : 'HH(:mm)',
        minTime                 : "08:00:00",
        allDayDefault           : false,
        allDaySlot              : false,
        themeSystem             : 'bootstrap4',
        defaultView             : 'agendaWeek',
        slotDuration            : '00:15:00',
        slotLabelInterval       : '01:00:00',
        eventSources: [
            {
                url: "/json/medium/plannings",
                type: "GET",
                dataType: "JSON",
                data: {
                },
                error: function() {
                    alert('Erreur lors de la récupération des plannings');
                },
            }
        ],

        eventResize: function(event, delta, revertFunc)
        {
            var timeNow =  (new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000)).toISOString().replace(/[^0-9]/g, "").slice(0,12);

            if(event.end.format('YYYYMMDDHHmm') < timeNow)
            {
                alert("Impossible de déplacer ce planning à une date passée !");
                revertFunc();
            }
            else
            {
                if (confirm(event.title + ' sera sur le planning du ' + event.start.format('DD/MM') + ' de ' +
                    event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                    event.end.format('HH') + 'h' + event.end.format('mm')))
                {
                    $.ajax({
                        url: "/json/medium/planning/update",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                            id: event.id,
                            newdate: event.start.format('YYYY-MM-DD'),
                            newstart: event.start.format('HH:mm:ss'),
                            newstop: event.end.format('HH:mm:ss'),
                        },
                        success: function(updateResult)
                        {
                            if(!updateResult){revertFunc();}
                        },
                        error: function(err) {
                            revertFunc();
                        }
                    });
                }
                else
                {
                    revertFunc();
                }
            }
        },

        eventDrop: function(event, delta, revertFunc)
        {

            var timeNow =  (new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000)).toISOString().replace(/[^0-9]/g, "").slice(0,12);

            if(event.end.format('YYYYMMDDHHmm') < timeNow)
            {
                alert("Impossible de déplacer ce planning à une date passée !");
                revertFunc();
            }
            else
            {
                if (confirm(event.title + ' sera sur le planning du ' + event.start.format('DD/MM') + ' de ' +
                    event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                    event.end.format('HH') + 'h' + event.end.format('mm')))
                {
                    $.ajax({
                        url: "/json/medium/planning/update",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                            id: event.id,
                            newdate: event.start.format('YYYY-MM-DD'),
                            newstart: event.start.format('HH:mm:ss'),
                            newstop: event.end.format('HH:mm:ss'),
                        },
                        success: function(updateResult)
                        {
                            if(!updateResult){revertFunc();}
                        },
                        error: function(err) {
                            revertFunc();
                        }
                    });
                }
                else
                {
                    revertFunc();
                }
            }
        },

        validRange              :
        {
            start: '2018-07-01',
            //end: '2017-06-01'
        },
        views: {
            month: {
                titleFormat         : 'MMMM YYYY',
                columnHeaderFormat  : 'ddd',
            },
            agendaWeek: {
                titleFormat         : 'DD MMM YYYY',
                columnHeaderFormat  : 'ddd DD/MM',
            },
            agendaDay: {
                titleFormat         : 'DD MMMM YYYY',
                columnHeaderFormat  : 'dddd DD MMMM YYYY',
            },
        },
        buttonText          :
        {
            today   : 'aujourd\'hui',
            month   : 'mois',
            week    : 'semaine',
            day     : 'jour',
            list    : 'liste',
        },
        header              :
        {
            left   : 'month,agendaWeek,agendaDay',
            center : 'title',
            right  : 'today prev,next',
        },
    });

    // Medium Model Planning
    $('#medium-modelplanning-display2').fullCalendar({
        height                  : 1000,
        locale                  : 'fr',
        firstDay                : 1,
        droppable               : false,
        displayEventTime        : true,
        displayEventEnd         : true,
        timeFormat              : 'HH:mm',
        slotLabelFormat         : 'HH(:mm)',
        minTime                 : "08:00:00",
        allDayDefault           : false,
        allDaySlot              : false,
        themeSystem             : 'bootstrap4',
        defaultView             : 'agendaWeek',
        slotDuration            : '00:15:00',
        slotLabelInterval       : '01:00:00',
        eventSources: [
            {
                url: "/json/medium/planning/models",
                type: "GET",
                dataType: "JSON",
                data: {
                },
                error: function() {
                    alert('Erreur lors de la récupération des modèles plannings');
                },
            }
        ],

        eventResize: function(event, delta, revertFunc)
        {
            if (confirm(event.title + ' sera sur le planning du ' + event.start.format('dddd') + ' de ' +
                event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                event.end.format('HH') + 'h' + event.end.format('mm')))
            {
                $.ajax({
                    url: "/json/medium/planning/model/update",
                    type: "GET",
                    dataType: "JSON",
                    data: {
                        id: event.id,
                        newday: event.start.format('dd'),
                        newstart: event.start.format('HH:mm:ss'),
                        newstop: event.end.format('HH:mm:ss'),
                    },
                    success: function(updateResult)
                    {
                        if(!updateResult){revertFunc();}
                    },
                    error: function(err) {
                        revertFunc();
                    }
                });
            }
            else
            {
                revertFunc();
            }
        },

        eventDrop: function(event, delta, revertFunc)
        {
            if (confirm(event.title + ' sera sur le planning du ' + event.start.format('dddd') + ' de ' +
                event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                event.end.format('HH') + 'h' + event.end.format('mm')))
            {
                $.ajax({
                    url: "/json/medium/planning/model/update",
                    type: "GET",
                    dataType: "JSON",
                    data: {
                        id: event.id,
                        newday: event.start.format('D'),
                        newstart: event.start.format('HH:mm:ss'),
                        newstop: event.end.format('HH:mm:ss'),
                    },
                    success: function(updateResult)
                    {
                        if(!updateResult){revertFunc();}
                    },
                    error: function(err) {
                        revertFunc();
                    }
                });
            }
            else
            {
                revertFunc();
            }
        },

        validRange              :
            {
                start: '2018-01-01',
                end: '2018-01-08'
            },
        views: {
            agendaWeek: {
                columnHeaderFormat  : 'dddd',
            },
            agendaDay: {
                columnHeaderFormat  : 'dddd',
            },
        },
        buttonText          :
            {
                week    : 'semaine',
                day     : 'jour',
                list    : 'liste',
            },
        header              :
            {
                left   : 'agendaWeek,agendaDay',
                center : '',
                right  : 'prev,next',
            },
    });

    // Admin Planning
    $('#user-planning-display2').fullCalendar({
        height                  : 1000,
        locale                  : 'fr',
        firstDay                : 1,
        //editable                : true,
        droppable               : false,
        displayEventTime        : true,
        displayEventEnd         : true,
        timeFormat              : 'HH:mm',
        slotLabelFormat         : 'HH(:mm)',
        minTime                 : "08:00:00",
        allDayDefault           : false,
        allDaySlot              : false,
        themeSystem             : 'bootstrap4',
        defaultView             : 'agendaWeek',
        slotDuration            : '00:15:00',
        slotLabelInterval       : '01:00:00',
        eventSources: [
            {
                url: "/json/user/plannings",
                type: "GET",
                dataType: "JSON",
                data: {
                },
                error: function() {
                    alert('Erreur lors de la récupération des plannings');
                },
            }
        ],

        eventResize: function(event, delta, revertFunc)
        {
            var timeNow =  (new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000)).toISOString().replace(/[^0-9]/g, "").slice(0,12);

            if(event.end.format('YYYYMMDDHHmm') < timeNow)
            {
                alert("Impossible de déplacer ce planning à une date passée !");
                revertFunc();
            }
            else
            {
                if (confirm(event.title + ' sera sur le planning du ' + event.start.format('DD/MM') + ' de ' +
                    event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                    event.end.format('HH') + 'h' + event.end.format('mm')))
                {
                    $.ajax({
                        url: "/json/user/planning/update",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                            id: event.id,
                            newdate: event.start.format('YYYY-MM-DD'),
                            newstart: event.start.format('HH:mm:ss'),
                            newstop: event.end.format('HH:mm:ss'),
                        },
                        success: function(updateResult)
                        {
                            if(!updateResult){revertFunc();}
                        },
                        error: function(err) {
                            revertFunc();
                        }
                    });
                }
                else
                {
                    revertFunc();
                }
            }
        },

        eventDrop: function(event, delta, revertFunc)
        {

            var timeNow =  (new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000)).toISOString().replace(/[^0-9]/g, "").slice(0,12);

            if(event.end.format('YYYYMMDDHHmm') < timeNow)
            {
                alert("Impossible de déplacer ce planning à une date passée !");
                revertFunc();
            }
            else
            {
                if (confirm(event.title + ' sera sur le planning du ' + event.start.format('DD/MM') + ' de ' +
                    event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                    event.end.format('HH') + 'h' + event.end.format('mm')))
                {
                    $.ajax({
                        url: "/json/user/planning/update",
                        type: "GET",
                        dataType: "JSON",
                        data: {
                            id: event.id,
                            newdate: event.start.format('YYYY-MM-DD'),
                            newstart: event.start.format('HH:mm:ss'),
                            newstop: event.end.format('HH:mm:ss'),
                        },
                        success: function(updateResult)
                        {
                            if(!updateResult){revertFunc();}
                        },
                        error: function(err) {
                            revertFunc();
                        }
                    });
                }
                else
                {
                    revertFunc();
                }
            }
        },

        validRange              :
        {
            start: '2018-07-01',
            //end: '2017-06-01'
        },
        views: {
            month: {
                titleFormat         : 'MMMM YYYY',
                columnHeaderFormat  : 'ddd',
            },
            agendaWeek: {
                titleFormat         : 'DD MMM YYYY',
                columnHeaderFormat  : 'ddd DD/MM',
            },
            agendaDay: {
                titleFormat         : 'DD MMMM YYYY',
                columnHeaderFormat  : 'dddd DD MMMM YYYY',
            },
        },
        buttonText          :
        {
            today   : 'aujourd\'hui',
            month   : 'mois',
            week    : 'semaine',
            day     : 'jour',
            list    : 'liste',
        },
        header              :
        {
            left   : 'month,agendaWeek,agendaDay',
            center : 'title',
            right  : 'today prev,next',
        },
    });

    // Admin Model Planning
    $('#user-modelplanning-display2').fullCalendar({
        height                  : 1000,
        locale                  : 'fr',
        firstDay                : 1,
        droppable               : false,
        displayEventTime        : true,
        displayEventEnd         : true,
        timeFormat              : 'HH:mm',
        slotLabelFormat         : 'HH(:mm)',
        minTime                 : "08:00:00",
        allDayDefault           : false,
        allDaySlot              : false,
        themeSystem             : 'bootstrap4',
        defaultView             : 'agendaWeek',
        slotDuration            : '00:15:00',
        slotLabelInterval       : '01:00:00',
        eventSources: [
            {
                url: "/json/user/planning/models",
                type: "GET",
                dataType: "JSON",
                data: {
                },
                error: function() {
                    alert('Erreur lors de la récupération des modèles plannings');
                },
            }
        ],

        eventResize: function(event, delta, revertFunc)
        {
            if (confirm(event.title + ' sera sur le planning du ' + event.start.format('dddd') + ' de ' +
                event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                event.end.format('HH') + 'h' + event.end.format('mm')))
            {
                $.ajax({
                    url: "/json/user/planning/model/update",
                    type: "GET",
                    dataType: "JSON",
                    data: {
                        id: event.id,
                        newday: event.start.format('dd'),
                        newstart: event.start.format('HH:mm:ss'),
                        newstop: event.end.format('HH:mm:ss'),
                    },
                    success: function(updateResult)
                    {
                        if(!updateResult){revertFunc();}
                    },
                    error: function(err) {
                        revertFunc();
                    }
                });
            }
            else
            {
                revertFunc();
            }
        },

        eventDrop: function(event, delta, revertFunc)
        {
            if (confirm(event.title + ' sera sur le planning du ' + event.start.format('dddd') + ' de ' +
                event.start.format('HH') + 'h' + event.start.format('mm') + ' à ' +
                event.end.format('HH') + 'h' + event.end.format('mm')))
            {
                $.ajax({
                    url: "/json/user/planning/model/update",
                    type: "GET",
                    dataType: "JSON",
                    data: {
                        id: event.id,
                        newday: event.start.format('D'),
                        newstart: event.start.format('HH:mm:ss'),
                        newstop: event.end.format('HH:mm:ss'),
                    },
                    success: function(updateResult)
                    {
                        if(!updateResult){revertFunc();}
                    },
                    error: function(err) {
                        revertFunc();
                    }
                });
            }
            else
            {
                revertFunc();
            }
        },

        validRange              :
            {
                start: '2018-01-01',
                end: '2018-01-08'
            },
        views: {
            agendaWeek: {
                columnHeaderFormat  : 'dddd',
            },
            agendaDay: {
                columnHeaderFormat  : 'dddd',
            },
        },
        buttonText          :
            {
                week    : 'semaine',
                day     : 'jour',
                list    : 'liste',
            },
        header              :
            {
                left   : 'agendaWeek,agendaDay',
                center : '',
                right  : 'prev,next',
            },
    });
});
*/



